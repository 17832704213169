import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputMask from 'react-input-mask';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import { sendData, onData, offData } from '../../Util/Socket';
import { isAdmin } from '../../Util/Auth';
import { states } from '../../Util/Store';

const CorporateInfo = () => {
  const [corporateInfo, setCorporateInfo, corporateInfoRef] = useState({
    address: '',
    city: '',
    zip: '',
    phone: '',
    email: '',
    arContact: ''
  });
  const [state, setState, stateRef] = useState({ value: '', text: '' });
  const [stateInput, setStateInput] = useState('');

  const saveCorporateInfo = () => {
    sendData('corporateUpdateInfo', {
      genUserId: localStorage.getItem('genUserId'),
      address: corporateInfoRef.current.address,
      city: corporateInfoRef.current.city,
      state: stateRef.current.value,
      zip: corporateInfoRef.current.zip,
      phone: corporateInfoRef.current.phone,
      email: corporateInfoRef.current.email,
      arContact: corporateInfoRef.current.arContact,
      bankName: corporateInfoRef.current.bankName,
      routing: corporateInfoRef.current.routingNumber,
      account: corporateInfoRef.current.accountNumber,
    });
  }

  const corporateGetInfo = () => {
    sendData('corporateGetInfo', {
      genUserId: localStorage.getItem('genUserId'),
    });
  }

  const onCorporateGetInfo = (infoObj) => {
    let trimmedState = infoObj.data.state?.trim();
    let stateData = {};

    if (trimmedState.length <= 2) {
      stateData = states.find((state) => state.value === infoObj.data.state?.trim());

      setState(stateData);
      setStateInput(stateData.text);
    } else {
      stateData = states.find((state) => state.text === infoObj.data.state?.trim());

      setState(stateData);
      setStateInput(trimmedState);
    }

    let info = {
      address: infoObj.data.address?.trim(),
      city: infoObj.data.city?.trim(),
      zip: infoObj.data.zip?.trim(),
      phone: infoObj.data.phone?.trim(),
      email: infoObj.data.email?.trim(),
      arContact: infoObj.data.arContact?.trim(),
    }

    setCorporateInfo(info);
  }

  const updateCorporateInfo = (field, value) => {
    setCorporateInfo({ ...corporateInfo, [field]: value });
  }

  useEffect(() => {

    if (isAdmin()) {
      corporateGetInfo();

      onData('corporateGetInfo', onCorporateGetInfo);

      return () => {
        offData('corporateGetInfo');
      }
    }
  }, []);

  return (
    <Card
      raised
    >
      <CardHeader
        title="Update Your Info"
        sx={{
          backgroundColor: "#1e4670",
          color: "white",
        }}
      />
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Card
              raised
            >
              <CardHeader
                title="Address Information"
                sx={{
                  backgroundColor: "#1e4670",
                  color: "white",
                }}
              />
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <TextField
                      label="Corporate Address"
                      variant="standard"
                      fullWidth
                      value={corporateInfo.address ?? ""}
                      onChange={(e) => updateCorporateInfo('address', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="City"
                      variant="standard"
                      fullWidth
                      value={corporateInfo.city ?? ""}
                      onChange={(e) => updateCorporateInfo('city', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Autocomplete
                      autoHighlight
                      autoSelect
                      value={state}
                      onChange={
                        (e, newState) => {
                          setState(newState);
                        }
                      }
                      inputValue={stateInput}
                      onInputChange={(e, newValue) => setStateInput(newValue)}
                      id="state"
                      options={states}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      getOptionLabel={(option) => {
                        return option.text ?? "";
                      }}
                      renderInput={(params) =>
                        <TextField
                          label="State"
                          variant="standard"
                          fullWidth
                          {...params}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      label="Zip"
                      variant="standard"
                      fullWidth
                      value={corporateInfo.zip ?? ""}
                      onChange={(e) => updateCorporateInfo('zip', e.target.value)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              raised
            >
              <CardHeader
                title="Contact Information"
                sx={{
                  backgroundColor: "#1e4670",
                  color: "white",
                }}
              />
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={4}>
                    <TextField
                      label="Accounts Receivable Contact Person"
                      fullWidth
                      variant="standard"
                      value={corporateInfo.arContact ?? ""}
                      onChange={(e) => updateCorporateInfo('arContact', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputMask
                      mask="(999) 999-9999"
                      maskChar=" "
                      value={corporateInfo.phone ?? ""}
                      onChange={(e) => updateCorporateInfo('phone', e.target.value)}
                    >
                      { () =>
                      <TextField
                        label="Contact Persons Phone"
                        fullWidth
                        variant="standard"
                      />
                      }
                    </InputMask>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Contact Persons Email"
                      fullWidth
                      variant="standard"
                      value={corporateInfo.email ?? ""}
                      onChange={(e) => updateCorporateInfo('email', e.target.value)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              fullWidth
              onClick={() => {
                saveCorporateInfo();
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CorporateInfo;
