import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import InputMask from 'react-input-mask';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js';
import { sendData, onData, offData } from '../../../Util/Socket';
import { cardErrorStatus } from '../../../Util/Finance';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#6c757d",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const PaymentStep = (props) => {
  const [loading, setLoading] = useState(true);
  const [showCardError, setShowCardError] = useState(false);
  const [cardError, setCardError] = useState('');
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPhoneAlert, setShowPhoneAlert] = useState(false);
  const [codeSent, setCodeSent, codeSentRef] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [phoneAlertError, setPhoneAlertError] = useState("");
  const [customer, setCustomer, customerRef] = useState(props?.customer);
  const [idempotencyKey, setIdempotencyKey, idempotencyKeyRef] = useState(null);
  const [accountType, setAccountType, accountTypeRef] = useState({ value: '', text: '' });
  const [accountTypeInput, setAccountTypeInput, accountTypeInputRef] = useState("");
  const [paymentInformation, setPaymentInformation, paymentInformationRef] = useState({
    cardName: '',
    bankAccountNumber: "",
    confirmBankAccountNumber: "",
    bankAccountRouting: "",
    confirmBankAccountRouting: "",
    verificationPhoneNumber: "",
    paymentAuthorized: false,
    verificationCode: "",
    sentVerificationCode: "",
    confirmTraditionalChecking: false,
    signType: 'esign'
  });
  const [bankAccountTypes, setBankAccountTypes] = useState([
    { value: "0", text: 'Checking' },
    { value: "1", text: 'Savings' }
  ]);
  const [errors, setErrors, errorsRef] = useState({});
  const [card, setCard, cardRef] = useState({});

  const stripe = useStripe();
  const elements = useElements();

  const phoneCheck = () => {
    let errors = {};

    if (!customerRef.current?.hasPaid && !customerRef.current?.hasBankInfo) {
      errors = validatePaymentInformation();

      setErrors(errors);
    }

    if ((customerRef.current?.hasPaid || paymentInformationRef.current.paymentAuthorized)
      && Object.keys(errors).length === 0
    ) {
      setLoading(true);

      sendData('phoneCheck', {
        genUserId: localStorage.getItem('genUserId'),
        contract: customerRef.current.contract,
        phone: paymentInformationRef.current?.verificationPhoneNumber?.replace(/\D/g, ''),
      });
    }
  }

  const onPhoneCheck = (data) => {
    if (data.success) {
      preAuthorization();
    } else {
      setPhoneAlertError(data.message);
      setShowPhoneAlert(true);
      setLoading(false);
    }
  }

  const verifyPhoneSMS = () => {
    sendData('verifyPhoneSMS', {
      contract: customerRef.current.contract,
      phone: paymentInformationRef.current?.verificationPhoneNumber?.replace(/\D/g, ''),
    });
  }

  const onVerifyPhoneSMS = (data) => {
    if (data?.success) {
      setCodeSent(true);
      updatePaymentInformation('sentVerificationCode', data.code);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  const verifyCode = () => {
    let formattedCode = paymentInformationRef.current.verificationCode.replace(/\s/g, '');
    let sentCode = paymentInformationRef.current.sentVerificationCode;

    //This needs to be changed to the backend eventually.
    if (paymentInformationRef.current.confirmTraditionalChecking) {
      setErrors(prevErrors => {
        return { ...prevErrors, confirmTraditionalChecking: null }
      })

      if (formattedCode !== sentCode) {
        setErrors({
          ...errorsRef.current,
          verificationCode: "Verification code does not match",
        });
        setPhoneVerified(false);
      } else {
        setPhoneVerified(true);


        sendData('sp_checkPaymentStatus', {
          contract: customerRef.current.contract,
          initialPayment: parseFloat(customerRef.current.merchandise.invoice.initialPayment),
        });

        let merchandise = {
          ...customerRef.current.merchandise,
          invoice: {
            ...customerRef.current.merchandise.invoice,
          }
        }


        props.setCustomer({
          ...customerRef.current,
          ...customerRef.current.merchandise,
          confirmTraditionalChecking: paymentInformationRef.current.confirmTraditionalChecking,
          paymentAuthorized: true,
          signType: paymentInformationRef.current.signType,
          verifiedPhone: paymentInformationRef.current.verificationPhoneNumber.replace(/\D/g, ''),
          hasPaid: true,
          hasBankInfo: true,
        });
        props.setActiveStep(props.activeStep + 1);
      }
    } else {
      setErrors(prevErrors => {
        return { ...prevErrors, confirmTraditionalChecking: "Please verify you have a traditional checking account" }
      });
    }
  }

  const preAuthorization = () => {

    if (!customerRef.current.hasPaid && Object.keys(errorsRef.current).length === 0) {
      let cardElement = elements.getElement(CardElement);

      console.log(paymentInformationRef.current);
      stripe.createToken(cardElement, { name: paymentInformationRef.current.cardName }).then((result) => {

        //We need wait to grab the card element before we can set loading
        setLoading(true);

        if (result?.error) {
          setLoading(false);
          setErrorMessage(result.error.message);
          setShowErrorAlert(true);
        } else if (result?.token?.card?.funding.toLowerCase() === 'prepaid') {
          setLoading(false);
          setErrorMessage('Prepaid cards are not accepted. Please use a credit or debit card to complete this transaction.')

          sendData('logPrepaidCard', {
            contract: customerRef.current.contract,
            customer: customerRef.current.name,
            phone: paymentInformationRef.current?.verificationPhoneNumber?.replace(/\D/g, ''),
          });
        } else {
          setErrorMessage("");
          setShowErrorAlert(false);

          let cardType = result.token.card.funding;
          let token = result.token.id;
          let last4 = result.token.card.last4;
          let cardId = result.token.card.id;
          let account = paymentInformationRef.current.bankAccountNumber.trim().replace(/\D/g, '');
          let routing = paymentInformationRef.current.bankAccountRouting.trim().replace(/\D/g, '');

          let key = `${idempotencyKeyRef.current}${customerRef.current.contract}${result.token.card.last4}${result.token.card.address_zip}`;

          setCard(result.token.card);

          sendData('preAuth', {
            genUserId: localStorage.getItem('genUserId'),
            contract: customerRef.current.contract,
            account: account,
            routing: routing,
            stripe: token,
            cnumber: last4,
            cardName: paymentInformationRef.current.cardName,
            mobile: paymentInformationRef.current.verificationPhoneNumber.replace(/\D/g, ''),
            sendtype: 'sms',
            cardData: '',
            accountType: accountTypeRef.current?.text.toLowerCase(),
            randomString: key,
            cardType: cardType,
            cardId: cardId,
            testContract: customerRef.current.testContract
          });
        }
      });
    } else {
      setLoading(true);
      verifyPhoneSMS();
    }
  }

  const onStep8 = (data) => {

    setCustomer({
      ...customerRef.current,
      ...customerRef.current.merchandise,
      hasPaid: true,
      hasBankInfo: true,
    });

    verifyPhoneSMS();
  }

  const onStep8Error = ({ declineCode, message }) => {
    if (declineCode && declineCode.length > 0) {
      if (declineCode === 'incorrect_cvc') {
        let callLogMessage = `Incorrect CVC on initial payment.`;

				sendData('callloginsert', {
					customer: customerRef.current,
					message: callLogMessage
				});
      } else if (declineCode === 'incorrect_zip') {
        let callLogMessage = `Invalid zip code on initial payment ${cardRef.current.address_zip}`;

				sendData('callloginsert', {
					customer: customerRef.current,
					message: callLogMessage
				});
      } else {
        let callLogMessage = `Card declined: ${declineCode} (Zip/CVC - Possible)`

				sendData('callloginsert', {
					customer: {
            ...customerRef.current,
            ...cardRef.current
          },
					message: callLogMessage
				});
      }
    }

    setCardError(message);
    setShowCardError(true);
    setLoading(false);
  }

  const updatePaymentInformation = (field, value) => {
    setPaymentInformation({
      ...paymentInformation,
      [field]: value
    });

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null
      });
    }
  }

  const validatePaymentInformation = () => {
    let errors = {};

    let numStartEndRegex = /^[0-9]*.*[0-9]$/i;
    let numAndDashRegex = /^([0-9-])*$/i;
    let confirmRoutingPrefix = paymentInformationRef.current.confirmBankAccountRouting?.substring(0, 2);
    confirmRoutingPrefix = parseInt(confirmRoutingPrefix);
    let routingPrefix = paymentInformationRef.current.bankAccountRouting?.substring(0, 2);
    routingPrefix = parseInt(routingPrefix);

    if (!(accountTypeInputRef.current)) {
      errors.accountType = "Please select an account type";
    }

    if (!(paymentInformationRef.current.bankAccountRouting?.length > 0)) {
      errors.bankAccountRouting = "Routing number is required";
    } else if (paymentInformationRef.current.bankAccountRouting?.length !== 9) {
      errors.bankAccountRouting = "Routing number must be 9 digits long.";
    } else if (paymentInformationRef.current.bankAccountRouting !== paymentInformationRef.current.confirmBankAccountRouting) {
      errors.bankAccountRouting = "Routing numbers do not match.";
    } else if (!numAndDashRegex.test(paymentInformationRef.current.bankAccountRouting)) {
      errors.bankAccountRouting = "Routing number can only contain numbers and dashes.";
    } else if (!numStartEndRegex.test(paymentInformationRef.current.bankAccountRouting)) {
      errors.bankAccountRouting = "Routing number must start and end with a number.";
    } else if (!(routingPrefix >= 1 && routingPrefix <= 72)) {
      errors.bankAccountRouting = "Invalid routing number.";
    } else if (paymentInformationRef.current.bankAccountRouting === paymentInformationRef.current.bankAccountNumber) {
      errors.bankAccountRouting = "Routing number cannot match the account number";
    } else if (!validCheckSum(paymentInformationRef.current.bankAccountRouting)) {
      errors.bankAccountRouting = "Please verify the information you have entered.";
    }

    if (!(paymentInformationRef.current.bankAccountNumber?.length > 0)) {
      errors.bankAccountNumber = "Account number is required.";
    } else if (paymentInformationRef.current.bankAccountNumber !== paymentInformationRef.current.confirmBankAccountNumber) {
      errors.bankAccountNumbers = "Account numbers do not match.";
    } else if (paymentInformationRef.current.bankAccountNumber === paymentInformationRef.current.bankAccountRouting) {
      errors.bankAccountNumbers = "Account number cannot match the routing number";
    } else if (!numStartEndRegex.test(paymentInformationRef.current.bankAccountNumber)) {
      errors.bankAccountNumbers = "Account number must start and end with a number.";
    } else if (!numAndDashRegex.test(paymentInformationRef.current.bankAccountNumber)) {
      errors.bankAccountNumbers = "Account number can only contain numbers and dashes.";
    }

    if (!(paymentInformationRef.current.confirmBankAccountNumber?.length > 0)) {
      errors.confirmBankAccountNumber = "Confirm account number is required.";
    } else if (paymentInformationRef.current.bankAccountNumber !== paymentInformationRef.current.confirmBankAccountNumber) {
      errors.confirmBankAccountNumber = "Account numbers do not match.";
    } else if (paymentInformationRef.current.confirmBankAccountNumber === paymentInformationRef.current.confirmBankAccountRouting) {
      errors.confirmBankAccountNumber = "Account number cannot match the routing number.";
    } else if (!numStartEndRegex.test(paymentInformationRef.current.confirmBankAccountNumber)) {
      errors.confirmBankAccountNumber = "Account number must start and end with a number.";
    } else if (!numAndDashRegex.test(paymentInformationRef.current.confirmBankAccountNumber)) {
      errors.confirmBankAccountNumber = "Account number can only contain numbers and dashes.";
    }

    if (!(paymentInformationRef.current.confirmBankAccountRouting?.length > 0)) {
      errors.confirmBankAccountRouting = "Routing number is required";
    } else if (paymentInformationRef.current.confirmBankAccountRouting?.length !== 9) {
      errors.confirmBankAccountRouting = "Routing number must be 9 digits long.";
    } else if (paymentInformationRef.current.bankAccountRouting !== paymentInformationRef.current.confirmBankAccountRouting) {
      errors.confirmBankAccountRouting = "Routing numbers do not match.";
    } else if (!numAndDashRegex.test(paymentInformationRef.current.confirmBankAccountRouting)) {
      errors.confirmBankAccountRouting = "Routing number can only contain numbers and dashes.";
    } else if (!numStartEndRegex.test(paymentInformationRef.current.confirmBankAccountRouting)) {
      errors.confirmBankAccountRouting = "Routing number must start and end with a number.";
    } else if (!(confirmRoutingPrefix >= 1 && confirmRoutingPrefix <= 72)) {
      errors.confirmBankAccountRouting = "Invalid routing number.";
    } else if (paymentInformationRef.current.confirmBankAccountRouting === paymentInformationRef.current.confirmBankAccountNumber) {
      errors.confirmBankAccountRouting = "Routing number cannot match the account number";
    } else if (!validCheckSum(paymentInformationRef.current.confirmBankAccountRouting)) {
      errors.confirmBankAccountRouting = "Please verify the information you have entered.";
    }

    let phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}(\s|.|-){0,3}\d{4}$/;
    if (!phoneRegex.test(paymentInformationRef.current.verificationPhoneNumber)) {
      errors.verificationPhoneNumber = "Phone number must be in the format (123) 456-7890.";
    } else if (paymentInformationRef.current.verificationPhoneNumber?.length < 10) {
      errors.verificationPhoneNumber = "Phone number must be 10 digits long.";
    } else if (!(paymentInformationRef.current.verificationPhoneNumber?.length > 0)) {
      errors.verificationPhoneNumber = "Phone number is required.";
    }

    if (!paymentInformationRef.current.paymentAuthorized) {
      errors.paymentAuthorized = "You must authorize the payment before you can continue.";
    }

    if (!paymentInformationRef.current.cardName?.length > 0) {
      errors.paymentAuthorized = "Card name is required.";
    }

    return errors;
  }

  const validCheckSum = (routingNumber) => {
    let numArray = routingNumber.split('');
    numArray.forEach((num, index) => {
      numArray[index] = parseInt(num);
    });

    //Checksum Test using weight 371 371 371
    let sum = 3 * (numArray[0] + numArray[3] + numArray[6]);
    sum += 7 * (numArray[1] + numArray[4] + numArray[7]);
    sum += 1 * (numArray[2] + numArray[5] + numArray[8]);

    return sum % 10 === 0;
  }

  useEffect(() => {
    setIdempotencyKey(props.idempotencyKey);
    setLoading(false);

    onData('phoneCheck', onPhoneCheck);
    onData('step8', onStep8);
    onData('step8error', onStep8Error);
    onData('verifyPhoneSMS', onVerifyPhoneSMS);

    return () => {
      offData('phoneCheck');
      offData('verifyPhoneSMS');
      offData('step8');
      offData('step8error');
      offData('preAuth');
    }
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showCardError}
        autoHideDuration={15000}
        onClose={(e, reason) => {
          if (reason !== 'clickaway' && reason !== 'escapeKeyDown') {
            setShowCardError(false);
          }
        }}
      >
        <Alert onClose={() => setShowCardError(false)} severity="error" variant="filled">
          {cardError}
        </Alert>
      </Snackbar>

      <Grid
        container
        direction="row"
        spacing={2}
      >
        <Grid item xs={12}>
          { showErrorAlert &&
            <Alert variant="filled" severity="error">
              { errorMessage }
            </Alert>
          }
        </Grid>
        { (!customer?.hasPaid) || (!customer?.hasBankInfo) ?
          <>
          <Grid item container xs={8}>
            <Card
              raised
            >
              <CardHeader
                title="Bank Information"
                sx={{ backgroundColor: '#1e4670', color: 'white' }}
              />
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Autocomplete
                      id="accountType"
                      autoHighlight
                      autoSelect
                      options={bankAccountTypes}
                      getOptionLabel={(option) => option.text ?? ""}
                      value={accountType}
                      onChange={(event, newAccount) => {
                        setAccountType(newAccount);
                      }}
                      inputValue={accountTypeInput}
                      onInputChange={(e, newValue) => setAccountTypeInput(newValue)}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          label="Account Type"
                          variant="standard"
                          fullWidth
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      fullWidth
                      label="Routing Number"
                      value={paymentInformation.bankAccountRouting}
                      onChange={(e) => {
                        updatePaymentInformation('bankAccountRouting', e.target.value);
                      }}
                      error={errors?.bankAccountRouting ? true : undefined}
                      helperText={errors?.bankAccountRouting ? errors.bankAccountRouting : ''}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      fullWidth
                      label="Confirm Routing Number"
                      value={paymentInformation.confirmBankAccountRouting}
                      onChange={(e) => {
                        updatePaymentInformation('confirmBankAccountRouting', e.target.value);
                      }}
                      error={errors?.confirmBankAccountRouting ? true : undefined}
                      helperText={errors?.confirmBankAccountRouting ? errors.confirmBankAccountRouting : ''}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      fullWidth
                      label="Account Number"
                      value={paymentInformation.bankAccountNumber}
                      onChange={(e) => {
                        updatePaymentInformation('bankAccountNumber', e.target.value);
                      }}
                      error={errors?.bankAccountNumber ? true : undefined}
                      helperText={errors?.bankAccountNumber ? errors.bankAccountNumber : ''}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      fullWidth
                      label="Confirm Account Number"
                      value={paymentInformation.confirmBankAccountNumber}
                      onChange={(e) => {
                        updatePaymentInformation('confirmBankAccountNumber', e.target.value);
                      }}
                      error={errors?.confirmBankAccountNumber ? true : undefined}
                      helperText={errors?.confirmBankAccountNumber ? errors.confirmBankAccountNumber : ''}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Grid
              container
              direction="column"
              spacing={2}
            >
              <Grid item xs={12}>
                <Card
                  raised
                >
                  <CardHeader
                    title="Debit/Credit Card Information"
                    sx={{ backgroundColor: '#1e4670', color: 'white' }}
                  />
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <TextField
                          variant="standard"
                          fullWidth
                          label="Name on Card"
                          value={paymentInformation.cardName}
                          onChange={(e) => {
                            updatePaymentInformation('cardName', e.target.value);
                          }}
                          errors={errors?.cardName ? true : undefined}
                          helperText={errors?.cardName ? errors.cardName : ''}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CardElement options={CARD_ELEMENT_OPTIONS} hidePostalCode="false"/>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card
                  raised
                >
                  <CardHeader
                    title="Verify Initial Payment"
                    sx={{ backgroundColor: '#1e4670', color: 'white' }}
                  />
                  <CardContent>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={paymentInformation.paymentAuthorized}
                            onChange={(e) => updatePaymentInformation('paymentAuthorized', e.target.checked)}
                          />
                        }
                        label={`Click to authorize Smart Sales and Lease to process
                          your initial payment of
                          $${customer?.merchandise?.invoice?.amountDue}
                          at this time using the card information you provided.
                          Future payments will be drafted from the checking account
                          provided.`
                        }
                      />
                    </FormGroup>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          </>
          :
          null
        }
        { !codeSent ?
          <Grid item xs={12}>
            <Card
              raised
            >
              <CardHeader
                title="Customer Verification"
                sx={{ backgroundColor: '#1e4670', color: 'white' }}
              />
              <CardContent>
                <Typography
                  variant="body1"
                  component="p"
                >
                  For your protection, we text you a confirmation code that
                  you will need to enter to sign your electronic document.
                  To receive your code, please enter your phone number and
                  press the SEND CODE VIA SMS button below. You agree data rates
                  may apply.
                </Typography>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    { phoneAlertError &&
                      <Alert variant="filled" severity="error">
                        { showPhoneAlert }
                      </Alert>
                    }
                  </Grid>
                  <Grid item>
                    <InputMask
                      mask="(999) 999-9999"
                      maskChar=" "
                      value={paymentInformation.verificationPhoneNumber}
                      onChange={(e) => {
                        updatePaymentInformation('verificationPhoneNumber', e.target.value);
                      }}
                    >
                      {() =>
                        <TextField
                          variant="standard"
                          label="Mobile Phone"
                          fullWidth
                          error={errors?.verificationPhoneNumber ? true : undefined}
                          helperText={errors?.verificationPhoneNumber ? errors.verificationPhoneNumber : ''}
                        />
                      }
                    </InputMask>
                  </Grid>
                  <Grid item>
                    { !loading ?
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SendIcon />}
                        onClick={() => phoneCheck()}
                      >
                        Send Code Via SMS
                      </Button>
                      :
                      <Button
                        variant="contained"
                        color="primary"
                      >
                        <CircularProgress color="secondary" size={24} />
                      </Button>
                    }
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          :
          <>
          <Grid item xs={12}>
            <Card
              raised
            >
              <CardHeader
                title="Choose A Signing Method"
                sx={{ backgroundColor: '#1e4670', color: 'white' }}
              />
              <CardContent>
                <FormControl>
                  <RadioGroup
                    name="signing-type"
                    value={paymentInformation.signType}
                    onChange={(e) => {
                      updatePaymentInformation('signType', e.target.value);
                    }}
                  >
                    { localStorage.getItem('userRoleId') === '-6' &&
                      <FormControlLabel
                        value="emailandsms"
                        control={<Radio />}
                        label="Email & SMS (DocuSign)"
                      />
                    }
                    { localStorage.getItem('userRoleId') !== '-6' &&
                      <>
                      <FormControlLabel
                        value="esign"
                        control={<Radio />}
                        label="E-Sign (DocuSign)"
                      />
                      <FormControlLabel
                        value="print"
                        control={<Radio />}
                        label="Print"
                      />
                      </>
                    }
                  </RadioGroup>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              raised
            >
              <CardHeader
                title="Confirm SMS Code"
                sx={{ backgroundColor: '#1e4670', color: 'white' }}
              />
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={4}>
                    <InputMask
                      mask="999 999"
                      value={paymentInformation.verificationCode}
                      onChange={(e) => updatePaymentInformation('verificationCode', e.target.value)}
                      maskChar=" "
                    >
                      {() =>
                        <TextField
                          variant="standard"
                          label="SMS Code"
                          fullWidth
                          error={errors?.verificationCode ? true : undefined}
                          helperText={errors?.verificationCode ? errors.verificationCode : ''}
                        />
                      }
                    </InputMask>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl
                      required
                      error={errors?.confirmTraditionalChecking ? true : undefined}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={paymentInformation.confirmTraditionalChecking}
                              onChange={(e) => updatePaymentInformation('confirmTraditionalChecking', e.target.checked)}
                            />
                          }
                          label={`You confirm you have a traditional checking account.
                            Prepaid consumer accounts are not accepted. If you are
                            unsure about your bank account qualification you can
                            contact Smart Sales and Lease to verify. You agree data
                            rates may apply.`
                          }
                        />
                      </FormGroup>
                      <FormHelperText>{errors?.confirmTraditionalChecking ? errors.confirmTraditionalChecking : ""}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          </>
        }
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ paddingTop: '1rem' }}
      >
        <Grid item>
          <Button
            variant="contained"
            color="error"
            startIcon={<ArrowBackIosNewIcon />}
            onClick={() => {
              props.setActiveStep(props.activeStep - 1);
            }}
          >Back</Button>
        </Grid>
        <Grid item>
          { codeSent &&
            <Button
              variant="contained"
              color="success"
              startIcon={<SendIcon />}
              onClick={() => {
                verifyCode();
              }}
            >Verify Code and Authorize</Button>
          }
        </Grid>
      </Grid>
    </>
  );
}

export default PaymentStep;
