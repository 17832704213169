import React, { useEffect, useCallback } from 'react';
import useState from 'react-usestateref';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  GridCellModes,
} from '@mui/x-data-grid';
import { withinFinanceRange, currencyFormatter } from '../../../Util/Finance';
import { sendData, onData, offData } from '../../../Util/Socket';

const useUpdateSKUS = () => {
  return useCallback(
    (sku) => {
      return new Promise((resolve, reject) => {
        if (sku.cost < 0) {
          reject('Cost cannot be negative');
        } else {
          resolve({ ...sku });
        }
      });
    }
  );
};

const SKUStep = (props) => {
  const [customer, setCustomer, customerRef] = useState(props?.customer);
  const [merchandise, setMerchandise, merchandiseRef] = useState(props?.customer?.merchandise);
  const [skus, setSKUS, skusRef] = useState(props?.customer?.merchandise?.skus);
  const [skuId, setSKUId, skuIdRef] = useState(0);
  const [showFinanceRangeAlert, setShowFinanceRangeAlert] = useState(false);
  const [merchandiseTotalCost, setMerchandiseTotalCost, merchandiseTotalCostRef] = useState(props?.customer?.merchandise?.totalCost || 0);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbar, setSnackbar] = useState({
    autoHideDuration: 3000, //Default 3 seconds
    severity: 'success',
    message: '',
  });
  const [currentCell, setCurrentCell] = useState("");
  const [cellModesModel, setCellModesModel] = useState({});
  const [columns, setColumns] = useState([
    {
      headerName: 'Order Number',
      field: 'orderNumber',
      type: 'string',
      editable: true,
      width: 200,
      valueFormatter: ({ value }) => {
        if (value?.length > 0) {
          return value;
        } else {
          return 'Click To Edit';
        }
      },
    },
    {
      headerName: 'SKU Number',
      field: 'sku',
      type: 'string',
      editable: true,
      width: 200,
      valueFormatter: ({ value }) => {
        if (value?.length > 0) {
          return value;
        } else {
          return 'Click To Edit';
        }
      },
    },
    {
      headerName: 'Description',
      field: 'description',
      type: 'string',
      editable: true,
      width: 250,
      valueFormatter: ({ value }) => {
        if (value?.length > 0) {
          return value;
        } else {
          return 'Click To Edit';
        }
      },
    },
    {
      headerName: 'Cost',
      field: 'cost',
      type: 'number',
      editable: true,
      valueFormatter: ({ value }) => currencyFormatter.format(value),
      width: 200,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<ContentCopyIcon />}
            label="Copy"
            title="Copy"
            onClick={() => {
              setSKUS((oldRows) => {
                let skuIndex = oldRows.findIndex(row => row.id === id);

                setSKUId(id + 1);

                return [
                  ...oldRows,
                  {
                    id: id + 1,
                    orderNumber: oldRows[skuIndex].orderNumber,
                    sku: oldRows[skuIndex].sku,
                    description: oldRows[skuIndex].description,
                    cost: oldRows[skuIndex].cost,
                  },
                ]
              });

              updateMerchandiseTotalCost();
            }}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            title="Delete"
            onClick={() => {
              setSKUS((oldRows) => {
                return oldRows.filter(row => row.id !== id);
              });

              updateMerchandiseTotalCost();
            }}
          />,
        ]
      }
    }
  ]);

  const updateSKUS = useUpdateSKUS();

  const processRowUpdate = useCallback(
    async (sku) => {
      const response = await updateSKUS(sku);

      setSKUS(skus.map(item => item.id === sku.id ? sku : item));
      updateMerchandiseTotalCost();

      return response;
    },
    [updateSKUS],
  );

  const onProcessRowError = useCallback((err) => {
    console.error(err);
  });

  const handleCellClick = useCallback((params) => {
    if (params.isEditable) {
      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View }
                }),
                {}
              )
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit }
          }
        };
      });
    }
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const submitSKUS = () => {
    if (withinFinanceRange(merchandiseTotalCostRef.current)) {
      sendData('contractSkus', {
        contract: customerRef.current.contract,
        skus: skusRef.current,
        totalCost: merchandiseTotalCostRef.current,
        complete: true,
        genUserId: localStorage.getItem('genUserId')
      });
    }
  }

  const onSubmitSKUS = (data) => {
    if (data.complete) {
      //Update customer and continue to next step
      let updatedMerchandise = {
        ...merchandiseRef.current,
        totalCost: merchandiseTotalCostRef.current,
        skus: skusRef.current
      };


      props.setCustomer({
        ...customerRef.current,
        merchandise: updatedMerchandise,
        cashPrice: data.cashPrice
      });

      props.setActiveStep(props.activeStep + 1);
    }
  }

  const updateMerchandiseTotalCost = () => {
    let currentTotalCost = 0;

    for (let i = 0; i < skusRef.current.length; i++) {
      currentTotalCost += parseFloat(skusRef.current[i].cost);
    }

    if (!withinFinanceRange(currentTotalCost)) {
      setShowFinanceRangeAlert(true);
    } else {
      setShowFinanceRangeAlert(false);

    }

    sendData('contractSkus', {
      contract: customerRef.current.contract,
      skus: skusRef.current,
      totalCost: currentTotalCost,
      complete: false,
      genUserId: localStorage.getItem('genUserId'),
    });
    setMerchandiseTotalCost(currentTotalCost);
  }

  useEffect(() => {
    if (skus.length === 0) {
      setSKUId(0);
      setSKUS([
        { id: 0,
          orderNumber: '',
          sku: '',
          description: '',
          cost: 0
        }
      ]);
    } else {
      setSKUId(skus[skus.length - 1].id + 1);
    }

    onData('contractSkus', onSubmitSKUS);

    return () => {
      offData('contractSkus');
      offData('taxEstimate');
    }
  }, []);

  return (
    <>
      <Snackbar open={showSnackbar}
        message={snackbar.message}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowSnackbar(false)} severity={snackbar.severity} sx={{ width: '100%'}}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Card
        raised
        sx={{ paddingBottom: '1rem' }}
      >
        <CardHeader
          sx={{ backgroundColor: '#1e4670', color: 'white' }}
          title="Shopping Cart"
        />
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{
              marginBottom: '1rem'
            }}
          >
            <Grid item xs={4}>
              <Stack direction="row" spacing={2}>
                <Typography
                  variant="h6"
                  component="h6"
                  align="center"
                >Total Cost: </Typography>
                <Chip
                  icon={<AttachMoneyIcon />}
                  label={currencyFormatter.format(merchandiseTotalCost)}
                  color="success"
                />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="info"
                fullWidth
                startIcon={<AddIcon />}
                onClick={() => {
                  setSKUId(oldId => {
                    let newId = oldId + 1;

                    setSKUS((oldRows) => [
                      ...oldRows,
                      {
                        id: newId,
                        orderNumber: '',
                        sku: '',
                        description: '',
                        cost: 0,
                      }
                    ]);

                    return newId;
                  });
                }}
              >
                Add Item
              </Button>
            </Grid>
          </Grid>
          <Stack spacing={1} sx={{ paddingBottom: 1 }}>
          { showFinanceRangeAlert &&
            <Alert variant="filled" severity="error" fullWidth>
              Total cost must be greater than <strong>$300.00</strong>
            </Alert>
          }
          { skus.length === 0 &&
            <Alert variant="filled" severity="warning" fullWidth>
              <Typography
                variant="p"
                component="p"
                align="center"
              >
                You don't have any line items added. Press the
                <strong> + </strong> button below to add one.
              </Typography>
            </Alert>
          }
          </Stack>
          <div style={{ width: '100%', height: '350px' }}>
            <DataGrid
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              rows={skus}
              columns={columns}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={(error) => {
                setSnackbar({
                  severity: 'error',
                  message: error
                });
                setShowSnackbar(true);
              }}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: false,
                },
              }}
              onCellModesModelChange={handleCellModesModelChange}
              onCellClick={handleCellClick}
              cellModesModel={cellModesModel}
              experimentalFeatures={{ newEditingApi: true }}
            />
          </div>
        </CardContent>
      </Card>
      { withinFinanceRange(merchandiseTotalCost) &&
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            marginTop: '1rem'
          }}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIosIcon />}
              onClick={() => submitSKUS()}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      }
    </>
  );
}

export default SKUStep;
