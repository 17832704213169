import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CellTowerIcon from '@mui/icons-material/CellTower';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HardwareIcon from '@mui/icons-material/Hardware';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import InfoIcon from '@mui/icons-material/Info';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const Exclusions = () => {
  const [listOpenStates, setListOpenStates] = useState({
    'cellular': false,
    'consumable': false,
    'fee': false,
    'hardware': false,
    'seasonal': false,
    'special': false,
    'subscription': false,
    'entertainment': false,
  });

  const toggleListOpenState = (listName) => {
    setListOpenStates(prevOpenStates => {
      return {
        ...listOpenStates,
        [listName]: !prevOpenStates[listName],
      }
    });
  }

  return (
    <>
      <Card
        raised
        sx={{
          height: '100%',
        }}
      >
        <CardHeader
          title="Exclusions List"
          sx={{
            backgroundColor: '#1e4670',
            color: 'white',
          }}
        />
        <CardContent sx={{ height: '100%', overflowY: 'auto' }}>
          <List>
            <ListItemButton>
              <ListItemIcon>
                <AutoStoriesIcon />
              </ListItemIcon>
              <ListItemText primary="Books" />
            </ListItemButton>

            { /* Cellular */ }
            <ListItemButton onClick={() => toggleListOpenState('cellular')}>
              <ListItemIcon>
                <CellTowerIcon />
              </ListItemIcon>
              <ListItemText primary="Cellular (Expand for Examples)" />
              <Tooltip title="Expand" placement="left">
                { listOpenStates['cellular'] ? <ExpandLess /> : <ExpandMore /> }
              </Tooltip>
            </ListItemButton>
            <Collapse in={listOpenStates['cellular']} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Activity Trackers and Accessories" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Apple Pens" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Apple Watches" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Cell Phones and Cell Phone Accessories" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Fitbits and Accessories" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Smart Watches" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Phone Cases" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Webcams" />
                </ListItemButton>
              </List>
            </Collapse>

            { /* Consumable */ }
            <ListItemButton onClick={() => toggleListOpenState('consumable')}>
              <ListItemIcon>
                <FastfoodIcon />
              </ListItemIcon>
              <ListItemText primary="Consumables/Cleaning Supplies (Expand for Examples)" />
              <Tooltip title="Expand" placement="left">
                { listOpenStates['consumable'] ? <ExpandLess /> : <ExpandMore /> }
              </Tooltip>
            </ListItemButton>
            <Collapse in={listOpenStates['consumable']} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Candy" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Chocolate" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Edible Items" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Pop" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Water" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Cleaning Supplies" />
                </ListItemButton>
              </List>
            </Collapse>

            { /* Clothing */ }
            <ListItemButton>
              <ListItemIcon>
                <CheckroomIcon />
              </ListItemIcon>
              <ListItemText primary="Clothing" />
            </ListItemButton>

            { /* Fee */ }
            <ListItemButton onClick={() => toggleListOpenState('fee')}>
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText primary="Fees (Expand for Examples)" />
              <Tooltip title="Expand" placement="left">
                { listOpenStates['fee'] ? <ExpandLess /> : <ExpandMore /> }
              </Tooltip>
            </ListItemButton>
            <Collapse in={listOpenStates['fee']} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Assembly Fees" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Delivery Fees in Nebraska and Iowa" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Haul Away Fees" />
                </ListItemButton>
              </List>
            </Collapse>

            { /* Fitness Equipment */ }
            <ListItemButton>
              <ListItemIcon>
                <FitnessCenterIcon />
              </ListItemIcon>
              <ListItemText primary="Fitness Equipment" />
            </ListItemButton>

            { /* Hardware */ }
            <ListItemButton onClick={() => toggleListOpenState('hardware')}>
              <ListItemIcon>
                <HardwareIcon />
              </ListItemIcon>
              <ListItemText primary="Installed Items (Expand for Examples)" />
              <Tooltip title="Expand" placement="left">
                { listOpenStates['hardware'] ? <ExpandLess /> : <ExpandMore /> }
              </Tooltip>
            </ListItemButton>
            <Collapse in={listOpenStates['hardware']} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Ceiling Fans" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Cooktops" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Deadbolts" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dishwashers" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Flood Lights" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Flooring" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Light Switches" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Satellite Dishes" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Security Cameras" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Thermostats" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="TV Mounts" />
                </ListItemButton>
              </List>
            </Collapse>

            { /* Seasonal */ }
            <ListItemButton onClick={() => toggleListOpenState('seasonal')}>
              <ListItemIcon>
                <AcUnitIcon />
              </ListItemIcon>
              <ListItemText primary="Seasonal (Expand for Examples)" />
              <Tooltip title="Expand" placement="left">
                { listOpenStates['seasonal'] ? <ExpandLess /> : <ExpandMore /> }
              </Tooltip>
            </ListItemButton>
            <Collapse in={listOpenStates['seasonal']} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Bug Zappers" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Chainsaws" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Grills" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Hoses" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Humidifiers" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Lawn Mowers" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Leaf Blowers" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Pressure Washers" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Snow Blowers" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Trimmers" />
                </ListItemButton>
              </List>
            </Collapse>

            { /* Special */ }
            <ListItemButton onClick={() => toggleListOpenState('special')}>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary="Special Orders (Expand for Examples)" />
              <Tooltip title="Expand" placement="left">
                { listOpenStates['special'] ? <ExpandLess /> : <ExpandMore /> }
              </Tooltip>
            </ListItemButton>
            <Collapse in={listOpenStates['special']} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Items that are not typically stocked" />
                </ListItemButton>
              </List>
            </Collapse>

            { /* Subscription */ }
            <ListItemButton onClick={() => toggleListOpenState('subscription')}>
              <ListItemIcon>
                <CreditCardIcon />
              </ListItemIcon>
              <ListItemText primary="Subscription Cards (Expand for Examples)" />
              <Tooltip title="Expand" placement="left">
                { listOpenStates['subscription'] ? <ExpandLess /> : <ExpandMore /> }
              </Tooltip>
            </ListItemButton>
            <Collapse in={listOpenStates['subscription']} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Gift Cards" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Hulu Cards" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Microsoft Office Cards" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Netflix Cards" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Xbox Cards" />
                </ListItemButton>
              </List>
            </Collapse>

            { /* Toys */ }
            <ListItemButton>
              <ListItemIcon>
                <SmartToyIcon />
              </ListItemIcon>
              <ListItemText primary="Toys" />
            </ListItemButton>

            { /* Entertainment */ }
            <ListItemButton onClick={() => toggleListOpenState('entertainment')}>
              <ListItemIcon>
                <SportsEsportsIcon />
              </ListItemIcon>
              <ListItemText primary="DVD, Entertainment, and Video Games (Expand for Examples)" />
              <Tooltip title="Expand" placement="left">
                { listOpenStates['entertainment'] ? <ExpandLess /> : <ExpandMore /> }
              </Tooltip>
            </ListItemButton>
            <Collapse in={listOpenStates['entertainment']} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Blu-Ray" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="DVDs" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Video Games" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText primary="Vinyl Records" />
                </ListItemButton>
              </List>
            </Collapse>

          </List>
        </CardContent>
      </Card>
    </>
  )
}

export default Exclusions;