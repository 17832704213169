import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import { sendData } from '../../../Util/Socket';

const ContractComplete = (props) => {
  const [showContractVoidModal, setShowContractVoidModal] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [AA, setAA] = useState(props.AA);
  const [AU, setAU] = useState(props.AU);
  const [contract, setContract] = useState(props.contract);

  const voidContract = () => {

    setShowContractVoidModal(false);

    sendData('voidContract', {
      contract: contract,
      genUserId: localStorage.getItem('genUserId'),
    });
  }

  useEffect(() => {


    fetch(`${window.apiUrl}/contracts/${contract}/complete`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('genUserId')}`,
      },
    })
    .then((res) => {

      return res.json();
    })
    .then((data) => {

      setCustomer(data);
    })
    .catch((err) => {
      console.error(err);
    });
  }, []);

  return (
    <>
      <Dialog
        open={showContractVoidModal}
        onClose={() => setShowContractVoidModal(false)}
        aria-labelledby="payment-estimator-modal"
      >
        <DialogTitle
          sx={{
            backgroundColor: '#1e4670',
            color: 'white',
          }}
        >
          Void Contract
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              paddingTop: '1rem',
            }}
          >
            <Grid item xs={12}>
              You are about to void the old contract out and start a new one. Would you like to continue?
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={() => setShowContractVoidModal(false)}
            color="error"
            variant="contained"
          >Cancel</Button>
          <Button
            onClick={() => voidContract()}
            color="primary"
            variant="contained"
          >Yes, Void Contract</Button>
        </DialogActions>
      </Dialog>

      <Card
        sx={{
          width: '100%',
        }}
      >
        <CardContent>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h5" component="h2">
                Contract Complete
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" component="p">
                Thank you {customer?.firstName} {customer?.lastName}!
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" component="p">
                Your contract number is: {contract}
              </Typography>
            </Grid>
            { customer?.storisAccountId &&
              <Grid item>
                <Typography variant="body2" component="p">
                  Your Storis account number is: {customer.storisAccountId}
                </Typography>
              </Grid>
            }
            <Grid item>
              <Typography variant="body2" component="p">
                A copy of your contract will be sent to the e-mail address you provided.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => setShowContractVoidModal(true)}
              >
                Void Contract
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default ContractComplete;
