import { useEffect } from 'react';
import useState from 'react-usestateref';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { sendData, onData, offData } from '../../Util/Socket';
import { formatDateMMDDYYYY } from '../../Util/DateTime';

const ContractsEnding = () => {
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [showApplication, setShowApplication] = useState(false);
  const [currentContract, setCurrentContract] = useState({});
  const [columns, setColumns] = useState([
    {
      headerName: 'Action',
      field: 'action',
      minWidth: 100,
      flex: 0.5,
      renderCell: (params) => (
        <Button
          fullWidth
          variant="contained"
          color="success"
          onClick={() => {
            setCurrentContract(params.row);
            setShowApplication(true);
          }}
        >
          Reapply
        </Button>
      ),
    },
    {
      headerName: 'Days Left',
      field: 'daysTillExp',
      width: 100,
      type: 'number',
      flex: 0.5,
    },
    {
      headerName: 'Contract',
      field: 'contract',
      minWidth: 150,
      type: 'string',
      flex: 0.5,
    },
    {
      headerName: 'First Name',
      field: 'firstName',
      minWidth: 250,
      type: 'string',
      flex: 1,
      valueFormatter: ({ value }) => value.toUpperCase(),
    },
    {
      headerName: 'LastName',
      field: 'lastName',
      minWidth: 250,
      type: 'string',
      flex: 1,
      valueFormatter: ({ value }) => value.toUpperCase(),
    },
  ]);

  const onCorporateContractsEnding = ({ contracts }) => {
    setContracts(contracts);
    setLoading(false);
  }

  const onCorporateReapplyContract = ({ success, contract }) => {
    if (success) {
      setContracts(contracts => contracts.filter(c => c.id !== contract));
    }
  }

  useEffect(() => {


    sendData('corporateContractsEnding', {
      genUserId: localStorage.getItem('genUserId'),
      dealerGroupId: localStorage.getItem('dealerGroupId'),
    });

    onData('corporateContractsEnding', onCorporateContractsEnding);
    onData('corporateReapplyContract', onCorporateReapplyContract);

    return () => {
      offData('corporateContractsEnding');
      offData('corporateReapplyContract');
    }
  }, []);

  return (
    <>

    <Dialog
      open={showApplication}
      onClose={() => setShowApplication(false)}
      fullWidth
    >
      <DialogTitle
        sx={{
          backgroundColor: '#1e4670',
          color: 'white',
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          Estimated Delivery Date
          <IconButton color="light" onClick={() => setShowApplication}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{
            padding: 2,
          }}
        >
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Typography><strong>Name:</strong> {currentContract.firstName} {currentContract.lastName}</Typography>
              <Typography><strong>Contract:</strong> {currentContract.contract}</Typography>
              <Typography><strong>Days Left:</strong> {currentContract.daysTillExp}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                orientation="landscape"
                minDate={new Date()}
                value={deliveryDate}
                onChange={(newValue) => {
                  setDeliveryDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Estimated Delivery Date" />
                )}
                components={{
                  ActionBar: () => null,
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                sendData('corporateReapplyContract', {
                  genUserId: localStorage.getItem('genUserId'),
                  contract: currentContract.contract,
                  deliveryDate: formatDateMMDDYYYY(deliveryDate),
                });

                setShowApplication(false);
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>

    <Card
      raised
      sx={{
        height: '100%',
      }}
    >
      <CardHeader
        title="Expiring Contracts"
        sx={{
          backgroundColor: '#1e4670',
          color: 'white',
        }}
      />
      <CardContent sx={{ height: '100%' }}>
        <div style={{ display: 'flex', height: '90%' }}>
          <DataGrid
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
            columns={columns}
            rows={contracts}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  ssn: false,
                },
              },
            }}
            components={{ Toolbar: GridToolbar, LoadingOverlay: LinearProgress }}
            loading={loading}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
          />
        </div>
      </CardContent>
    </Card>
    </>
  );
}

export default ContractsEnding;
