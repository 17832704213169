import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const ContractCancel = () => {
  return (
    <>
      <Card
        sx={{
          width: '100%',
        }}
      >
        <CardHeader title="Error: Cancelled" />
        <CardContent>
          <Typography variant="h4" component="h3">
            Contract signing cancelled. Please see the sales associate
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default ContractCancel;
