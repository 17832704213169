import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import { useNavigate, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { onData, offData } from '../../Util/Socket';

const CustomerStatus = () => {
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);
  const [contractMessage, setContractMessage] = useState('');
  const [contractStatus, setContractStatus] = useState('');
  const [showContractStatus, setShowContractStatus] = useState(false);
  const [checkPercentage, setCheckPercentage] = useState(0);
  const [checkingStatus, setCheckingStatus] = useState(true);
  const [headerColor, setHeaderColor] = useState('#d32f2f');
  const [ellipsis, setEllipsis, ellipsisRef] = useState('...');
  const location = useLocation();
  const navigate = useNavigate();

  const isApproved = (status) => {
    return (
      status &&
      (status.toLowerCase() === 'approved' || status.toLowerCase() === 'prequalified')
    );
  }

  const onContractStatus = (data) => {
    if (location?.state?.contract) {
      if (data.contract === location.state.contract) {

        if (isApproved(data.status)) {
          setHeaderColor('#388e3c'); // green

          if (!!data?.msg) {
            setContractMessage(data.msg);
          } else {
            setContractMessage('Please wait while we redirect you to complete your application.');

            setTimeout(() => {
              navigate('/dashboard/customer', { state: { contract: data.contract } });
            }, 3000);
          }
        } else if (data.status.toLowerCase() === 'on hold') {
          setHeaderColor('#f57c00'); // orange

          if (!!data?.msg) {
            setContractMessage(data.msg);
          } else {
            setContractMessage('Please see a sales associate for more details.');
          }
        } else {
          setHeaderColor('#d32f2f'); // red
          setError(data?.msg);
          setShowError(true);
        }

        setContractStatus(`${data.status}`);
        setShowContractStatus(true);
        setCheckPercentage(100);
        setCheckingStatus(false);
      }
    }
  }

  useEffect(() => {


    onData('contractStatus', onContractStatus);

    let percentInterval = setInterval(() => {
      if (checkPercentage < 100) {
        if (ellipsisRef.current.length < 3) {
          setEllipsis(ellipsisRef.current + '.');
        } else {
          setEllipsis('.');
        }
        setCheckPercentage(prevPercent => prevPercent + 1);
      } else {
        clearInterval(percentInterval);
      }
    }, 1000);

    return () => {
      offData('contractStatus');
      clearInterval(percentInterval);
    }
  }, []);

  return (
    <Card
      raised
    >
      <CardHeader
        title="Checking Status"
        sx={{ backgroundColor: headerColor, color: 'white' }}
      />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            { checkingStatus &&
              <Typography
                variant="body1"
                component="p"
              >
                Please wait while we check the status of your application {ellipsis}
              </Typography>
            }
            { showError &&
              <Typography
                variant="body1"
                component="p"
              >
                {error}
              </Typography>
            }
            { showContractStatus &&
              <>
              <Typography
                variant="h4"
                component="h4"
              >
                Status: {contractStatus}
              </Typography>
              <Typography
                variant="body1"
                component="p"
              >
                { !(contractMessage?.length > 0) && !isApproved(contractStatus) ?
                  'Please see a sales associate for more details.'
                  :
                  contractMessage
                }
              </Typography>
              </>
            }
          </Grid>
          <Grid item xs={12}>
            { checkingStatus &&
              <LinearProgress
                color="primary"
                variant="determinate"
                value={checkPercentage}
              />
            }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CustomerStatus;
