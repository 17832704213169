const today = () => {
  return new Date();
}

const future30Days = () => {
  let date = new Date();
  return new Date(date.setDate(date.getDate() + 30));
}

const future45Days = () => {
  let date = new Date();
  return new Date(date.setDate(date.getDate() + 45));
}

const future50Days = () => {
  let date = new Date();
  return new Date(date.setDate(date.getDate() + 50));
}

const within45Days = (date) => {
  let newDate = new Date()
  newDate.setHours(0, 0, 0, 0);
  let maxDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 45);
  let dateToCheck = new Date(date);

  if (dateToCheck >= newDate && dateToCheck <= maxDate) {
    return true;
  } else {
    return false;
  }
}

const within30To50Days = (date) => {
  //check if date is within 30 to 50 days from today
  let newDate = new Date();
  newDate.setHours(0, 0, 0, 0);
  let minDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 29);
  let maxDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 50);
  let dateToCheck = new Date(date);

  if (dateToCheck >= minDate && dateToCheck <= maxDate) {
    return true;
  } else {
    return false;
  }
}

const formatDateMMDDYYYY = (date) => {
  //Formats date to MM/DD/YYYY
  let convertedDate = null;
  if (typeof date === 'string') {
    convertedDate = new Date(date);
  } else {
    convertedDate = date;
  }

  let month = convertedDate.getMonth() + 1;
  let day = convertedDate.getDate();
  let year = convertedDate.getFullYear();

  return month + '/' + day + '/' + year;
}

const validateFundingDate = (date) => {
  let newDate = new Date();
  newDate.setHours(0, 0, 0, 0);

  return (new Date(date) > new Date(
    newDate.getFullYear(),
    newDate.getMonth(),
    newDate.getDate() - 21 // 3 weeks ago
  ) &&
  new Date(date) < new Date(
    newDate.getFullYear(),
    newDate.getMonth(),
    newDate.getDate() + 7 // 1 week in the future
  ));
}

const formatUSTime = (time) => {
  const date = new Date(time);
  return `${date.toLocaleTimeString('en-US')}`; // 12:00:00 AM to 12:00:00 PM
}

const getAge = (dob) => {
  let birthDate = new Date(dob);
  let age = today().getFullYear() - birthDate.getFullYear();
  let m = today().getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today().getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

module.exports = {
  today,
  future30Days,
  future45Days,
  future50Days,
  within45Days,
  within30To50Days,
  validateFundingDate,
  formatDateMMDDYYYY,
  formatUSTime,
  getAge,
}
