import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import MessageIcon from '@mui/icons-material/Message';

const ContactUs = () => {
  const [loading, setLoading] = useState(true);
  const [officeHours, setOfficeHours] = useState([
    { day: "Monday", time: "8:00 AM - 6:00 PM" },
    { day: "Tuesday", time: "8:00 AM - 6:00 PM" },
    { day: "Wednesday", time: "8:00 AM - 6:00 PM" },
    { day: "Thursday", time: "8:00 AM - 6:00 PM" },
    { day: "Friday", time: "8:00 AM - 6:00 PM" },
    { day: "Saturday", time: "8:00 AM - 4:00 PM" },
    { day: "Sunday", time: "CLOSED" }
  ]);

  useEffect(() => {


    setLoading(false);
  }, []);

  return (
    <>
      <Card
        raised
      >
        <CardHeader
          title="Contact Us"
          sx={{
            backgroundColor: '#1e4670',
            color: 'white',
          }}
        />
        <CardContent>
          { !loading ?
          <Stack spacing={3}>
            <Typography
              component="h6"
              variant="h6"
            >
              Need to get in touch with us immediately? Use our live chat feature!
            </Typography>
            <Typography>
              The fastest way to contact us is through our live chat feature
              (accessed by pressing the <MessageIcon color="info" /> button at the top of
              the page).
            </Typography>
            <hr />
            <Typography>
              <strong>Phone:</strong> <a href="tel:18777003040">(877) 700-3040</a>
            </Typography>
            <Typography>
              <strong>Fax:</strong> (605) 385-0006
            </Typography>
            <Typography>
              <strong>E-mail:</strong> <a href="mailto:info@smartsalesandlease.com">info@smartsalesandlease.com</a>
            </Typography>
            <Typography>
              <strong>Address:</strong> 3213 W. Main Street Suite #382, Rapid City, 57702
            </Typography>
            <Card style={{width: '18rem'}}>
              <CardHeader
                sx={{backgroundColor: '#1e4670', color: 'white'}}
                title="Hours of Operation"
              />
              <CardContent>
                <Stack spacing={1}>
                  {
                    officeHours.map((officeHour, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Typography
                            component="p"
                            variant="body2"
                          >
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item>
                                {officeHour.day}
                              </Grid>
                              <Grid item>
                                {officeHour.time}
                              </Grid>
                            </Grid>
                          </Typography>
                          <Divider />
                        </React.Fragment>
                      );
                    })
                  }
                </Stack>
              </CardContent>
            </Card>
          </Stack>
          :
          <LinearProgress color="primary" />
        }
        </CardContent>
      </Card>
    </>
  )
}

export default ContactUs;
