import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InputMask from 'react-input-mask';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { today, getAge } from '../../../Util/DateTime';
import {
  states,
  generations,
  employmentLengths,
  employmentTypes,
  employmentIncomes
} from '../../../Util/Store';
import { sendData, onData, offData } from '../../../Util/Socket';

const CustomerStep = (props) => {
  const [taxError, setTaxError] = useState(false);
  const [showCosignerInfo, setShowCosignerInfo] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [cosignerErrors, setCosignerErrors] = useState({});
  const [customerErrors, setCustomerErrors, customerErrorsRef] = useState({});
  const [employmentErrors, setEmploymentErrors, employmentErrorsRef] = useState({});
  const [cosignerGeneration, setCosignerGeneration, cosignerGenerationRef] = useState({ value: '', text: '' });
  const [cosignerGenerationInput, setCosignerGenerationInput] = useState('');
  const [cosignerInfo, setCosignerInfo, cosignerRef] = useState({
    firstName: '',
    middleInitial: '',
    lastName: '',
    dob: '',
    ssn: '',
    phone: ''
  });
  const [customerGeneration, setCustomerGeneration, customerGenerationRef] = useState({ value: '', text: '' });
  const [customerGenerationInput, setCustomerGenerationInput] = useState('');
  const [customerState, setCustomerState, customerStateRef] = useState({ value: '', text: '' });
  const [customerStateInput, setCustomerStateInput] = useState('');
  const [customerInfo, setCustomerInfo, customerRef] = useState({
    firstName: '',
    middleInitial: '',
    lastName: '',
    dob: '',
    ssn: '',
    phone: '',
    email: '',
    address: '',
    secondaryAddress: '',
    city: '',
    zip: '',
    cosigner: false
  });
  const [employmentType, setEmploymentType, employmentTypeRef] = useState({ value: '', text: '' });
  const [employmentTypeInput, setEmploymentTypeInput] = useState('');
  const [employmentLength, setEmploymentLength, employmentLengthRef] = useState({ value: '', text: '' });
  const [employmentLengthInput, setEmploymentLengthInput] = useState('');
  const [employmentIncome, setEmploymentIncome, employmentIncomeRef] = useState({ value: '', text: '' });
  const [employmentIncomeInput, setEmploymentIncomeInput] = useState('');
  const [employmentInfo, setEmploymentInfo, employmentRef] = useState({
    name: '',
    phone: '',
  });

  const navigate = useNavigate();

  const handleShowCosignerInfo = () => setShowCosignerInfo(!showCosignerInfo);

  const updateCosignerInfo = (field, value) => {
    setCosignerInfo(prevInfo => {
      return {
        ...prevInfo,
        [field]: value
      }
    });

    if (!!cosignerErrors[field]) {
      setCosignerErrors({
        ...cosignerErrors,
        [field]: null
      });
    }
  }

  const updateCustomerInfo = (field, value) => {
    if (field === 'cosigner') {
      setCosignerErrors({});
      handleShowCosignerInfo();
    }

    setCustomerInfo(prevInfo => {
      return {
        ...prevInfo,
        [field]: value
      }
    });

    //Check for errors on the current field and clear it
    if (!!customerErrors[field]) {
      setCustomerErrors({
        ...customerErrors,
        [field]: null
      });
    }
  }

  const updateEmploymentInfo = (field, value) => {
    setEmploymentInfo(prevInfo => {
      return {
        ...employmentInfo,
        [field]: value
      }
    });

    //Check for errors on the current field and clear it
    if (!!employmentErrors[field]) {
      setEmploymentErrors({
        ...employmentErrors,
        [field]: null
      });
    }
  }

  const validateCosignerInfo = () => {
    let errors = {};

    if (!cosignerRef.current.firstName.length > 0) {
      errors.firstName = 'First Name is required';
    }

    if (cosignerRef.current.middleInitial.length !== 1) {
      errors.middleInitial = 'Middle Initial must be one character.';
    } else if (!isNaN(cosignerRef.current.middleInitial)) {
      errors.middleInitial = 'Middle Initial must be a letter';
    }

    if (!cosignerRef.current.lastName.length > 0) {
      errors.lastName = 'Last Name is required';
    }

    let birthDate = new Date(cosignerRef.current.dob);
    if (typeof(cosignerRef.current.dob) !== 'object') {
      errors.dob = 'Date of Birth is required.';
    } else if (birthDate > new Date()) {
      errors.dob = 'Date of Birth must be in the past.';
    } else if (birthDate < new Date(1900, 0, 1)) {
      errors.dob = 'Date of Birth must be after January 1, 1900.';
    } else if (getAge(cosignerRef.current.dob) < 18) {
      errors.dob = 'Date of Birth must be 18 or older.';
    }

    let ssnRegex = /^\d{3}-\d{2}-\d{4}$/; //123-45-6789
    if (!ssnRegex.test(cosignerRef.current.ssn)) {
      errors.ssn = 'SSN must be in the format ###-##-####.';
    } else if (cosignerRef.current.ssn === customerRef.current.ssn) {
      errors.ssn = 'SSN must be different than the primary applicant.';
    }

    let phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}(\s|.|-){0,3}\d{4}$/;
    if (!phoneRegex.test(cosignerRef.current.phone)) {
      errors.phone = 'Phone number must be in the format (###) ###-####.';
    }

    return errors;
  }

  const validateCustomerInfo = () => {
    let errors = {};

    if (!(customerRef.current.firstName?.length > 0)) {
      errors.firstName = 'First Name is required.';
    }

    if (customerRef.current.middleInitial?.length !== 1) {
      errors.middleInitial = 'Middle Initial must be one character';
    } else if (!isNaN(customerRef.current.middleInitial)) {
      errors.middleInitial = 'Middle Initial must be a letter';
    }

    if (!(customerRef.current.lastName?.length > 0)) {
      errors.lastName = 'Last Name is required.';
    }

    let birthDate = new Date(customerRef.current.dob);
    if (typeof(customerRef.current.dob) !== 'object') {
      errors.dob = 'Please verify you have entered a valid date.';
    } else if (birthDate > new Date()) {
      errors.dob = 'Date of Birth must be in the past.';
    } else if (birthDate < new Date(1900, 0, 1)) {
      errors.dob = 'Date of Birth must be after January 1, 1900.';
    } else if (getAge(customerRef.current.dob) < 18) {
      errors.dob = 'Date of Birth must be 18 or older.';
    }

    let ssnRegex = /^\d{3}-\d{2}-\d{4}$/; //123-45-6789
    if (!ssnRegex.test(customerRef.current.ssn)) {
      errors.ssn = 'SSN must be in the format ###-##-####.';
    }

    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(customerRef.current.email)) {
      errors.email = 'Email must be formatted ########@###.###';
    }

    let phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}(\s|.|-){0,3}\d{4}$/;
    if (!phoneRegex.test(customerRef.current.phone)) {
      errors.phone = 'Phone number must be in the format (###) ###-####.';
    }

    if (!(customerRef.current.address?.length > 0)) {
      errors.address = 'Address is required.';
    }


    if (!(customerRef.current.city?.length > 0)) {
      errors.city = 'City is required.';
    }

    if (!(customerStateRef.current.value?.length > 0)) {
      errors.state = 'State is required.';
    }

    let zipRegex = /^\d{5}$/;
    if (!(customerRef.current.zip?.length > 0)) {
      errors.zip = 'Zip Code is required.';
    } else if (!zipRegex.test(customerRef.current.zip)) {
      errors.zip = 'Zip Code must be 5 digits.';
    }

    return errors;
  }

  const validateEmploymentInfo = () => {
    let errors = {};

    if (!(employmentRef.current.name?.length > 0)) {
      errors.name = 'Employer name is required.';
    }

    if (typeof employmentLengthRef.current.value !== 'number') {
      errors.length = 'Length of employment is required.';
    }

    let phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}(\s|.|-){0,3}\d{4}$/;
    if (!phoneRegex.test(employmentRef.current.phone)) {
      errors.phone = 'Phone number must be in the format (###) ###-####.';
    }

    if (!(employmentTypeRef.current.value?.length > 0)) {
      errors.type = 'Employment type is required.';
    }

    if (typeof employmentIncomeRef.current.value !== 'number') {
      errors.monthlyIncome = 'Employment income is required.';
    }

    return errors;
  }

  const submitContract = () => {
    let newCustomerErrors = validateCustomerInfo();
    let newEmploymentErrors = validateEmploymentInfo();
    let newCosignerErrors = {};

    if (customerRef.current.cosigner) {
      newCosignerErrors = validateCosignerInfo();

      setCosignerErrors({
        ...cosignerErrors,
        ...newCosignerErrors
      });
    }

    setCustomerErrors({
      ...customerErrors,
      ...newCustomerErrors
    });
    setEmploymentErrors({
      ...employmentErrors,
      ...newEmploymentErrors
    });

    if (customerRef.current.cosigner) {
      if (Object.keys(newCustomerErrors).length === 0
          && Object.keys(newEmploymentErrors).length === 0
          && Object.keys(newCosignerErrors).length === 0
        ) {

        let unformattedCustomerSSN = customerRef.current.ssn.replace(/-/g, '');
        let unformattedCustomerPhone = customerRef.current.phone.replace(/\D/g, '');
        let unformattedEmployerPhone = employmentRef.current.phone.replace(/\D/g, '');
        let formattedCustomerDOB = new Date(customerRef.current.dob).toISOString().substring(0, 10);

        let contractData = {
          genUserId: localStorage.getItem('genUserId'),
          dealerId: localStorage.getItem('dealerId'),
          firstName: customerRef.current.firstName,
          middleInitial: customerRef.current.middleInitial,
          lastName: customerRef.current.lastName,
          gen: customerGenerationRef.current.value,
          dob: formattedCustomerDOB,
          ssn: unformattedCustomerSSN,
          phone: unformattedCustomerPhone,
          email: customerRef.current.email,
          address: customerRef.current.address,
          apt: customerRef.current.secondaryAddress,
          city: customerRef.current.city,
          state: customerStateRef.current.value,
          zip: customerRef.current.zip,
          employer: {
            name: employmentRef.current.name,
            durationEmployed: employmentLengthRef.current.value,
            phone: unformattedEmployerPhone,
            employmentType: employmentTypeRef.current.value,
            pay: employmentIncomeRef.current.value
          }
        }

        let unformattedCosignerSSN = cosignerRef.current.ssn.replace(/-/g, '');
        let unformattedCosignerPhone = cosignerRef.current.phone.replace(/\D/g, '');
        let formattedCosignerDOB = new Date(cosignerRef.current.dob).toISOString().substring(0, 10);

        contractData.cosigner = {
          coFirstName: cosignerRef.current.firstName,
          coMiddleInitial: cosignerRef.current.middleInitial,
          coLastName: cosignerRef.current.lastName,
          coGen: cosignerGenerationRef.current.value,
          coDob: formattedCosignerDOB,
          coSsn: unformattedCosignerSSN,
          coMobileNum: unformattedCosignerPhone
        }

        sendData('submitContract', contractData);
      }
    } else {
      if (Object.keys(newCustomerErrors).length === 0
          && Object.keys(newEmploymentErrors).length === 0
        ) {

        let unformattedCustomerSSN = customerRef.current.ssn.replace(/-/g, '');
        let unformattedCustomerPhone = customerRef.current.phone.replace(/\D/g, '');
        let unformattedEmployerPhone = employmentRef.current.phone.replace(/\D/g, '');
        let formattedCustomerDOB = new Date(customerRef.current.dob).toISOString().substring(0, 10);

        let contractData = {
          genUserId: localStorage.getItem('genUserId'),
          dealerId: localStorage.getItem('dealerId'),
          firstName: customerRef.current.firstName,
          middleInitial: customerRef.current.middleInitial,
          lastName: customerRef.current.lastName,
          gen: customerGenerationRef.current.value,
          dob: formattedCustomerDOB,
          ssn: unformattedCustomerSSN,
          phone: unformattedCustomerPhone,
          email: customerRef.current.email,
          address: customerRef.current.address,
          apt: customerRef.current.secondaryAddress,
          city: customerRef.current.city,
          state: customerStateRef.current.value,
          zip: customerRef.current.zip,
          employer: {
            name: employmentRef.current.name,
            durationEmployed: employmentLengthRef.current.value,
            phone: unformattedEmployerPhone,
            employmentType: employmentTypeRef.current.value,
            pay: employmentIncomeRef.current.value
          }
        }


        sendData('submitContract', contractData);
      }
    }
  }

  const onSubmitContract = (contract) => {
    navigate('/dashboard/customer-status', { state: { contract: contract } });
  }

  const onTaxEstimate = ({ success }) => {
    if (success) {
      setTaxError(false);
    } else {
      setTaxError(true);
    }
  }

  useEffect(() => {


    onData('submitContract', onSubmitContract);
    onData('taxEstimate', onTaxEstimate);

    return () => {
      offData('submitContract');
      offData('taxEstimate');
    }
  }, []);

  return (
    <>
      <Card
        raised
        sx={{marginBottom: "1rem"}}
      >
        <CardHeader
          sx={{backgroundColor: '#1e4670', color: 'white'}}
          title="Personal Information"
        />
        <CardContent>
          <Grid container spacing={3}>
            { taxError &&
              <Grid item xs={12}>
                <Alert variant="filled" severity="error" fullWidth>
                  It looks like there is no tax information for the provided zip code. Please ensure you have entered
                  the correct zip code and try again. If the zipcode is correct, please contact customer service at (877) 700-3040.
                </Alert>
              </Grid>
            }
            <Grid item xs={4}>
              <TextField
                label="First Name"
                variant="standard"
                fullWidth
                value={customerInfo.firstName ?? ""}
                onChange={(e) => {
                  updateCustomerInfo(
                    'firstName', e.target.value
                  );
                }
                }
                error={customerErrors?.firstName ? true : false}
                helperText={customerErrors?.firstName ? customerErrors.firstName : ''}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Middle Initial"
                variant="standard"
                fullWidth
                maxLength={1}
                value={customerInfo.middleInitial ?? ""}
                onChange={(e) => {
                  updateCustomerInfo(
                    'middleInitial', e.target.value
                  );
                }
                }
                inputProps={{ maxLength: 1 }}
                error={customerErrors?.middleInitial ? true : false}
                helperText={customerErrors?.middleInitial ? customerErrors.middleInitial : ''}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Last Name"
                variant="standard"
                fullWidth
                value={customerInfo.lastName ?? ""}
                onChange={(e) => {
                  updateCustomerInfo(
                    'lastName', e.target.value
                  );
                }
                }
                error={customerErrors?.lastName ? true : false}
                helperText={customerErrors?.lastName ? customerErrors.lastName : ''}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                autoHighlight
                autoSelect
                value={customerGeneration}
                onChange={
                  (e, newGeneration) => {
                    setCustomerGeneration(newGeneration);
                  }
                }
                inputValue={customerGenerationInput}
                onInputChange={(e, newGeneration) => setCustomerGenerationInput(newGeneration)}
                id="customerGeneration"
                options={generations}
                isOptionEqualToValue={(option, value) => option.value == value.value}
                getOptionLabel={(option) => {
                  return option.text ?? "";
                }}
                renderInput={(params) =>
                  <TextField
                    label="Generation"
                    variant="standard"
                    fullWidth
                    {...params}
                  />
                }
              />
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date of Birth"
                  value={customerInfo.dob ?? ""}
                  disableFuture
                  onChange={(date) => {
                    updateCustomerInfo(
                      'dob', date
                    );
                  }
                  }
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      variant="standard"
                      fullWidth
                      error={customerErrors?.dob ? true : false}
                      helperText={customerErrors?.dob ? customerErrors.dob : 'MM/DD/YYYY'}
                    />
                  }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <InputMask
                mask="999-99-9999"
                maskChar=""
                value={customerInfo.ssn ?? ""}
                onChange={(e) => {
                  updateCustomerInfo(
                    'ssn', e.target.value
                  );
                }}
              >
              { () =>
                <TextField
                  label="SSN"
                  variant="standard"
                  fullWidth
                  error={customerErrors?.ssn ? true : false}
                  helperText={customerErrors?.ssn ? customerErrors.ssn : ''}
                />
              }
              </InputMask>
            </Grid>
            <Grid item xs={2}>
              <InputMask
                mask="(999) 999-9999"
                maskChar=" "
                value={customerInfo.phone ?? ""}
                onChange={(e) => {
                  updateCustomerInfo(
                    'phone', e.target.value
                  );
                }}
              >
              { () =>
                <TextField
                  label="Phone"
                  variant="standard"
                  fullWidth
                  error={customerErrors?.phone ? true : false}
                  helperText={customerErrors?.phone ? customerErrors.phone : ''}
                />
              }
              </InputMask>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Email"
                variant="standard"
                fullWidth
                value={customerInfo.email ?? ""}
                onChange={(e) => {
                  updateCustomerInfo(
                    'email', e.target.value
                  );
                }
                }
                error={customerErrors?.email ? true : false}
                helperText={customerErrors?.email ? customerErrors.email : ''}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                label="Address"
                variant="standard"
                fullWidth
                value={customerInfo.address ?? ""}
                onChange={(e) => {
                  updateCustomerInfo(
                    'address', e.target.value
                  );
                }
                }
                error={customerErrors?.address ? true : false}
                helperText={customerErrors?.address ? customerErrors.address : ''}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Apt #, Suite, PO Box..."
                variant="standard"
                fullWidth
                value={customerInfo.secondaryAddress ?? ""}
                onChange={(e) => {
                  updateCustomerInfo(
                    'secondaryAddress', e.target.value
                  );
                }
                }
                error={customerErrors?.secondaryAddress ? true : false}
                helperText={customerErrors?.secondaryAddress ? customerErrors.secondaryAddress : ''}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="City"
                variant="standard"
                fullWidth
                value={customerInfo.city ?? ""}
                onChange={(e) => {
                  updateCustomerInfo(
                    'city', e.target.value
                  );
                }
                }
                error={customerErrors?.city ? true : false}
                helperText={customerErrors?.city ? customerErrors.city : ''}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                autoHighlight
                autoSelect
                value={customerState}
                onChange={
                  (e, newState) => {
                    setCustomerState(newState);
                    setCustomerErrors({ ...customerErrors, state: null });
                  }
                }
                inputValue={customerStateInput}
                onInputChange={(e, newState) => setCustomerStateInput(newState)}
                id="state"
                options={states}
                isOptionEqualToValue={(option, value) => option.value == value.value}
                getOptionLabel={(option) => {
                  return option.text ?? "";
                }}
                renderInput={(params) =>
                  <TextField
                    label="State"
                    variant="standard"
                    error={customerErrors?.state ? true : false}
                    helperText={customerErrors?.state ? customerErrors.state : ''}
                    fullWidth
                    {...params}
                  />
                }
              />
            </Grid>
            <Grid item xs={2}>
                <TextField
                  label="Zip Code"
                  variant="standard"
                  fullWidth
                  value={customerInfo.zip ?? ""}
                  onChange={(e) => {
                    updateCustomerInfo(
                      'zip', e.target.value
                    );
                  }}
                  onBlur={(e) => {
                    if (e.target.value?.length === 5) {
                      sendData('taxEstimate', { zipCode: e.target.value });
                    }
                  }}
                  error={customerErrors?.zip ? true : false}
                  helperText={customerErrors?.zip ? customerErrors.zip : ''}
                />
            </Grid>
            <Grid item xs={2}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={customerInfo.cosigner ?? false}
                      onChange={(e) => {
                        updateCustomerInfo(
                          'cosigner', e.target.checked
                        );
                      }
                      }
                    />
                  }
                  label="Cosigner"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      { customerInfo.cosigner &&
        <Card
          raised
          sx={{marginBottom: "1rem"}}
        >
          <CardHeader
            sx={{backgroundColor: '#1e4670', color: 'white'}}
            title="Cosigner Information"
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TextField
                  label="First Name"
                  variant="standard"
                  fullWidth
                  value={cosignerInfo.firstName ?? ""}
                  onChange={(e) => {
                    updateCosignerInfo(
                      'firstName', e.target.value
                    );
                  }
                  }
                  error={cosignerErrors?.firstName ? true : false}
                  helperText={cosignerErrors?.firstName ? cosignerErrors.firstName : ''}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Middle Initial"
                  variant="standard"
                  fullWidth
                  value={cosignerInfo.middleInitial ?? ""}
                  onChange={(e) => {
                    updateCosignerInfo(
                      'middleInitial', e.target.value
                    );
                  }
                  }
                  inputProps={{ maxLength: 1 }}
                  error={cosignerErrors?.middleInitial ? true : false}
                  helperText={cosignerErrors?.middleInitial ? cosignerErrors.middleInitial : ''}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Last Name"
                  variant="standard"
                  fullWidth
                  value={cosignerInfo.lastName ?? ""}
                  onChange={(e) => {
                    updateCosignerInfo(
                      'lastName', e.target.value
                    );
                  }
                  }
                  error={cosignerErrors?.lastName ? true : false}
                  helperText={cosignerErrors?.lastName ? cosignerErrors.lastName : ''}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  autoHighlight
                  autoSelect
                  value={cosignerGeneration}
                  onChange={
                    (e, newGeneration) => {
                      setCosignerGeneration(newGeneration);
                    }
                  }
                  inputValue={cosignerGenerationInput}
                  onInputChange={(e, newGeneration) => setCosignerGenerationInput(newGeneration)}
                  id="employmentType"
                  options={generations}
                  isOptionEqualToValue={(option, value) => option.value == value.value}
                  getOptionLabel={(option) => {
                    return option.text ?? "";
                  }}
                  renderInput={(params) =>
                    <TextField
                      label="Generation"
                      variant="standard"
                      fullWidth
                      {...params}
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date of Birth"
                    value={cosignerInfo.dob ?? ""}
                    disableFuture
                    onChange={(date) => {
                      updateCosignerInfo(
                        'dob', date
                      );
                    }
                    }
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        variant="standard"
                        fullWidth
                        error={cosignerErrors?.dob ? true : false}
                        helperText={cosignerErrors?.dob ? cosignerErrors.dob : 'MM/DD/YYYY'}
                      />
                    }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <InputMask
                  mask="999-99-9999"
                  maskChar=" "
                  value={cosignerInfo.ssn ?? ""}
                  onChange={(e) => {
                    updateCosignerInfo(
                      'ssn', e.target.value
                    );
                  }}
                >
                  {() =>
                    <TextField
                      label="SSN"
                      variant="standard"
                      fullWidth
                      error={cosignerErrors?.ssn ? true : false}
                      helperText={cosignerErrors?.ssn ? cosignerErrors.ssn : ''}
                    />
                  }
                </InputMask>
              </Grid>
              <Grid item xs={4}>
                <InputMask
                  mask="(999) 999-9999"
                  maskChar=" "
                  value={cosignerInfo.phone ?? ""}
                  onChange={(e) => {
                    updateCosignerInfo(
                      'phone', e.target.value
                    );
                  }}
                >
                { () =>
                  <TextField
                    label="Phone"
                    variant="standard"
                    fullWidth
                    error={cosignerErrors?.phone ? true : false}
                    helperText={cosignerErrors?.phone ? cosignerErrors.phone : ''}
                  />
                }
                </InputMask>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }
      <Card
        raised
        sx={{marginBottom: 1}}
      >
        <CardHeader
          sx={{backgroundColor: '#1e4670', color: 'white'}}
          title="Employment Information"
        />
        <CardContent>
          <Grid container spacing={3} sx={{ paddingBottom: 1 }}>
            <Grid item xs={8}>
              <TextField
                label="Employer Name"
                variant="standard"
                fullWidth
                value={employmentInfo.name ?? ""}
                onChange={(e) => {
                  updateEmploymentInfo(
                    'name', e.target.value
                  );
                }
                }
                error={employmentErrors?.name ? true : false}
                helperText={employmentErrors?.name ? employmentErrors.name : ''}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                autoHighlight
                autoSelect
                value={employmentLength}
                onChange={
                  (e, newLength) => {
                    setEmploymentLength(newLength);
                    setEmploymentErrors({ ...employmentErrors, length: null });
                  }
                }
                inputValue={employmentLengthInput}
                onInputChange={(e, newLength) => setEmploymentLengthInput(newLength)}
                id="employmentLength"
                options={employmentLengths}
                isOptionEqualToValue={(option, value) => option.value == value.value}
                getOptionLabel={(option) => {
                  return option.text ?? "";
                }}
                renderInput={(params) =>
                  <TextField
                    label="Employment Length"
                    variant="standard"
                    error={employmentErrors?.length ? true : false}
                    helperText={employmentErrors?.length ? employmentErrors.length : ''}
                    fullWidth
                    {...params}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputMask
                mask="(999) 999-9999"
                maskChar=" "
                value={employmentInfo.phone ?? ""}
                onChange={(e) => {
                  updateEmploymentInfo(
                    'phone', e.target.value
                  );
                }}
              >
              { () =>
                <TextField
                  label="Employer Phone"
                  variant="standard"
                  fullWidth
                  error={employmentErrors?.phone ? true : false}
                  helperText={employmentErrors?.phone ? employmentErrors.phone : ''}
                />
              }
              </InputMask>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                autoHighlight
                autoSelect
                value={employmentType}
                onChange={
                  (e, newType) => {
                    setEmploymentType(newType);
                    setEmploymentErrors({ ...employmentErrors, type: null });
                  }
                }
                inputValue={employmentTypeInput}
                onInputChange={(e, newType) => setEmploymentTypeInput(newType)}
                id="employmentType"
                options={employmentTypes}
                isOptionEqualToValue={(option, value) => option.value == value.value}
                getOptionLabel={(option) => {
                  return option.text ?? "";
                }}
                renderInput={(params) =>
                  <TextField
                    label="Employment Type"
                    variant="standard"
                    error={employmentErrors?.type ? true : false}
                    helperText={employmentErrors?.type ? employmentErrors.type : ''}
                    fullWidth
                    {...params}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                autoHighlight
                autoSelect
                value={employmentIncome}
                onChange={
                  (e, newIncome) => {
                    setEmploymentIncome(newIncome);
                    setEmploymentErrors({ ...employmentErrors, monthlyIncome: null });
                  }
                }
                inputValue={employmentIncomeInput}
                onInputChange={(e, newIncome) => setEmploymentIncomeInput(newIncome)}
                id="employmentType"
                options={employmentIncomes}
                isOptionEqualToValue={(option, value) => option.value == value.value}
                getOptionLabel={(option) => {
                  return option.text ?? "";
                }}
                renderInput={(params) =>
                  <TextField
                    label="Gross Monthly Income"
                    variant="standard"
                    error={employmentErrors?.monthlyIncome ? true : false}
                    helperText={employmentErrors?.monthlyIncome ? employmentErrors.monthlyIncome : ''}
                    fullWidth
                    {...params}
                  />
                }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <FormGroup>
            <FormControlLabel
              label="You understand that you are providing 'written instructions'
                to Smart Sales and Lease under the Fair Credit Reporting Act
                authorizing Smart Sales and Lease to obtain information from your
                personal credit profile. You authorize Smart Sales and Lease to
                obtain such information solely to prequalify you for leasing options."
              control={
                <Checkbox
                  checked={agreedToTerms}
                  onChange={(e) => {
                    setAgreedToTerms(e.target.checked);
                  }}
                />
              }
            />
          </FormGroup>
        </Grid>
        <Grid item>
          { agreedToTerms &&
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIosIcon />}
              onClick={() => {
                submitContract();
              }}
              disabled={taxError}
            >Next</Button>
          }
        </Grid>
      </Grid>
    </>
  );
}

export default CustomerStep;
