import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const CustomerVideo = (props) => {
  useEffect(() => {

  }, [props]);

  return (
    <>
      <Dialog fullScreen open={props.show} onClose={props.close}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <video width="100%" height="100%" controls autoPlay>
            <source
              src="https://cdn.smartsalesandlease.com/dealer-assets/videos/Smart-Sales-and-Lease_Ashley-$79.mp4"
              type="video/mp4"
              alt="Your browser does not support the video player."
            />
          </video>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CustomerVideo;
