import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PDFDownload from '../Fragments/PDFDownload';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { currencyFormatter } from '../../Util/Finance';
import { formatDateMMDDYYYY } from '../../Util/DateTime';
import { sendData, onData, offData } from '../../Util/Socket';
import { downloadCSV } from '../../Util/CSV';

const FundingReports = () => {
  const [loading, setLoading] = useState(true);
  const [fundingReports, setFundingReports] = useState([]);
  const [pdfData, setPdfData] = useState(null);
  const [showPDFDownload, setShowPDFDownload] = useState(false);
  const [columns, setColumns] = useState([
    {
      headerName: 'Batch ID',
      field: 'FundingBatchID',
      flex: 1,
      type: 'string',
    },
    {
      headerName: 'Batch Inserted',
      field: 'InsertDate',
      flex: 1,
      type: 'string',
      valueFormatter: ({ value }) => formatDateMMDDYYYY(value),
    },
    {
      headerName: 'Batch Total',
      field: 'BatchTotal',
      flex: 1,
      type: 'number',
      valueFormatter: ({ value }) => currencyFormatter.format(value),
    },
    {
      headerName: 'Actions',
      type: 'actions',
      field: 'actions',
      flex: 2.5,
      filterable: false,
      getActions: ({ row }) => {
        return (
          [
            <Button
              variant="contained"
              color="error"
              startIcon={<PictureAsPdfIcon />}
              onClick={() =>corporateFundingReport(row.FundingBatchID, 'pdf')}
            >
              PDF
            </Button>,
            <Button
              variant="contained"
              color="success"
              startIcon={<CloudDownloadIcon />}
              onClick={() => corporateFundingReport(row.FundingBatchID, 'csv')}
            >
              CSV
            </Button>
          ]
        );
      },
    }
  ]);

  const corporateGetFundingReports = () => {
    sendData('corporateGetFundingReports', {
      genUserId: localStorage.getItem('genUserId'),
    });
  }

  const onCorporateGetFundingReports = ({ data }) => {
    setFundingReports(data);
    setLoading(false);
  }

  const corporateFundingReport = (batchId, type) => {
    sendData('corporateFundingReport', {
      genUserId: localStorage.getItem('genUserId'),
      batchId: batchId,
      fileExt: type,
    });
  }

  const onCorporateFundingReport = ({ data, fileExt, batchId }) => {
    if (fileExt.toLowerCase() === 'pdf') {
      setPdfData(data);
      setShowPDFDownload(true);
    } else {
      downloadCSV(data, batchId);
    }
  }

  useEffect(() => {

    corporateGetFundingReports();

    onData('corporateGetFundingReports', onCorporateGetFundingReports);
    onData('corporateFundingReport', onCorporateFundingReport);

    return () => {
      offData('corporateGetFundingReports');
      offData('corporateFundingReport');
    }
  }, []);

  return (
    <>
      { showPDFDownload &&
        <PDFDownload
          open={showPDFDownload}
          onClose={() => setShowPDFDownload(false)}
          src={pdfData}
        />
      }

      <Card
        raised
      >
        <CardHeader
          title="Funding Reports"
          sx={{
            backgroundColor: "#1e4670",
            color: "white",
          }}
        />
        <CardContent
          sx={{
            height: 'calc(100vh - 200px)',
          }}
        >
          <DataGrid
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
            columns={columns}
            rows={fundingReports}
            components={{ Toolbar: GridToolbar, LoadingOverlay: LinearProgress }}
            loading={loading}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
          />
        </CardContent>
      </Card>
    </>
  );
}

export default FundingReports;
