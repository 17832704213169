import React, { useEffect, useCallback } from 'react';
import useState from 'react-usestateref';
import PropTypes from 'prop-types';
import {
  DataGrid,
  GridToolbar,
  useGridApiContext,
  GridCellModes,
  GRID_DATE_COL_DEF,
} from '@mui/x-data-grid';
import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import locale from 'date-fns/locale/en-US';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import CancelFunding from '../Fragments/CancelFunding';
import { sendData, onData, offData } from '../../Util/Socket';
import { currencyFormatter } from '../../Util/Finance';
import { formatDateMMDDYYYY, validateFundingDate } from '../../Util/DateTime';

const useUpdateDeliveryDate = () => {
  return useCallback(
    (rowData) =>
      new Promise((resolve, reject) => {
          if (!rowData.deliveryDate) {
            resolve({ ...rowData, deliveryDate: "" });
          } else {
            resolve({ ...rowData, deliveryDate: rowData.deliveryDate });
          }
        }
      ),
    [],
  );
};

function buildApplyDateFilterFn(filterItem, compareFn, showTime = false) {
  if (!filterItem.value) {
    return null;
  }

  const filterValueMs = filterItem.value.getTime();

  return ({ value }) => {
    if (!value) {
      return false;
    }

    // Make a copy of the date to not reset the hours in the original object
    const dateCopy = new Date(value);
    dateCopy.setHours(
      showTime ? value.getHours() : 0,
      showTime ? value.getMinutes() : 0,
      0,
      0,
    );

    const cellValueMs = dateCopy.getTime();

    return compareFn(cellValueMs, filterValueMs);
  };
}

function getDateFilterOperators(showTime = false) {
  return [
    {
      value: 'is',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 === value2,
          showTime,
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: 'not',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 !== value2,
          showTime,
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: 'after',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 > value2,
          showTime,
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: 'onOrAfter',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 >= value2,
          showTime,
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: 'before',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 < value2,
          showTime,
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: 'onOrBefore',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 <= value2,
          showTime,
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: 'isEmpty',
      getApplyFilterFn: () => {
        return ({ value }) => {
          return value == null;
        };
      },
      requiresFilterValue: false,
    },
    {
      value: 'isNotEmpty',
      getApplyFilterFn: () => {
        return ({ value }) => {
          return value != null;
        };
      },
      requiresFilterValue: false,
    },
  ];
}

const dateAdapter = new AdapterDateFns({ locale });

/**
 * `date` column
 */

const dateColumnType = {
  ...GRID_DATE_COL_DEF,
  resizable: false,
  renderEditCell: (params) => {
    return <GridEditDateCell {...params} />;
  },
  filterOperators: getDateFilterOperators(),
  valueFormatter: (params) => {
    if (typeof params.value === 'string') {
      return params.value;
    }
    if (params.value && !isNaN(params.value)) {
      return dateAdapter.format(params.value, 'keyboardDate');
    }
    return '';
  },
};

const GridEditDateTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    fontSize: 'inherit',
  },
});

function GridEditDateCell({ id, field, value }) {
  const apiRef = useGridApiContext();

  const handleChange = (newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <DatePicker
      value={value || null}
      minDate={new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 21 // 3 weeks ago
      )}
      maxDate={new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 7 // 1 week in the future
      )}
      renderInput={(params) => <GridEditDateTextField fullWidth {...params} />}
      onChange={handleChange}
    />
  );
}

GridEditDateCell.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The column field of the cell that triggered the event.
   */
  field: PropTypes.string.isRequired,
  /**
   * The grid row id.
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

function GridFilterDateInput(props) {
  const { item, applyValue, apiRef } = props;

  const handleFilterChange = (newValue) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <DatePicker
      variant="standard"
      value={item.value || ""}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={apiRef.current.getLocaleText('filterPanelInputLabel')}
        />
      )}
      InputAdornmentProps={{
        sx: {
          '& .MuiButtonBase-root': {
            marginRight: -1,
          },
          '& .MuiDataGrid-cell--editable': {
            backgroundColor: 'rgb(217 243 190)'
          },
        },
      }}
      onChange={handleFilterChange}
    />
  );
}

const AA = process.env.REACT_APP_AA;
const AU = process.env.REACT_APP_AU;

const Funding = () => {
  const [loading, setLoading] = useState(true);
  const [showCancelFunding, setShowCancelFunding] = useState(false);
  const [currentContract, setCurrentContract] = useState(null);
  const [fundingItems, setFundingItems] = useState([]);
  const [isEditing, setIsEditing, isEditingRef] = useState(false);
  const [cellModesModel, setCellModesModel] = useState({});
  const [columns, setColumns] = useState([
    {
      field: "deliveryDate",
      ...dateColumnType,
      headerName: "Delivery Date",
      flex: 0.75,
      editable: true,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      renderCell: (rowData) => {
        return (
          <>
          { rowData.row.IsEligibleForFunding &&
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              key={rowData.id}
            >
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isEditingRef.current}
                  onClick={() => openContract(rowData.row.id)}
                >
                  Contract
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="success"
                  variant="contained"
                  disabled={isEditingRef.current}
                  onClick={() => submitForFunding(rowData.row.deliveryDate, rowData.row.id)}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="error"
                  variant="contained"
                  disabled={isEditingRef.current}
                  onClick={() => {
                    setCurrentContract(rowData.row.id);
                    setShowCancelFunding(true);
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          }
          </>
        );
      },
      flex: 2,
    },
    {
      headerName: 'Contract',
      field: 'contract',
      flex: 0.5,
      type: 'string',
      editable: false
    },
    {
      headerName: 'Contract Start Date',
      field: 'Startdate',
      flex: 0.75,
      type: 'string',
      editable: false
    },
    {
      headerName: 'Name',
      field: 'name',
      flex: 1,
      type: 'string',
      editable: false
    },
    {
      headerName: 'PO Number',
      field: 'PONumber',
      flex: 0.75,
      type: 'number',
      editable: false
    },
    {
      headerName: 'Cash Price',
      field: 'InvoiceAmt',
      flex: 0.5,
      type: 'number',
      valueFormatter: ({ value }) => currencyFormatter.format(value),
      editable: false,
      cellClassName: 'font-tabular-nums',
    },
    {
      headerName: 'Eligible',
      field: 'IsEligibleForFunding',
      flex: 0.5,
      type: 'boolean',
      renderCell: (rowData) => {
        return (
          <Chip
            color={rowData.value ? 'success' : 'error'}
            variant="filled"
            label={rowData.value ? 'Yes' : 'No'}
          />
        )
      },
      editable: false
    },
    {
      headerName: 'Dealer',
      field: 'dealer',
      type: 'string',
      flex: 0.75,
      editable: false
    },
  ]);

  const openContract = (contract) => {
    let contractUrl = `${window.apiUrl}/awsstore/request/${AU}/${AA}/current-contract/${contract}`;
    window.open(contractUrl, '_blank');
  }

  const getFunding = () => {
    sendData('fundingDashboard', {
      genUserId: localStorage.getItem('genUserId')
    });
  }

  const onFunding = (data) => {
    data.forEach(item => {
      if (!item.name) {
        item.name = `${item.Firstname} ${item.lastName}`;
      }
    });

    setFundingItems(data);
    setLoading(false);
  }

  const submitForFunding = (deliveryDate, contract) => {
    let isValidFundingDate = validateFundingDate(deliveryDate);

    if (isValidFundingDate) {
      let formattedDate = formatDateMMDDYYYY(deliveryDate);

      sendData('submitFunding', {
        genUserId: localStorage.getItem('genUserId'),
        contract: contract,
        date: formattedDate
      });
    }
  }

  const onSubmitFunding = (data) => {
    if (data?.success) {
      setLoading(true);

      getFunding();
    }
  }

  const cancelFunding = (contract, agent, reason) => {

    setShowCancelFunding(false);
    setLoading(true);
    sendData('cancelFunding', {
      genUserId: localStorage.getItem('genUserId'),
      contract: contract,
      agent: agent,
      reason: reason
    });
  }

  const onCancelFunding = (data) => {
    if (data.success) {
      setLoading(true);

      getFunding();
    }
  }

  const updateDeliveryDate = useUpdateDeliveryDate();

  const processRowUpdate = useCallback(
    async (newRow) => {
      const response = await updateDeliveryDate(newRow);

      setIsEditing(false);

      return response;
    },
    [updateDeliveryDate],
  );

  const handleCellClick = useCallback((params) => {
    if (params.isEditable) {
      setIsEditing(true);
      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View }
                }),
                {}
              )
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit }
          }
        };
      });
    }
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  useEffect(() => {


    getFunding();

    onData('fundingDashboard', onFunding);
    onData('submitFunding', onSubmitFunding);
    onData('cancelFunding', onCancelFunding);

    return () => {
      offData('fundingDashboard');
      offData('submitFunding');
      offData('cancelFunding');
    }
  }, []);

  return (
    <>
      { showCancelFunding &&
        <CancelFunding
          contract={currentContract}
          open={showCancelFunding}
          onClose={() => setShowCancelFunding(false)}
          cancelFunding={cancelFunding}
        />
      }

      <Card
        raised
      >
        <CardHeader
          title="Submit For Funding"
          sx={{
            backgroundColor: '#1e4670',
            color: 'white',
          }}
        />
        <CardContent
          sx={{
            height: 'calc(100vh - 200px)',
            '& .MuiDataGrid-cell--editable': {
              bgcolor: (theme) => '#bbbbbb',
              border: (theme) => '2px solid #1e4670'
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <DataGrid
              disableSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              initialState={{
                sorting: {
                  sortModel: [{ field: 'contract', sort: 'desc' }],
                },
              }}
              isCellEditable={(rowData) => rowData.row.IsEligibleForFunding}
              rows={fundingItems}
              columns={columns}
              experimentalFeatures={{ newEditingApi: true }}
              loading={loading}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={(params) => console.error(params)}
              onCellClick={handleCellClick}
              onCellModesModelChange={handleCellModesModelChange}
              cellModesModel={cellModesModel}
              onCellEditStart={(rowData) => {
                setIsEditing(true);
              }}
              onCellEditStop={(rowData) => {
                setIsEditing(false);
              }}
              components={{ Toolbar: GridToolbar, LoadingOverlay: LinearProgress }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
            />
          </LocalizationProvider>
        </CardContent>
      </Card>
    </>
  );
}

export default Funding;
