import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  today,
  future30Days,
  future45Days,
  future50Days,
  within45Days,
  within30To50Days
} from '../../../Util/DateTime';
import { withinFinanceRange } from '../../../Util/Finance';
import { offData, sendData, onData } from '../../../Util/Socket';

const TermsStep = (props) => {
  const [showTaxError, setShowTaxError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showExceptionAlert, setShowExceptionAlert] = useState(false);
  const [exceptionAlertMessage, setExceptionAlertMessage] = useState('');
  const [showIncreaseAlert, setShowIncreaseAlert] = useState(props?.customer?.merchandise?.invoice?.initialPaymentIncrease);
  const [showCashPriceAlert, setShowCashPriceAlert, showCashPriceAlertRef] = useState(props?.customer?.merchandise?.invoice?.cashPriceError);
  const [showFinanceRangeAlert, setShowFinanceRangeAlert] = useState(false);
  const [customer, setCustomer, customerRef] = useState(props?.customer);
  const [termData, setTermData, termDataRef] = useState({
    initialPayment: parseFloat(props?.customer?.merchandise?.invoice?.initialPayment),
    paymentStartDate: new Date(props?.customer?.merchandise?.invoice?.paymentStartDate || new Date()),
    term: props?.customer?.merchandise?.invoice?.term?.toString() || '2',
  });
  const [frequency, setFrequency, frequencyRef] = useState({value: '1', text: 'Monthly'})
  const [frequencyInput, setFrequencyInput, frequencyInputRef] = useState('1');
  const [initialPayments, setInitialPayments] = useState(null)
  const [termErrors, setTermErrors, termErrorsRef] = useState({});
  const [term12, setTerm12] = useState("");
  const [term18, setTerm18] = useState("");
  const [term24, setTerm24] = useState("");
  const [term3M, setTerm3M] = useState("");
  const [rent90, setRent90, rent90Ref] = useState(null);
  const [rent12, setRent12, rent12Ref] = useState(null);
  const [rent18, setRent18, rent18Ref] = useState(null);
  const [rent24, setRent24, rent24Ref] = useState(null);
  const [hasPayments, setHasPayments, hasPaymentsRef] = useState(false);

  const contractInvoiceFinal = () => {
    let newTermErrors = validateTerms();

    setTermErrors({
      ...termErrors,
      ...newTermErrors
    });

    if (Object.keys(newTermErrors).length === 0) {
      setLoading(true);

      if (termDataRef.current.initialPayment || parseInt(termDataRef.current.initialPayment) <= 0) {

        let formattedPaymentStartDate = termDataRef.current.paymentStartDate.toISOString().substring(0, 10);


        sendData('contractInvoiceFinal', {
          genUserId: localStorage.getItem('genUserId'),
          rent90: rent90Ref.current,
          rent12: rent12Ref.current,
          rent18: rent18Ref.current,
          rent24: rent24Ref.current,
          contract: customerRef.current.contract,
          frequency: parseInt(frequencyRef.current?.value),
          option: termDataRef.current.term,
          startDate: formattedPaymentStartDate,
          cashPrice: customerRef.current.cashPrice,
          initialPayment: termDataRef.current.initialPayment
        });
      }
    }
  }

  const onContractInvoiceFinal = (data) => {
    if (data?.error) {
      setLoading(false);

      if (data.error === 'warranty') {
        setExceptionAlertMessage(
          `It appears you may have added a warranty or protection plan which is not an allowable item
          for leasing in the state of ${data.state}. Click Next once you confirm you have not
          included a warranty or protection plan.`
        );
        setShowExceptionAlert(true);
      } else if (data.error === 'delivery') {
        setExceptionAlertMessage(
          `It appears you may have added a delivery fee which is not an allowable item for leasing
          in the state of ${data.state}. The customer will need to pick up or pay for delivery
          separately. Click Next once you confirm you have not included a delivery fee.`
        );
        setShowExceptionAlert(true);
      } else {
        setExceptionAlertMessage(`Unable to process your request at this time. If the issue persists please contact support.`);
      }
    } else if (data?.success) {
      setShowExceptionAlert(false);
      setExceptionAlertMessage('');
      setLoading(false);

      let updatedInvoice = {
        ...customerRef.current.merchandise.invoice,
        rent90: rent90Ref.current,
        amountDue: data.amountDue,
        initialPayment: termDataRef.current.initialPayment,
        paymentStartDate: termDataRef.current.paymentStartDate,
        paymentFrequency: parseInt(frequencyRef.current.value),
        term: termDataRef.current.term,
      }

      let updatedMerchandise = {
        ...customerRef.current.merchandise,
        invoice: updatedInvoice
      }



      props.setCustomer({
        ...customerRef.current,
        hasBankInfo: data.hasBankInfo,
        hasPaid: data.hasPaid,
        merchandise: updatedMerchandise
      });

      props.setActiveStep(props.activeStep + 1);
    }
  }

  const updateTermData = (field, value) => {
    setTermData({
      ...termDataRef.current,
      [field]: value
    });

    if (!!termErrors[field]) {
      setTermErrors({
        ...termErrorsRef.current,
        [field]: null
      });
    }
  }

  const onTaxEstimate = ({ success }) => {
    console.log(success);
    if (success) {
      setShowTaxError(false);
    } else {
      setShowTaxError(true);
    }
  }

  const getTerms = () => {
    if (frequencyRef.current?.value) {
      sendData('getTerms', {
        genUserId: localStorage.getItem('genUserId'),
        source: localStorage.getItem('dealerName'),
        zip: props?.customer?.Zip,
        cashPrice: props?.customer?.cashPrice,
        initialPayment: parseFloat(props?.customer?.merchandise?.invoice?.initialPayment),
        paymentFrequency: parseInt(frequencyInputRef.current),
        contract: props?.customer?.contract
      });
    } else {
      setLoading(false);
      setTermErrors({
        ...termErrorsRef.current,
        frequency: 'Please select a payment frequency'
      });
    }
  }

  const onGetTerms = (data) => {
    let term12;
    if (data?.[0]?.[0]?.Term12) {
      term12 = data[0][0].Term12;
    }

    let term18;
    if (data?.[1]?.[0]?.Term18) {
      term18 = data[1][0].Term18;
    }

    let term24;
    if (data?.[2]?.[0]?.Term24) {
      term24 = data[2][0].Term24;
    }

    let term3M;
    if (data?.[7]?.[0]?.Term3M) {
      term3M = data[7][0].Term3M;
    }

    setTerm12(term12);
    setTerm18(term18);
    setTerm24(term24);
    setTerm3M(term3M);

    let rent90 = '0.00';
    if (data?.[6]?.[0]?.rent90) {
      rent90 = parseFloat(data[6][0].rent90).toFixed(2);
    }

    let rent12 = '0.00';
    if (data?.[3]?.[0]?.rent) {
      rent12 = parseFloat(data[3][0].rent).toFixed(2);
    }

    let rent18 = '0.00';
    if (data?.[4]?.[0]?.rent) {
      rent18 = parseFloat(data[4][0].rent).toFixed(2);
    }

    let rent24 = '0.00';
    if (data?.[5]?.[0]?.rent) {
      rent24 = parseFloat(data[5][0].rent).toFixed(2);
    }

    setRent90(rent90);
    setRent12(rent12);
    setRent18(rent18);
    setRent24(rent24);

    if (data?.[6]?.[0]?.AmountApproved) {
      cashPriceCheck();
    }

    if (hasPaymentsRef.current) {
      setHasPayments(true);
    } else {
      setHasPayments(false);
    }

    if (data?.[8]?.[0]) {
      if (showCashPriceAlertRef.current) {
        updateTermData('initialPayment', 0.00);
      } else {
        let payments = {
          '2': parseFloat(data[8][0].rent12initial).toFixed(2),
          '3': parseFloat(data[8][0].rent18initial).toFixed(2),
          '6': parseFloat(data[8][0].rent24initial).toFixed(2)
        }
        setInitialPayments(payments);

        if (!parseInt(termDataRef.current.term)) {

          updateTermData('term', "2");
        }

        updateTermData('initialPayment', payments[termDataRef.current.term]);
      }
    }

    setLoading(false);
  }

  const cashPriceCheck = () => {
    //The minimum threshold is 1.1 times the cash price
    //The max threshold is 1.3 times the cash price
    let formattedCashPrice = parseFloat(props?.customer?.cashPrice).toFixed(2);
    let approvedAmount = parseFloat(props?.customer?.amountApproved);
    let minThreshold =  parseFloat(approvedAmount * 1.1);
    let maxThreshold = parseFloat(approvedAmount * 1.3);

    if (formattedCashPrice > maxThreshold) {
      setShowCashPriceAlert(true);
      setShowIncreaseAlert(false);
    } else if (formattedCashPrice > minThreshold) {
      setShowIncreaseAlert(true);
      setShowCashPriceAlert(false);
    } else {
      setShowIncreaseAlert(false);
      setShowCashPriceAlert(false);
    }
  }

  const validateTerms = () => {
    let errors = {};

    if (termDataRef.current.term.length === 0) {
      errors.termSelected = "Term is required.";
    }

    let freq = parseInt(frequencyRef.current.value);
    if (freq !== 1 && freq !== 2) {
      errors.frequency = "Frequency is required.";
    }

    let startDate = new Date(termDataRef.current.paymentStartDate);

    if (startDate < new Date().setHours(0, 0, 0, 0)) {
      errors.paymentStartDate = "Payment start date cannot be in the past.";
    } else if (customerRef.current.State === 'AR' || customerRef.current.state === 'AR') {
      if (!within30To50Days(startDate)) {
        errors.paymentStartDate = "Payment start date must be within 30 to 50 days from today.";
      }
    } else {
      if (!within45Days(startDate)) {
        errors.paymentStartDate = "Payment start date must be within 45 days of today.";
      }
    }

    return errors;
  }

  useEffect(() => {
    console.log(props);
    if (customer?.merchandise?.invoice?.paymentFrequency == 1) {
      setFrequency({
        value: '1',
        text: 'Monthly',
      });
      setFrequencyInput('1');
    } else {
      setFrequency({
        value: '2',
        text: 'Bi-Weekly',
      });
      setFrequencyInput('2');
    }

    if (props?.customer?.merchandise?.invoice?.paymentFrequency) {
      setFrequencyInput(`${props.customer.merchandise.invoice.paymentFrequency}`);
    }

    sendData('taxEstimate', {
      zipCode: props?.customer?.Zip,
    });

    getTerms();

    onData('getTerms', onGetTerms);
    onData('contractInvoiceFinal', onContractInvoiceFinal);
    onData('taxEstimate', onTaxEstimate);

    return () => {
      offData('getTerms');
      offData('contractInvoiceFinal');
    }
  }, []);

  return (
    <>
      <Card
        raised
        sx={{ marginBottom:  '1rem' }}
      >
        <CardHeader
          sx={{backgroundColor: '#1e4670', color: 'white'}}
          title="Terms"
        />
        <CardContent>
          <Stack sx={{ width: "100%", paddingTop: 1, paddingBottom: 1 }} spacing={2}>
            { !withinFinanceRange(props?.customer?.cashPrice || 0) &&
              <Alert variant="filled" severity="error">
                Cash price must be greater than $300
              </Alert>
            }
            { showExceptionAlert &&
              <Alert variant="filled" severity="error">
                <strong>Warning: </strong> { exceptionAlertMessage }
              </Alert>
            }
            { showFinanceRangeAlert &&
              <Alert variant="filled" severity="error">
                Total cost must be less than <strong>$5,000.00</strong> and
                must be greater than <strong>$300.00</strong>
              </Alert>
            }
            { showIncreaseAlert &&
              <Alert variant="filled" severity="warning">
                The initial payment will increase because you have went 110% above the approved amount.
              </Alert>
            }
            { showCashPriceAlert &&
              <Alert variant="filled" severity="error">
                Contract total cannot exceed 130% of approved amount.
              </Alert>
            }
            { showTaxError &&
              <Alert variant="filled" severity="error">
                It looks like there is no tax information for the provided zip code. Please ensure you have entered
                the correct zip code. If the zipcode is correct, please contact customer service at (877) 700-3040.
              </Alert>
            }
          </Stack>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={3}>
              <TextField
                type="number"
                label="Cash Price"
                fullWidth
                variant="standard"
                value={props?.customer?.cashPrice || 0.00}
                onBlur={(e) => cashPriceCheck()}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="number"
                label="Initial Payment"
                fullWidth
                variant="standard"
                placeholder="Initial Payment"
                value={termData?.initialPayment || 0.00}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                value={frequency}
                autoHighlight
                autoSelect
                onChange={
                  (e, newFrequency) => {
                    setFrequency(newFrequency);
                  }
                }
                onBlur={
                  (e) => {
                    setLoading(true);
                    setTermErrors({ ...termErrors, frequency: null });
                    getTerms();
                  }
                }
                inputValue={frequencyInput}
                onInputChange={(e, newFrequency) => {
                  setFrequencyInput(newFrequency)
                }}
                id="frequency"
                options={[ {value: "1", text: "Monthly"}, {value: "2", text: "Bi-Weekly"} ]}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                getOptionLabel={(option) => {
                  return option.text ?? "";
                }}
                renderInput={(params) =>
                  <TextField
                    label="Frequency"
                    variant="standard"
                    fullWidth
                    error={termErrors?.frequency ? true : false}
                    helperText={termErrors?.frequency ? termErrors.frequency : ''}
                    {...params}
                  />
                }
              />
            </Grid>
            <Grid item xs={3}>
              { customerRef.current.state === 'AR' || customerRef.current.State === 'AR' ?
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Payment Starts On"
                    value={termData.paymentStartDate}
                    minDate={future30Days()}
                    maxDate={future50Days()}
                    onChange={(date) => updateTermData('paymentStartDate', date)}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        variant="standard"
                        fullWidth
                        error={termErrors?.paymentStartDate ? true : false}
                        helperText={termErrors?.paymentStartDate ? termErrors.paymentStartDate : ''}
                      />
                    }
                  />
                </LocalizationProvider>
                :
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Payment Starts On"
                    value={termData.paymentStartDate}
                    onChange={(date) => updateTermData('paymentStartDate', date)}
                    minDate={today()}
                    maxDate={future45Days()}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        variant="standard"
                        fullWidth
                        error={termErrors?.paymentStartDate ? true : false}
                        helperText={termErrors?.paymentStartDate ? termErrors.paymentStartDate : ''}
                      />
                    }
                  />
                </LocalizationProvider>
              }
            </Grid>
            { loading ?
            <Grid item xs={12}>
              <LinearProgress color="primary"/>
            </Grid>
            :
            <>
            <Grid item>
              { term12?.length > 0 || term18?.length > 0 || term24?.length > 0 ?
                <Typography
                  variant="p"
                  component="p"
                >
                  {term3M}
                </Typography>
                :
                null
              }
            </Grid>
            <Grid item>
              <FormControl>
                <RadioGroup
                  name="terms"
                  value={termData.term}
                  onChange={(e) => {
                    let initialPayment = initialPayments[e.target.value];

                    setTermData(oldData => ({
                      ...oldData,
                      term: e.target.value,
                      initialPayment: initialPayment
                    }))
                  }}
                >
                { term12?.length > 0 &&
                  <FormControlLabel
                    label={term12}
                    value="2"
                    control={<Radio />}
                  />
                }
                { term18?.length > 0 &&
                  <FormControlLabel
                    label={term18}
                    value="3"
                    control={<Radio />}
                  />
                }
                { term24?.length > 0 &&
                  <FormControlLabel
                    label={term24}
                    value="6"
                    control={<Radio />}
                  />
                }
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                component="p"
              >
                *Merchandise is New Unless Otherwise Stated.
              </Typography>
            </Grid>
            </>
            }
          </Grid>
        </CardContent>
      </Card>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          { localStorage.getItem('storisDealer') === 'false' &&
          <Button
            variant="contained"
            color="error"
            startIcon={<ArrowBackIosNewIcon />}
            onClick={() => {
              props.setActiveStep(props.activeStep - 1);
            }}
          >Back</Button>
          }
        </Grid>
        <Grid item>
          { !showCashPriceAlert && !showTaxError &&
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIosIcon />}
              disabled={!withinFinanceRange(props?.customer?.cashPrice || 0)}
              onClick={() => {
                contractInvoiceFinal();
              }}
            >Next</Button>
          }
        </Grid>
      </Grid>
    </>
  );
}

export default TermsStep;
