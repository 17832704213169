import React, { useEffect, forwardRef } from 'react';
import useState from 'react-usestateref';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PaymentEstimator from '../Fragments/PaymentEstimator';
import CustomerVideo from '../Fragments/CustomerVideo';
import { sendData, onData, offData } from '../../Util/Socket';
import { currencyFormatter } from '../../Util/Finance';

const AA = process.env.REACT_APP_AA;
const AU = process.env.REACT_APP_AU;

const Customers = () => {
  const [loading, setLoading] = useState(true);
  const [showPaymentEstimator, setShowPaymentEstimator] = useState(false);
  const [showCustomerVideo, setShowCustomerVideo] = useState(false);
  const [columns, setColumns] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState({});
  const [pageSize, setPageSize] = useState(10);

  const navigate = useNavigate();

  const onCalculatorData = ({ zip }) => {
    if (zip) {
      setCurrentCustomer(prevCustomerData => {
        return {
          ...prevCustomerData,
          zip: zip,
        }
      });
    }

    setShowPaymentEstimator(true);
  }

  const openContract = (contract) => {
    let contractUrl = `${window.apiUrl}/awsstore/request/${AU}/${AA}/current-contract/${contract}`;
    window.open(contractUrl, '_blank');
  }

  const getStatusColor = (status) => {
    if (status) {
      status = status.toString().toLowerCase();
    } else {
      status = '';
    }

    switch(status) {
      case 'prequalified':
        return 'info';
      case 'qualified':
        return 'success';
      case 'denied':
        return 'error';
      default:
        return 'warning';
    }
  }

  const createColumns = () => {
    let columns = [
      {
        headerName: "Contract",
        field: "contract",
        flex: 0.5,
        type: "string",
        editable: false
      },
      {
        headerName: "Name",
        field: "name",
        flex: 1,
        type: "string",
        editable: false
      }
    ];

    //If Storis Dealer add Storis column to table
    if (localStorage.getItem('storisDealer') === 'true') {
      columns.push({
        headerName: "Storis Account",
        field: "storisId",
        flex: 0.5,
        type: "number",
        editable: false
      });
    }

    columns.push(
      {
        headerName: "Actions",
        field: "actions",
        type: "actions",
        flex: 1,
        getActions: (rowData) => {
          let actions = [];

          if (rowData.row.actions.indexOf('edit') > -1) {
            actions.push(
            <GridActionsCellItem
              icon={<Tooltip title="Edit Customer"><EditIcon /></Tooltip>}
              label="Edit"
              onClick={() =>
                navigate('/dashboard/customer', { state: {
                    contract: rowData.row.contract
                  }
                })
              }
            />
            );
          }

          actions.push(
            <GridActionsCellItem
              icon={<Tooltip title="Play Video"><PlayCircleIcon /></Tooltip>}
              label="Video"
              onClick={() => setShowCustomerVideo(true)}
            />
          );

          if (rowData.row.actions.indexOf('estimator') > -1) {
            actions.push(
            <GridActionsCellItem
              icon={<Tooltip title="Payment Estimator"><AttachMoneyIcon /></Tooltip>}
              label="Estimator"
              onClick={() => {
                sendData('calculatorData', {
                  genUserId: localStorage.getItem('genUserId'),
                  contract: rowData.row.contract
                });

                setCurrentCustomer(rowData.row);
              }}
            />
            );
          }

          if (rowData.row.actions.indexOf('contract') > -1) {
            actions.push(
              <GridActionsCellItem
                icon={<Tooltip title="View Contract"><HistoryEduIcon /></Tooltip>}
                label="Contract"
                onClick={() => {
                  openContract(rowData.row.contract);
                }}
              />
            );
          }

          return actions;
        }
      },
      {
        headerName: "Application Date",
        field: "appDate",
        flex: 0.5,
        type: "string",
        editable: false
      },
      {
        headerName:"Status",
        field: "status",
        flex: 0.75,
        type: "string",
        renderCell: (rowData) => {
          return (
            <Chip
              color={getStatusColor(rowData.value)}
              variant="filled"
              label={rowData?.value?.toUpperCase() ?? "N/A"}
            />
          )
        },
        editable: false
      },
      {
        headerName: "Amount Approved",
        field: "amountApproved",
        flex: 0.5,
        type: "number",
        valueFormatter: ({ value }) => currencyFormatter.format(value),
        editable: false,
        cellClassName: 'font-tabular-nums',
      },
      {
        headerName: "Cleared for Delivery?",
        field: "cleared",
        flex: 0.75,
        type: "boolean",
        renderCell: (rowData) => {
          return (
            <Chip
              icon={rowData.value ? <CheckIcon /> : <CloseIcon />}
              color={rowData.value ? 'success' : 'error'}
              variant="filled"
              label={rowData.value ? 'Yes Contract' : 'No Contract'}
            />
          )
        },
        editable: false
      },
      {
        headerName: "Expiration Date",
        field: "expirationDate",
        flex: 0.5,
        type: "string",
        valueFormatter: ({ value }) => {
          if (value) {
            return new Date(value).toLocaleDateString();
          } else {
            return 'N/A';
          }
        },
        editable: false
      },
      {
        headerName: "SSN",
        field: "ssn",
        type: "number",
        hideable: false,
        editable: false
      }
    );

    setColumns(columns);
  }

  const onCustomerDashboard = (data) => {
    let customers = [];


    for (let i = 0; i < data.length; i++) {
      const firstName = data[i].Firstname;
      const lastName = data[i].lastName;

      let actions = [];

      if (canEditContract(data[i])) {
        actions.push('edit');
      }

      actions.push('video');

      if (parseInt(data[i].docusign) !== 1 && parseInt(data[i].amountapproved) > 0) {
        actions.push('estimator');
      }

      if (parseInt(data[i].docusign)) {
        actions.push('contract');
      }

      customers.push({
        id: i,
        contract: data[i].contract,
        firstName: firstName,
        lastName: lastName,
        name: `${firstName} ${lastName}`,
        amountApproved: data[i].amountapproved,
        appDate: data[i].rdate,
        dlKey: data[i].DLKey,
        status: data[i].status,
        docusign: data[i].docusign,
        cleared: data[i].cleared,
        ssn: data[i].ssnLast4,
        storisId: data[i].AccountID,
        hasIdentification: data[i].hasIdentification,
        zip: data[i].zip,
        expirationDate: data[i].expirationDate,
        actions: actions,
      });
    }

    setCustomers(customers);
    setLoading(false);
  }

  const canEditContract = (customer) => {
    let isDSGOnline =
      (localStorage.getItem('genUserId') &&
      parseInt(localStorage.getItem('userRoleId')) === -6) ?
      true : false;

    return (
			(customer?.status?.toLowerCase() === 'prequalified' ||
      customer?.status?.toLowerCase() === 'approved')
      &&
			(!isDSGOnline ||
      (isDSGOnline && parseInt(customer?.hasIdentification) === 1))
    );
  }

  useEffect(() => {
    createColumns();

    sendData('customerDashboard', { genUserId: localStorage.getItem('genUserId') });

    onData('calculatorData', onCalculatorData);
    onData('customerDashboard', onCustomerDashboard);

    return () => {
      offData('calculatorData');
      offData('customerDashboard');
    }
  }, []);

  return (
    <>
      { showPaymentEstimator ?
        <PaymentEstimator
          customer={currentCustomer}
          showCustomerAlert={false}
          show={showPaymentEstimator}
          close={() => setShowPaymentEstimator(false)}
        />
        :
        null
      }

      { showCustomerVideo ?
        <CustomerVideo
          show={showCustomerVideo}
          close={() => setShowCustomerVideo(false)}
        />
        :
        null
      }

      <Card
        raised
        sx={{
          height: '100%',
        }}
      >
        <CardHeader
          title="Customers"
          sx={{
            backgroundColor: '#1e4670',
            color: 'white',
          }}
        />
        <CardContent sx={{ height: '100%' }}>
          <div style={{ display: 'flex', height: '90%' }}>
            <DataGrid
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableSelectionOnClick
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 25, 50, 100]}
              columns={columns}
              rows={customers}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    ssn: false,
                  },
                },
              }}
              components={{ Toolbar: GridToolbar, LoadingOverlay: LinearProgress }}
              loading={loading}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export default Customers;
