import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const PDFDownload = (props) => {

  useEffect(() => {

  }, [props]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle
        sx={{
          marginBottom: '1rem',
          backgroundColor: '#d32f2f', //error
          color: 'white'
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            PDF Download
          </Grid>
          <Grid item>
            <IconButton
              onClick={props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <iframe
          src={props.src}
          height="700px"
          width="100%"
        />
      </DialogContent>
    </Dialog>
  );
}

export default PDFDownload;
