import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import * as FlexmonsterReact from 'react-flexmonster';
import { sendData, onData, offData } from '../../Util/Socket';

const Accountability = () => {
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState({});

  const onAccountability = (data) => {

    setReport({
      dataSource: {
        data: data.reportReturn
      },
      slice: {
        columns: [{ uniqueName: '[Measures]' }],
        rows: [
          { uniqueName: 'DealerGroup' },
          { uniqueName: 'dealer' },
          { uniqueName: 'salesperson' }
        ],
        expands: {
          rows: [{ tuple: [`dealer.[${localStorage.getItem('dealerGroup')}`] }]
        },
        measures: [
          {
            aggregation: 'distinctcount',
            availableAggregations: ['count', 'distinctcount'],
            caption: 'Apps (Last 30 Days)',
            grandTotalCaption: 'Total Apps (Last 30 Days)',
            uniqueName: 'appLast30Days'
          },
          {
            aggregation: 'distinctcount',
            caption: 'Signed Contracts (Last 30 Days)',
            grandTotalCaption: 'Total Signed Contracts (Last 30 Days)',
            uniqueName: 'signedContractLast30Days'
          }
        ],
      },
			conditions: [
				{
					formula: '#value < 1',
					measure: 'signedContractLast30Days',
					format: {
						backgroundColor: '#F44336',
						color: '#000000',
						fontFamily: 'Arial',
						fontSize: '12px'
					}
				}
			],
    });

    setLoading(false);
  }

  useEffect(() => {
    sendData('accountability', { genUserId: localStorage.getItem('genUserId') });

    onData('accountability', onAccountability);

    return () => {
      offData('accountability');
    }
  }, []);

  return (
    <>
      <Card
        raised
      >
        <CardHeader
          sx={{backgroundColor: '#1e4670', color: 'white'}}
          title="Accountability"
        />
        <CardContent>
          { !loading ?
            <FlexmonsterReact.Pivot
              toolbar={false}
              width="100%"
              report={report}
            />
            :
            <LinearProgress color="primary" />
          }
        </CardContent>
      </Card>
    </>
  );
}

export default Accountability;
