const createCSV = (headers, data) => {
  const headerArray = headers.map(header => header.headerName);
  const fieldsArray = headers.map(header => header.field);

  let csv = `${headerArray.join(',')}\n`;

  data.forEach((item) => {
    let newLine = '';

    fieldsArray.forEach((field) => {
      let newItem = '';
      if (!item[field] && !isNaN(item[field])) {
        newItem = 0;
      } else if (item[field]) {
        newItem = item[field];
      }

      newLine = `${newLine}${newItem},`;
    });

    csv = `${csv}${newLine}\n`;
  });

  return csv;
}

const downloadCSV = (data, fileName) => {
  const a = document.createElement('a');

  a.setAttribute(
    'href',
    `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`
  );
  a.setAttribute('download', `${fileName}.csv`);
  a.style.display = 'none';
  document.body.appendChild(a);

  a.click();

  document.body.removeChild(a);
}

module.exports = {
  createCSV,
  downloadCSV,
}
