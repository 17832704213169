import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ContractComplete from '../Fragments/Contract/ContractComplete';
import ContractCancel from '../Fragments/Contract/ContractCancel';
import ContractDecline from '../Fragments/Contract/ContractDecline';
import ContractException from '../Fragments/Contract/ContractException';
import ContractTimeout from '../Fragments/Contract/ContractTimeout';
import { Navigate } from 'react-router-dom';

const renderEvent = (event, AA, AU, contract) => {
  switch (event) {
    case 'complete':
      return <ContractComplete AA={AA} AU={AU} contract={contract} />;
    case 'declined':
      return <ContractDecline />;
    case 'cancel':
      return <ContractCancel />;
    case 'exception':
      return <ContractException />;
    case 'timeout':
      return <ContractTimeout />;
    case 'error':
      return <Navigate to="/error" state={{ errorCode: `C-${contract}`, errorMessage: 'An error occurred.' }} />;
    default:
      return null;
  }
}

const SigningEvent = () => {
  const {
    event,
    AA,
    AU,
    contract
  } = useParams();

  useEffect(() => {

  }, []);

  return (
    <>
      {renderEvent(event, AA, AU, contract)}
    </>
  );
}

export default SigningEvent;
