import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChatIcon from '@mui/icons-material/Chat';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PeopleIcon from '@mui/icons-material/People';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BuildIcon from '@mui/icons-material/Build';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import PaymentEstimator from '../Components/Fragments/PaymentEstimator';
import Chat from '../Components/Fragments/Chat';
import { useNavigate } from 'react-router-dom';
import { onData, offData } from '../Util/Socket';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://smartsalesandlease.com/">
        Smart Sales and Lease
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: 999,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      zIndex: 998,
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

function AdminDashboardContent() {
  const [open, setOpen] = useState(true);
  const [showEstimator, setShowEstimator] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showUpdateSnackbar, setShowUpdateSnackbar] = useState(false);

  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const toggleEstimator = () => {
    setShowEstimator(!showEstimator);
  };

  const toggleChat = () => {
    setShowChat(!showChat);
  };

  const toggleUpdateSnackbar = () => {
    setShowUpdateSnackbar(!showUpdateSnackbar);
  };

  const onNewUpdate = (data) => {
    setShowUpdateSnackbar(true);

    setInterval(() => {
      alert('New Update Available. Please refresh your page.');
    }, 300000);
  }

  const onErrorHandler = ({ msg, code }) => {

    if (msg.includes('Invalid GenUserID')) {
      navigate('/login', { state: { error: true, message: 'Invalid Credentials. Please log back in.' } });
    } else {
      navigate('/error', { state: { errorCode: code, errorMessage: msg } });
    }
  };

  useEffect(() => {
    onData('errorHandler', onErrorHandler);
    onData('newUpdate', onNewUpdate);

    return () => {
      offData('errorHandler');
      offData('newUpdate');
    }
  }, []);

  return (
    <>
      { showUpdateSnackbar &&
        <Snackbar
          open={showUpdateSnackbar}
          autoHideDuration={30000}
          onClose={toggleUpdateSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            variant="filled"
            severity="info"
            sx={{ width: '100%' }}
            action={
              <ButtonGroup variant="standard">
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Update
                </Button>
                <Button color="inherit" size="small" onClick={toggleUpdateSnackbar}>
                  Dismiss
                </Button>
              </ButtonGroup>
            }
          >
            There is a new update available. Please refresh the page to update.
          </Alert>
        </Snackbar>
      }

      { showEstimator &&
        <PaymentEstimator
          show={showEstimator}
          close={toggleEstimator}
          customer={null}
          showCustomerAlert={true}
        />
      }

      { showChat &&
        <Chat close={toggleChat} />
      }

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar color="secondary" position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="a"
              href="/dashboard"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <img
                width="162px"
                height="52px"
                src="https://cdn.smartsalesandlease.com/global-assets/logos/SMART SALES AND LEASE 1.png" alt="Smart Sales and Lease"
              />
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Button
                startIcon={<PersonAddIcon />}
                color="dark"
                onClick={() => navigate('/admin-dashboard/stores')}
              >
                New Store
              </Button>
              <Button
                startIcon={<ChatIcon />}
                color="blue"
                onClick={toggleChat}
              >
                Chat
              </Button>
              <Button
                startIcon={<AttachMoneyIcon />}
                color="success"
                onClick={toggleEstimator}
              >
                Payment Estimator
              </Button>
            </Grid>
            <Typography
              variant="subtitle2"
              component="a"
              href="/dashboard"
              sx={{
                mr: 2,
                color: 'inherit',
                textAlign: 'center',
                textDecoration: 'none',
              }}
            >
              { localStorage.getItem('dealerName') }
            </Typography>
            <IconButton onClick={() => navigate('/logout')} color="inherit">
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List
            component="nav"
            sx={{
              paddingTop: '15px',
              paddingBottom: '15px',
            }}
          >
            <ListItemButton
              onClick={() => navigate('/admin-dashboard')}
            >
              <ListItemIcon>
                <AnalyticsIcon />
              </ListItemIcon>
              <ListItemText>
                Metrics
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => navigate('/admin-dashboard/stores')}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText>
                All Stores
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => navigate('/admin-dashboard/funding-reports')}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText>
                Funding Reports
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => navigate('/admin-dashboard/info')}
            >
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText>
                Update Your Info
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => navigate('/admin-dashboard/funding')}
            >
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText>
                Submit For Funding
              </ListItemText>
            </ListItemButton>
            {/* <ListItemButton
              onClick={() => navigate('/admin-dashboard/contracts-ending')}
            >
              <ListItemIcon>
                <AvTimerIcon />
              </ListItemIcon>
              <ListItemText>
                Expiring Contracts
              </ListItemText>
            </ListItemButton> */}
            <ListItemButton
              onClick={() => navigate('/admin-dashboard/order-history')}
            >
              <ListItemIcon>
                <ImportContactsIcon />
              </ListItemIcon>
              <ListItemText>
                Order History
              </ListItemText>
            </ListItemButton>
            { localStorage.getItem('storisDealer') === 'true' &&
              <ListItemButton
                onClick={() => navigate('/admin-dashboard/accountability')}
              >
                <ListItemIcon>
                  <AssignmentTurnedInIcon />
                </ListItemIcon>
                <ListItemText>
                  Accountability
                </ListItemText>
              </ListItemButton>
            }
            <ListItemButton
              onClick={() => navigate('/admin-dashboard/expiring-contracts')}
            >
              <ListItemIcon>
                <HourglassFullIcon />
              </ListItemIcon>
              <ListItemText>
                Approvals
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => navigate('/admin-dashboard/contact-us')}
            >
              <ListItemIcon>
                <ContactSupportIcon />
              </ListItemIcon>
              <ListItemText>
                Contact Us
              </ListItemText>
            </ListItemButton>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4, minHeight: "700px", height: "50%" }}>
            <Outlet />
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default function AdminDashboard() {
  return <AdminDashboardContent />;
}
