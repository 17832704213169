import React, {
  useEffect,
} from 'react';
import useState from 'react-usestateref';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { sendData, onData, offData } from '../../Util/Socket';
import { withinFinanceRange, currencyFormatter } from '../../Util/Finance';
import { today } from '../../Util/DateTime';
import EstimatorChart from './EstimatorChart';

//This component could be refactored to improve readibilty and maintainability
//The form data should be refactored to be a single object
//Reference: https://dev.to/alecgrey/controlled-forms-with-front-and-backend-validations-using-react-bootstrap-5a2
const PaymentEstimator = (props) => {
  const [showCustomerAlert, setShowCustomerAlert] = useState(props?.showCustomerAlert);
  const [showIncreaseAlert, setShowIncreaseAlert] = useState(false);
  const [showCashPriceAlert, setShowCashPriceAlert] = useState(false);
  const [showFinanceRangeAlert, setShowFinanceRangeAlert] = useState(false);
  const [showPeriodicPayment, setShowPeriodicPayment] = useState(false);
  const [showBalloonPayment, setShowBalloonPayment] = useState(false);

  const [showEstimatorChart, setShowEstimatorChart] = useState(false);
  const [estimatorChartData, setEstimatorChartData] = useState([{x: 0, y: 0}]);
  const [estimatorChartXDomain, setEstimatorChartXDomain] = useState([0, 0]);
  const [estimatorChartYDomain, setEstimatorChartYDomain] = useState([0, 0]);
  const [estimatorChartTooltips, setEstimatorChartTooltips] = useState([]);

  const [contract, setContract] = useState(props?.customer?.contract);
  const [zip, setZip, zipRef] = useState(props?.customer?.zip || props?.customer?.Zip || "");
  const [cashPrice, setCashPrice, cashPriceRef] = useState(props?.customer?.amountApproved || 1000);
  const [frequencyInput, setFrequencyInput] = useState('');
  const [frequency, setFrequency, freqRef] = useState({ value: '', text: '' });
  const [initialPayment, setInitialPayment] = useState(0);
  const [minInitialPayment, setMinInitialPayment] = useState("");
  const [termInput, setTermInput] = useState('');
  const [term, setTerm, termRef] = useState({ value: '', text: '' });
  const [paymentPeriod, setPaymentPeriod] = useState('');
  const [periodicPayment, setPeriodicPayment] = useState("");
  const [balloonPayment, setBalloonPayment] = useState("");
  const [errors, setErrors] = useState({});
  const [estimatorPaymentTerms, setEstimatorPaymentTerms] = useState([]);

  const onZipBlur = () => {
    let formErrors = validateEstimator();
    setErrors(formErrors);

    if (!!formErrors.zip) {
      getTerms();
    }
  }

  const cashPriceUpdate = (newCashPrice) => {
    setCashPrice(newCashPrice);

    //Check if cash price is within finance range
    if (withinFinanceRange(newCashPrice)) {
      setShowFinanceRangeAlert(false);
    } else {
      setShowFinanceRangeAlert(true);
    }
  }

  const onCashPriceBlur = () => {
    cashPriceCheck(parseInt(cashPrice));

    let formErrors = validateEstimator();
    setErrors(formErrors);

    if (withinFinanceRange(parseInt(cashPrice))) {
      setShowFinanceRangeAlert(false);
      getTerms();
    }
  }

  const onFrequencyBlur = () => {
    let formErrors = validateEstimator();
    setErrors(formErrors);

    if (!formErrors.frequency) {
      getTerms();
    }
  }

  const onTermBlur = () => {
    let formErrors = validateEstimator();
    setErrors(formErrors);

    if (!formErrors.term) {
      getTerms();
    }
  }

  const getTerms = () => {
    setShowEstimatorChart(false);

    sendData('getTerms', {
      genUserId: localStorage.getItem('genUserId'),
      source: localStorage.getItem('dealerName'),
      zip: zip,
      cashPrice: cashPrice,
      initialPayment: initialPayment,
      paymentFrequency: freqRef?.current?.value,
      contract: contract
    });
  }

  const onGetTerms = (data) => {
    //This could probably use a refactor in the future
    //getTerms is used to get the terms for the payment estimator
    //but also to calculate the data for the estimator graph
    //This is a hack to check if the terms have already been set and
    //if they are then we need to create/update the estimator info and graph
    if (Object.keys(errors).length === 0) {
      if (termRef?.current?.value && freqRef?.current?.value) {
        if (data?.[6]?.[0].state && data?.[6]?.[0]?.state.toLowerCase() === 'nc') {
          if (parseInt(termRef.current.value) === 12 && data?.[3]?.[0]) {
            // 12 Month
            setBalloonPayment(parseFloat(data[3][0].balloonPayment).toFixed(2));
            setShowBalloonPayment(true);
          } else if (parseInt(termRef.current.value) === 18 && data?.[4]?.[0]) {
            // 18 Month
            setBalloonPayment(parseFloat(data[4][0].balloonPayment).toFixed(2));
            setShowBalloonPayment(true);
          } else if (parseInt(termRef.current.value) === 25 && data?.[5]?.[0]) {
            // 24 Month
            setBalloonPayment(parseFloat(data[5][0].balloonPayment).toFixed(2));
            setShowBalloonPayment(true);
          }
        }

        calculatePayments(data);
      } else {
        let paymentTerms = [];

        if (data?.[0]?.[0]?.Term12 && data?.[0]?.[0]?.Term12.trim() !== '') {
          paymentTerms.push({
            value: 12,
            text: '12 Month'
          });
        }

        if (data?.[1]?.[0]?.Term18 && data?.[1]?.[0]?.Term18.trim() !== '') {
          paymentTerms.push({
            value: 18,
            text: '18 Month'
          });
        }

        if (data?.[2]?.[0]?.Term24 && data?.[2]?.[0]?.Term24.trim() !== '') {
          paymentTerms.push({
            value: 24,
            text: '24 Month'
          });
        }

        if (data?.[6]?.[0] && data?.[6]?.[0]?.minDown) {
          let initialPayment = parseFloat(data[6][0].minDown).toFixed(2);

          setInitialPayment(initialPayment);
          setMinInitialPayment(initialPayment);
        }

        setEstimatorPaymentTerms([ ...estimatorPaymentTerms, ...paymentTerms ]);
      }
    }
  }

  const calculatePayments = (data) => {
    let table;
    if (parseInt(termRef.current?.value) === 12) {
      // 12 Month
      table = data[3];
      if (props?.customer && props?.customer?.contract) {
        setInitialPayment(parseFloat(data[8][0].rent12initial).toFixed(2));
      }
    } else if (parseInt(termRef.current?.value) === 18) {
      // 18 Month
      table = data[4];
      if (props?.customer && props?.customer?.contract) {
        setInitialPayment(parseFloat(data[8][0].rent18initial).toFixed(2));
      }
    } else if (parseInt(termRef.current?.value) === 24) {
      // 24 Month
      table = data[5];
      if (props?.customer && props?.customer?.contract) {
        setInitialPayment(parseFloat(data[8][0].rent24initial).toFixed(2));
      }
    }

    if (table && (table[0].rent || table[0].biRent)) {
      if (parseInt(freqRef.current?.value) === 1) {
        // Monthly
        setPaymentPeriod('Monthly');
        setPeriodicPayment(table[0].rent.toFixed(2));
        setShowPeriodicPayment(true);
      } else {
        // Bi-monthly
        setPaymentPeriod('Bi-Weekly');
        setPeriodicPayment(table[0].biRent.toFixed(2));
        setShowPeriodicPayment(true);
      }

      let marchYear = today().getFullYear();
      if (today().getMonth() >= 3) {
        marchYear++;
      }

      const nextMarch = new Date(marchYear, 2, 1);
      const monthOfMarch = monthDiff(today(), nextMarch);

      let tooltips = [];
      let estimateData = table.map((month, index) => {
        let newPaymentData = {
          earlyBuyoutCost: parseFloat(month.earlyBuyoutCost.toFixed(2)),
          totalCostNoTax: parseFloat(month.totalCostNoTax.toFixed(2)),
          totalCost: parseFloat(month.totalCost.toFixed(2)),
          paymentNumber: index,
          attributes: []
        };

        if (index === 3) {
          newPaymentData.attributes.push('map-marker');
        }

        if (index === monthOfMarch) {
          newPaymentData.attributes.push('bookmark');
        }

        if (index === table.length - 1) {
          newPaymentData.attributes.push('calendar');
        }

        let tooltip = createEstimatorTooltip(newPaymentData);
        tooltips.push(tooltip);

        return newPaymentData;
      });

      let yMax = Math.max(...estimateData.map((item) => item.totalCost)) * 3;
      let xMax = null;
      if (parseInt(termRef.current?.value) === 12) {
        xMax = 12;
      } else if (parseInt(termRef.current?.value) === 18) {
        xMax = 18;
      } else if (parseInt(termRef.current?.value) === 24) {
        xMax = 24;
      }

      setEstimatorChartXDomain([ 0, xMax ]);
      setEstimatorChartYDomain([ 0, yMax ]);
      setEstimatorChartData(estimateData);
      setEstimatorChartTooltips(tooltips);
      setShowEstimatorChart(true);
    } else {
      //Reset estimator error as no data was found
      setZip('');
      setCashPrice(1000);
      setInitialPayment(0);
      setMinInitialPayment(0);
      setTerm(null);
      setFrequency(null);
      setEstimatorChartData(null);
      setEstimatorChartXDomain(null);
      setEstimatorChartYDomain(null);
      setEstimatorChartTooltips(null);
      setShowEstimatorChart(false);
    }
  }

  const cashPriceCheck = (newCashPrice) => {
    //The minimum threshold is 1.1 times the cash price
    //The max threshold is 1.3 times the cash price
    //If there is no amount approved, the cash price is the amount approved
    let formattedCashPrice = parseFloat(newCashPrice).toFixed(2);
    let approvedAmount = parseFloat(props?.customer?.amountApproved || newCashPrice);
    let minThreshold =  parseFloat(approvedAmount * 1.1);
    let maxThreshold = parseFloat(approvedAmount * 1.3);

    if (formattedCashPrice > maxThreshold) {
      setShowCashPriceAlert(true);
      setShowIncreaseAlert(false);
    } else if (formattedCashPrice > minThreshold) {
      setShowIncreaseAlert(true);
      setShowCashPriceAlert(false);
    } else {
      setShowIncreaseAlert(false);
      setShowCashPriceAlert(false);
    }
  }

  const createEstimatorTooltip = (data) => {
    if (parseInt(freqRef.current?.value) === 1) {
      //Montly payment tooltips
      //The first payment is the initial payment
      //The following payments are the monthly payments
      let paymentNumber = data.paymentNumber === 0 ?
        'Initial Payment' : data.paymentNumber;

      return `
        Buyout cost after making payment(s):
        [${paymentNumber}] equals $${data.earlyBuyoutCost},
        for a Total Cost of ${data.totalCost}
      `;
    } else {
      //Bi-weekly payment tooltips
      //The first payment is the initial payment
      //The following payments are the bi-weekly payments
      let firstPayment = data.paymentNumber === 0 ?
        'InitialPayment' : (data.paymentNumber * 2) - 1;
      let secondPayment = firstPayment === 0 ?
        null : firstPayment + 1;
      let paymentNumber = data.paymentNumber === 0 ?
        `${firstPayment}` : `${firstPayment} & ${secondPayment}`;

      return `
        Buyout cost after making payment(s):
        [${paymentNumber}] equals $${data.earlyBuyoutCost},
        for a Total Cost of ${data.totalCost}
      `;
    }
  }

  const monthDiff = (date1, date2) => {
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth() + 1;
    months += date2.getMonth() + 1;

    return months <= 0 ? 0 : months;
  };

  const validateEstimator = () => {
    let newErrors = {};

    //Zip code must be filled out and must be 5 digits in length
    if (!zipRef.current || zipRef.current.length !== 5) {
      newErrors.zip = 'Please enter a valid zip.';
    }

    //Cash price must be within the finacning range
    if (!withinFinanceRange(cashPriceRef.current)) {
      newErrors.cashPrice = 'Please verify the cash price entered.';
    }

    //Frequency must be selected
    if (parseInt(freqRef?.current?.value) !== 1 && parseInt(freqRef?.current?.value) !== 2) {
      newErrors.frequency = 'Please select a payment frequency.';
    }

    //Term must be selected
    if (parseInt(termRef?.current?.value) % 6 !== 0) {
      newErrors.term = 'Please select a payment term.';
    }

    return newErrors;
  }

  useEffect(() => {
    //If the payment estimator has customer information get the payment terms
    if (props?.customer?.frequency) {
      setFrequencyInput(props.customer.frequency);
    }

    if (props?.customer?.contract) {
      getTerms();
    }

    onData('getTerms', onGetTerms);

    return () => {
      offData('getTerms');
    }
  }, [props.show, props.customer]);

  return (
    <>
      <Dialog
        maxWidth="lg"
        open={props.show}
        onClose={props.close}
        aria-labelledby="payment-estimator-modal"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#1e4670"
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h6"
              as="h5"
              sx={{
                color: "white",
              }}
            >
              Payment Estimator
            </Typography>
            <a
              target="_blank"
              href="https://cdn.smartsalesandlease.com/dealer-assets/videos/80secondtraining.mp4"
              style={{ color: "white" }}
            >
              80 Second Explanation Video
            </a>
            <IconButton color="light" onClick={props.close}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack direction="column" spacing={1} sx={{ paddingBottom: 1, width: '100%' }}>
            { showCustomerAlert ?  
               <Alert variant="filled" severity="info" sx={{ width: '100%' }}>
                For prequalified customers, please use the estimator next to their Application ID. This estimator does not factor in the customer's prequalified limit.
              </Alert>
              :
              <Alert variant="filled" severity="info" sx={{ width: '100%' }}>
                <strong>Customer: </strong>{ `${props.customer.name}` }
                &nbsp;&nbsp;&nbsp; 
                <strong>Contract: </strong>{ `${props.customer.contract}` }
                &nbsp;&nbsp;&nbsp;
                <strong>Prequalified Amount: </strong>{ `${currencyFormatter.format(props.customer.amountApproved)}` }
              </Alert>
            }
            { showCashPriceAlert &&
              <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
                Contract total cannot exceed 130% of approved amount.
              </Alert>
            }
            { showIncreaseAlert &&
              <Alert variant="filled" severity="warning" sx={{ width: '100%' }}>
                The initial payment will increase because you have went 110% above the approved amount.
              </Alert>
            }
            { showFinanceRangeAlert &&
              <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
                Total cose must be less than <strong>$5,000.00</strong> and must be greater than <strong>$300.00</strong>
              </Alert>
            }
          </Stack>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={5}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <TextField
                    error={errors?.zip ? true : false}
                    label="Zip Code"
                    maxLength={5}
                    variant="standard"
                    value={zip}
                    disabled={props?.customer?.zip ? true : false}
                    onChange={(e) => {
                      if (!isNaN(e.target.value) && e.target.value.length <= 5) {
                        setZip(e.target.value)
                      }
                    }}
                    onBlur={(e) => onZipBlur()}
                    helperText={errors?.zip}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <AttachMoneyIcon sx={{ mr: 1, my: 0.5 }} />
                    <TextField
                      fullWidth
                      error={errors?.cashPrice ? true : false}
                      label="Cash Price"
                      variant="standard"
                      disabled={zip?.length === 5 ? false : true}
                      value={cashPrice}
                      onChange={(e) => cashPriceUpdate(e.target.value)}
                      hint="Do not include sales tax in cash price"
                      onBlur={(e) => onCashPriceBlur(e.target.value)}
                      helperText={errors?.cashPrice}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                  paddingBottom: 1
                }}
              >
                <Grid item xs={12}>
                  <Autocomplete
                    disabled={zip?.length === 5 ? false : true}
                    autoHighlight
                    autoSelect
                    value={frequency}
                    onChange={
                      (e, newFrequency) => {
                        setFrequency(newFrequency);
                        setErrors({ ...errors, frequency: null });
                      }
                    }
                    onBlur={(e) => onFrequencyBlur()}
                    inputValue={frequencyInput}
                    onInputChange={(e, newValue) => setFrequencyInput(newValue)}
                    id="frequency"
                    options={[
                      { value: 1, text: 'Monthly', }, 
                      { value: 2, text: 'Bi-Weekly', },
                    ]}
                    isOptionEqualToValue={(option, value) => option.value == value.value}
                    getOptionLabel={(option) => {
                      return option.text ?? "";
                    }}
                    renderInput={(params) => 
                      <TextField
                        label="Frequency"
                        variant="standard"
                        fullWidth
                        error={errors?.frequency ? true : false}
                        helperText={errors?.frequency}
                        {...params}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                  paddingBottom: 1,
                }}
              >
                <Grid item xs={12}>
                  <TextField
                    label="Initial Payment"
                    value={initialPayment}
                    InputProps={{
                      startAdornment:
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>,
                    }}
                    fullWidth
                    disabled
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                  paddingBottom: 1,
                }}
              >
                <Grid item xs={12}>
                  <Autocomplete
                    disabled={zip?.length === 5 ? false : true}
                    autoHighlight
                    autoSelect
                    value={term}
                    onChange={
                      (e, newTerm) => {
                        setTerm(newTerm);
                        setErrors({ ...errors, term: null });
                      }
                    }
                    onBlur={(e) => onTermBlur()}
                    inputValue={termInput}
                    onInputChange={(e, newTerm) => {
                      setTermInput(newTerm);
                    }}
                    options={estimatorPaymentTerms}
                    isOptionEqualToValue={(option, value) => option.value == value.value}
                    getOptionLabel={(option) => {
                      return option.text ?? "";
                    }}
                    renderInput={(params) => 
                      <TextField
                        label="Payment Terms"
                        variant="standard"
                        fullWidth
                        error={errors?.term ? true : false}
                        helperText={errors?.term}
                        {...params}
                      />
                    }
                  />
                </Grid>
              </Grid>
              { showPeriodicPayment &&
                <Alert variant="filled" severity="success" sx={{ width: '100%' }}>
                  <strong>{ paymentPeriod }:</strong>
                  &nbsp;
                  {currencyFormatter.format(periodicPayment)}
                </Alert>
              }
              { showBalloonPayment &&
                <Alert variant="filled" severity="success" sx={{ width: '100%' }}>
                  <strong>Final Balloon Payment:</strong>
                  &nbsp;
                  {currencyFormatter.format(balloonPayment)}
                </Alert>
              }
              <Box sx={{ paddingBottom: 1, paddingTop: 1 }}>
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => getTerms()}
                >
                  Calculate!
                </Button>
              </Box>
            </Grid>
            <Grid item xs={7}>
              { showEstimatorChart &&
                <EstimatorChart
                  show={showEstimatorChart}
                  data={estimatorChartData}
                  xDomain={estimatorChartXDomain}
                  yDomain={estimatorChartYDomain}
                  tooltips={estimatorChartTooltips}
                />
              }
            </Grid>
          </Grid>
          <Alert variant="filled" severity="info" sx={{ width: '100%' }}>
            <AlertTitle>Important!</AlertTitle>
            <Stack direction="column" spacing={1}>
              <p>
              <strong>
                Note:
              </strong>
              &nbsp;
              Above are estimated numbers. Please see contract for final numbers.
              </p>
              <p>
              <strong>
                Note:
              </strong>
              &nbsp;
              All amounts are based on full and timely payment of scheduled amounts. Totals do not reflect NSF fees, which may apply when applicable. See contract for details.
              </p>
              <p>
              <strong>
                Note:
              </strong>
              &nbsp;
              Tax amounts subject to change in local sales tax rate.
              </p>
            </Stack>
          </Alert>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PaymentEstimator;
