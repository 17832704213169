const chatSocket = window.chatSocket;

const startChat = (name) => {

  chatSocket.emit('startchat', name);
}

const sendChat = (chat) => {
  chatSocket.emit('chat', chat);
}

const logChatStart = (log) => {
  chatSocket.emit('logChatStart', log);
}

const refreshChat = (sid) => {
  chatSocket.emit('refresh', { sid: sid });
}

const onChat = (proc, cb) => {
  chatSocket.on(proc, (data) => {

    cb(data);
  });
}

const offChat = (proc) => {
  chatSocket.off(proc);
}

module.exports = {
  startChat,
  onChat,
  sendChat,
  logChatStart,
  refreshChat,
  offChat,
}
