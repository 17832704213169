import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import * as FlexmonsterReact from 'react-flexmonster';
import { sendData, onData, offData } from '../../Util/Socket';

const ExpiringContracts = () => {
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState({});

  const onExpiringContracts = (data) => {
    setReport({
      dataSource: {
        data: data.reportReturn
      },
      slice: {
        columns: [{ uniqueName: '[Measures]' }],
        rows: [
          { uniqueName: 'dealergroup' },
          { uniqueName: 'dealer' },
          { uniqueName: 'salesperson' },
          { uniqueName: 'customer' },
          { uniqueName: 'custType' }
        ],
        expands: {
          rows: [{ tuple: [`dealergroup.[${localStorage.getItem('dealerGroup')}]`] }]
        },
        measures: [
          {
            aggregation: 'min',
            caption: 'Days Until Expired',
            grandTotalCaption: 'Total Days Until Expired',
            uniqueName: 'daysTillExpired'
          },
          {
            aggregation: 'sum',
            caption: 'Amount Approved',
            format: 'money',
            grandTotalCaption: 'Total Amount Approved',
            uniqueName: 'amountapproved',
          },
        ],
      },
			conditions: [
				{
					formula: '#value < 1',
					measure: 'signedContractLast30Days',
					format: {
						backgroundColor: '#F44336',
						color: '#000000',
						fontFamily: 'Arial',
						fontSize: '12px'
					}
				}
			],
    });

    setLoading(false);
  }

  useEffect(() => {


    sendData('expiringContracts', { genUserId: localStorage.getItem('genUserId') });

    onData('expiringContracts', onExpiringContracts);

    return () => {
      offData('expiringContracts');
    }
  }, []);

  return (
    <>
      <Card
        raised
      >
        <CardHeader
          sx={{backgroundColor: '#1e4670', color: 'white'}}
          title="Open Approvals"
        />
        <CardContent>
          { !loading ?
            <FlexmonsterReact.Pivot
              width="100%"
              report={report}
              toolbar={true}
              beforetoolbarcreated={(toolbar) => {
                let tabs = toolbar.getTabs();
                toolbar.getTabs = () => {
                  //Remove unwanted tabs
                  tabs = tabs.filter((tab) => {
                    return tab.id !== "fm-tab-connect" &&
                    tab.id !== "fm-tab-open" &&
                    tab.id !== "fm-tab-save";
                  });

                  return tabs;
                };
              }}
            />
            :
            <LinearProgress color="primary" />
          }
        </CardContent>
      </Card>
    </>
  );
}

export default ExpiringContracts;
