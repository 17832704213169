import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer
} from 'victory';

const EstimatorChart = (props) => {
  const [xDomain, setXDomain] = useState(props.xDomain);
  const [yDomain, setYDomain] = useState(props.yDomain);
  const [tooltips, setTooltips] = useState(props.tooltips);
  const [estimatorData, setEstimatorData] = useState(props.data);

  useEffect(() => {

  }, [props]);

  return (
    <>
      <VictoryChart
        containerComponent={
          <VictoryVoronoiContainer
            labels={({datum}) => tooltips[datum.paymentNumber]}
            labelComponent={
              <VictoryTooltip constrainToVisibleArea/>
            }
            voronoiDimension="x"
          />
        }
      >
        <VictoryLine
          animate={{
            duration: 2000,
            onLoad: { duration: 1000 }
          }}
          data={estimatorData}
          x="paymentNumber"
          y="totalCost"
        />
        <VictoryAxis crossAxis
          label="Month"
          domain={xDomain}
          padding={30}
          tickFormat={(tick) => ''}
        />
        <VictoryAxis dependentAxis
          label="Total Cost"
          domain={yDomain}
          padding={30}
          tickFormat={(tick) => ''}
        />
      </VictoryChart>
    </>
  );
}

export default EstimatorChart;
