import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import { useNavigate, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const Error = () => {
  const [error, setError] = useState('It looks like the page you have requested was not found');
  const [errorCode, setErrorCode] = useState('E-404');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {


    if (location?.state?.errorCode) {
      setError(location?.state?.errorMessage);
      setErrorCode(location?.state?.errorCode);
    }
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ marginTop: '2rem' }}
      spacing={3}
    >
      <Grid item xs={4}>
        <Card>
          <CardHeader
            sx={{ backgroundColor: '#d32f2f', color: 'white' }}
            title="Error"
          />
          <Divider />
          <CardContent>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <img
                  src="https://cdn.smartsalesandlease.com/global-assets/logos/SMART SALES AND LEASE LOGO 1 TRANSPARENT.png"
                  style={{height: "100px"}}
                  alt="Smart Sales and Lease"
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  component="h2"
                  sx={{ fontWeight: 'bold' }}
                >
                  {errorCode}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  component="p"
                >
                  {error}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/dashboard')}
                >
                  Return to Dashboard
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Error;
