import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const CancelFunding = (props) => {
  const [cancelAgent, setCancelAgent] = useState('');
  const [cancelReason, setCancelReason] = useState('');

  useEffect(() => {

  }, [props]);

  return (
    <Dialog
      disableEscapeKeyDown
      open={props.open}
      onClose={props.onClose}
      fullWidth
    >
      <DialogTitle
        sx={{
          marginBottom: '1rem',
          backgroundColor: '#d32f2f', //error
          color: 'white'
        }}
      >
        Cancel Funding
      </DialogTitle>
      <DialogContent>
        <Stack
          spacing={2}
        >
          <TextField
            label="Contract"
            variant="standard"
            disabled
            value={props.contract}
            required
          />
          <TextField
            label="Sales Agent"
            variant="standard"
            value={cancelAgent}
            onChange={(e) => setCancelAgent(e.target.value)}
            required
          />
          <TextField
            label="Reason For Cancellation"
            variant="standard"
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
            multiline
            required
            rows={4}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => props.cancelFunding(props.contract, cancelAgent, cancelReason)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CancelFunding;
