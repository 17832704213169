const states = [
  { text: 'Alabama', value: 'AL' },
  { text: 'Alaska', value: 'AK' },
  { text: 'Arizona', value: 'AZ' },
  { text: 'Arkansas', value: 'AR' },
  { text: 'California', value: 'CA' },
  { text: 'Colorado', value: 'CO' },
  { text: 'Connecticut', value: 'CT' },
  { text: 'Delaware', value: 'DE' },
  { text: 'Florida', value: 'FL' },
  { text: 'Georgia', value: 'GA' },
  { text: 'Hawaii', value: 'HI' },
  { text: 'Idaho', value: 'ID' },
  { text: 'Illinois', value: 'IL' },
  { text: 'Indiana', value: 'IN' },
  { text: 'Iowa', value: 'IA' },
  { text: 'Kansas', value: 'KS' },
  { text: 'Kentucky', value: 'KY' },
  { text: 'Louisiana', value: 'LA' },
  { text: 'Maine', value: 'ME' },
  { text: 'Maryland', value: 'MD' },
  { text: 'Massachusetts', value: 'MA' },
  { text: 'Michigan', value: 'MI' },
  { text: 'Minnesota', value: 'MN' },
  { text: 'Mississippi', value: 'MS' },
  { text: 'Missouri', value: 'MO' },
  { text: 'Montana', value: 'MT' },
  { text: 'Nebraska', value: 'NE' },
  { text: 'Nevada', value: 'NV' },
  { text: 'New Hampshire', value: 'NH' },
  { text: 'New Jersey', value: 'NJ' },
  { text: 'New Mexico', value: 'NM' },
  { text: 'New York', value: 'NY' },
  { text: 'North Carolina', value: 'NC' },
  { text: 'North Dakota', value: 'ND' },
  { text: 'Ohio', value: 'OH' },
  { text: 'Oklahoma', value: 'OK' },
  { text: 'Oregon', value: 'OR' },
  { text: 'Pennsylvania', value: 'PA' },
  { text: 'Rhode Island', value: 'RI' },
  { text: 'South Carolina', value: 'SC' },
  { text: 'South Dakota', value: 'SD' },
  { text: 'Tennessee', value: 'TN' },
  { text: 'Texas', value: 'TX' },
  { text: 'Utah', value: 'UT' },
  { text: 'Vermont', value: 'VT' },
  { text: 'Virginia', value: 'VA' },
  { text: 'Washington', value: 'WA' },
  { text: 'West Virginia', value: 'WV' },
  { text: 'Wisconsin', value: 'WI' },
  { text: 'Wyoming', value: 'WY' }
];

const generations = [
  { value: '', text: 'N/A' },
  { value: 'J', text: 'JR' },
  { value: 'S', text: 'SR' },
  { value: '1', text: 'I' },
  { value: '2', text: 'II' },
  { value: '3', text: 'III' },
  { value: '4', text: 'IV' },
  { value: '5', text: 'V' },
  { value: '6', text: 'VI' },
  { value: '7', text: 'VII' }
];

const employmentLengths = [
  { value: '', text: 'Please Select a Length...' },
  { value: 0, text: 'Not Currently Employed' },
  { value: 6, text: '1-6 Months' },
  { value: 12, text: '7-12 Months' },
  { value: 24, text: '1-2 Years' },
  { value: 48, text: '2-4 Years' },
  { value: 62, text: 'More Than 4 Years' }
];

const employmentTypes = [
  { value: '', text: 'Please Select an Employment Type...' },
  { value: 'Full Time', text: 'Full Time' },
  { value: 'Part Time', text: 'Part Time' },
  { value: 'Self Employed', text: 'Self Employed' },
  { value: 'Retired', text: 'Retired' },
  { value: 'Military', text: 'Military' },
  { value: 'Long Term Disability', text: 'Long Term Disability' },
  { value: 'Alimony', text: 'Alimony' },
  { value: 'Other', text: 'Other' }
];

const employmentIncomes = [
  { value: '', text: 'Please Select an Income...' },
  { value: 0, text: 'No Income' },
  { value: 500, text: '$1 - $500' },
  { value: 1000, text: '$501 - $1000' },
  { value: 1500, text: '$1001 - $1500' },
  { value: 2000, text: '$1501 - $2000' },
  { value: 2500, text: '$2001 - $2500' },
  { value: 3000, text: '$2501 - $3000' },
  { value: 3500, text: 'Greater Than $3000' }
];

module.exports = {
  states,
  generations,
  employmentLengths,
  employmentTypes,
  employmentIncomes
}