import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import { useNavigate, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import GavelIcon from '@mui/icons-material/Gavel';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import CustomerVideo from '../Fragments/CustomerVideo';
import PaymentEstimator from '../Fragments/PaymentEstimator';
import CustomerStep from '../Fragments/Customer/CustomerStep';
import SKUStep from '../Fragments/Customer/SKUStep';
import TermsStep from '../Fragments/Customer/TermsStep';
import PaymentStep from '../Fragments/Customer/PaymentStep';
import ContractStep from '../Fragments/Customer/ContractStep';
import { sendData, onData, offData, joinRoom, leaveRoom } from '../../Util/Socket';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { currencyFormatter } from '../../Util/Finance';

const AA = process.env.REACT_APP_AA;
const AU = process.env.REACT_APP_AU;
const STRIPE_TEST_KEY = process.env.REACT_APP_STRIPE_TEST_KEY;
const STRIPE_LIVE_KEY = process.env.REACT_APP_STRIPE_LIVE_KEY;

//Could be improved by using a customer context
const Customer = () => {
  const [loading, setLoading] = useState(true);
  const [idempotencyKey, setIdempotencyKey] = useState('');
  const [contract, setContract] = useState(null);
  const [isStorisDealer, setIsStorisDealer] = useState(localStorage.getItem('storisDealer') === 'true');
  const [epo, setEPO] = useState(null);
  const [steps, setSteps] = useState([
    { label: 'Customer Information', icon: PersonIcon },
    { label: 'Shopping Cart', icon: ShoppingCartIcon },
    { label: 'Terms', icon: GavelIcon },
    { label: 'Payment', icon: AttachMoneyIcon },
    { label: 'Contract', icon: HistoryEduIcon }
  ]);
  const [customer, setCustomer, customerRef] = useState({
    apiSocket: null,
    contract: null,
    firstName: null,
    middleInitial: null,
    lastName: null,
    zip: null,
    dlKey: null,
    status: null,
    cashPrice: null,
    merchandise: {
      skus: [],
      invoice: {
        taxError: false,
        cashPriceError: false,
        initialPayment: null,
        minInitialPayment: null,
        paymentFrequency: 1,
        paymentStartDate: null,
        option: null,
        term12: null,
        term18: null,
        term24: null,
        rent90: null,
        rent12: null,
        rent18: null,
        rent24: null,
        term3M: null,
        previousPaidAmount: null
      },
      totalCost: null
    },
    hasBankInfo: false,
    hasPaid: false,
    paymentAuthorized: false,
    confirmTraditionalChecking: false,
    smsCode: null,
    payment: {
      paymentOption: null,
      paymentSchedule: null,
      paymentStartDate: null,
      card: {
        number: null,
        exp: null,
        cvc: null
      },
      bank: {
        type: null,
        account: null,
        routing: null
      }
    },
    stripe: {
      liveKey: null,
      testKey: null
    },
    contractSigned: false,
    docusignURL: null,
    abaBypass: null,
    print: false
  });
  const [showCustomerVideo, setShowCustomerVideo] = useState(false);
  const [showPaymentEstimator, setShowPaymentEstimator] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [stripePromise, setStripePromise] = useState(null);

  const location = useLocation();

  const onGetEditContract = (data) => {

    //There is a lot of unecessary data manipulation here.
    //The code should be refactored to handle the data it receives
    //without having to manipulate it. i.e. data.contract = data.Contract
    if (data?.customer) {
      if (data.customer?.testContract) {
        //This can be dangerous to the performance of the app
        //if the component was to re-render multiple times
        // Create the `Stripe` object

        const stripePromise = loadStripe(STRIPE_TEST_KEY);
        setStripePromise(stripePromise);
      } else {
        //This can be dangerous to the performance of the app
        //if the component was to re-render multiple times
        // Create the `Stripe` object
        const stripePromise = loadStripe(STRIPE_LIVE_KEY);
        setStripePromise(stripePromise);
      }

      let status = data.customer?.Status.toLowerCase();
      if (status === "prequalified" || status === "approved") {
        // customerData.apiSocket = customer.apiSocket;
        data.customer.paymentAuthorized = false;
        data.customer.confirmTraditionalChecking = false;

        let initialPayment = null;
        if (data.customer?.initialpayment && !isNaN(data.customer.initialpayment)) {

          initialPayment = parseFloat(data.customer.initialpayment).toFixed(2);

        }

        // SKUs.
        let totalCost = 0;
        data.customer.merchandise = {
          invoice: {},
          skus: []
        };

        if (data.customer?.skus) {
          const skus = JSON.parse(data.customer.skus);

          if (skus.length > 0) {
            skus.forEach(sku => {
              data.customer.merchandise.skus.push({
                sku: sku.SKU,
                cost: sku.price
                  ? parseFloat(sku.price).toFixed(2)
                  : (0).toFixed(2),
                description: sku.description,
                orderNumber: sku.orderNumber,
                id: sku.IDSKU
              });

              totalCost += parseFloat(sku.price);
            });
          }
        }

        let cashPrice = (0).toFixed(2);
        if (!isNaN(parseFloat(data.customer.cash_price))) {
          cashPrice = parseFloat(data.customer.cash_price).toFixed(2);
        }


        data.customer.initialpayment = initialPayment;
        data.customer.amountApproved = data.customer.AmountApproved;
        data.customer.merchandise.invoice.initialPayment = initialPayment;
        data.customer.merchandise.invoice.previousPaidAmount = data.customer.previousPaidAmount;

        data.customer.merchandise.invoice.paymentFrequency =
					data.customer?.paymentfrequencyid ?? 1;

        data.customer.merchandise.invoice.paymentStartDate =
          data.customer.firstautopaymentdate;
        data.customer.merchandise.invoice.amountDue = data.customer.amountDue;
        data.customer.merchandise.totalCost = parseFloat(totalCost).toFixed(2);

        data.customer.merchandise.invoice.term = data.customer.contracttermid;

        data.customer.cashPrice = cashPrice;
        data.customer.contractSigned = !!data.customer.ContractSigned;
        data.customer.textSent = false;
      }


      setCustomer(data.customer);

      //If customer has already signed contract, move them to the contract step
      if (!isStorisDealer && data.customer.contractSigned) {
        setActiveStep(4);
      } else if (isStorisDealer && data.customer.contractSigned) {
        setActiveStep(3);
      } else {
        setActiveStep(1);
      }

      setLoading(false);
    }
  }

  const printUnsignedContract = () => {

    if (customerRef.current?.print) {
      let contractUrl = `${window.apiUrl}/docusign/unsigned/${customerRef.contract}/${AU}/${AA}`;
      window.open(contractUrl, '_blank');
    }
  }

  const showDocusignContract = () => {

    if (customerRef.current?.contractSigned) {
      let contractUrl = `${window.apiUrl}/awsstore/request/${AU}/${AA}/current-contract/${customer.contract}`
      window.open(contractUrl, '_blank');
    }
  }

  const setCustomerInfo = (source) => {
    let customerData = {};

    customerData.firstName = source.FirstName;
    customerData.middleInitial = source.midName ? source.midName[0] : null;
    customerData.lastName = source.lastName;
    customerData.name = `${customerData.firstName} ${
      customerData.middleInitial
    } ${customerData.lastName}`;

    customerData.dob = source.Birthdate;
    customerData.phone = source.mobilephone;
    customerData.email = source.email;

    let state = null;
    if (source.State && source.State.trim() !== '') {
      state = source.State.toUpperCase();
    }

    customerData.address1 = source.address;
    customerData.address2 = source.aptNumber;
    customerData.city = source.city;
    customerData.state = state;
    customerData.zip = source.Zip;

    customerData.ssn = null;
    customerData.gen = null;

    return customerData;
  }

  const generateIdempotencyKey = (length) => {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';

    for (let i = 0; i < length; i++) {
      randomString += possible.charAt(
        Math.floor(Math.random() * possible.length)
      );
    }

    return randomString;
  }

  useEffect(() => {
    console.log("Edit Customer");
    console.log(location?.state?.source)

    joinRoom(parseInt(localStorage.getItem('dealerId')));

    //Editing an existing customer
    if (location?.state?.contract) {
      setContract(location.state.contract);

      let editContractData = {
        genUserId: localStorage.getItem('genUserId'),
        dealerGroup: localStorage.getItem('dealerGroup'),
        contract: location.state.contract
      }
      sendData('getEditContract', editContractData);

      //This should be renamed to getCustomer in the future
      onData('getEditContract', onGetEditContract);

      return () => {
        offData('getEditContract');
        leaveRoom(`${localStorage.getItem('dealerId')}`);
      }

    //Creating a new Customer
    } else {
      setActiveStep(0);
      setContract(null);
      setEPO(null);
      setCustomer({
        apiSocket: null,
        contract: null,
        firstName: null,
        middleInitial: null,
        lastName: null,
        zip: null,
        dlKey: null,
        status: null,
        cashPrice: null,
        merchandise: {
          skus: [],
          invoice: {
            taxError: false,
            cashPriceError: false,
            initialPayment: null,
            minInitialPayment: null,
            paymentFrequency: 1,
            paymentStartDate: null,
            option: null,
            term12: null,
            term18: null,
            term24: null,
            rent90: null,
            rent12: null,
            rent18: null,
            rent24: null,
            term3M: null,
            previousPaidAmount: null
          },
          totalCost: null
        },
        hasBankInfo: false,
        hasPaid: false,
        paymentAuthorized: false,
        confirmTraditionalChecking: false,
        smsCode: null,
        payment: {
          paymentOption: null,
          paymentSchedule: null,
          paymentStartDate: null,
          card: {
            number: null,
            exp: null,
            cvc: null
          },
          bank: {
            type: null,
            account: null,
            routing: null
          }
        },
        stripe: {
          liveKey: null,
          testKey: null
        },
        contractSigned: false,
        docusignURL: null,
        abaBypass: null,
        print: false
      });

      let idempotencyKey = generateIdempotencyKey(5);
      setIdempotencyKey(idempotencyKey);

      setLoading(false);

      return () => {
        leaveRoom(`${localStorage.getItem('dealerId')}`);
      }
    }
  }, [location.state]);

  return !loading && (
    <>
      <Elements stripe={stripePromise}>
        { showCustomerVideo &&
          <CustomerVideo
            show={showCustomerVideo}
            close={() => setShowCustomerVideo(false)}
          />
        }

        { showPaymentEstimator &&
          <PaymentEstimator
            customer={customer}
            showCustomerAlert={false}
            show={showPaymentEstimator}
            close={() => setShowPaymentEstimator(false)}
          />
        }

        <Grid
          container
          direction="column"
          spacing={3}
        >
          {/* Customer Header Info */}
          { contract &&
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h6">
                    <strong>Customer: </strong>
                    {customer.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    <strong>Contract: </strong>
                    {contract}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    <strong>Prequalified Amount: </strong>
                    { currencyFormatter.format(customer.amountApproved) }
                  </Typography>
                </Grid>
                <Grid item>
                  <ButtonGroup variant="contained">
                    <Button
                      color="info"
                      onClick={() => setShowCustomerVideo(true)}
                    >
                      Video
                    </Button>
                    <Button
                      color="success"
                      onClick={() => setShowPaymentEstimator(true)}
                    >
                      Estimator
                    </Button>
                    { customer?.print &&
                      <Button
                        color="error"
                        onClick={() => printUnsignedContract()}
                      >
                        Print
                      </Button>
                    }
                    { customer?.contractSigned &&
                      <Button
                        color="primary"
                        onClick={() => showDocusignContract()}
                      >
                        Contract
                      </Button>
                    }
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          }

          {/* Customer Stepper */}
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {
                steps.map((step, index) =>  {
                  if (isStorisDealer) {
                    //If the user is a storis dealer, don't show the shopping cart step
                    if (index === 1) {
                      return;
                    }

                    return (
                      <Step key={step.label}>
                        <StepLabel>{step.label}</StepLabel>
                        {/* <StepLabel StepIconComponent={step.icon}>{step.label}</StepLabel> */}
                      </Step>
                    );
                  } else {
                    return (
                      <Step key={step.label}>
                        <StepLabel>{step.label}</StepLabel>
                        {/* <StepLabel StepIconComponent={step.icon}>{step.label}</StepLabel> */}
                      </Step>
                    );
                  }
                })
              }
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            {
            //Customer Steps are different for Storis Dealer's
            //Storis Dealer's do not have a shopping cart
            !isStorisDealer ?
              activeStep === 0 ?
              <CustomerStep
                setCustomer={(customer) => setCustomer(customer)}
                customer={customer}
                activeStep={activeStep}
                setActiveStep={(step) => setActiveStep(step)}
              />
              :
              activeStep === 1 ?
              <SKUStep
                customer={customer}
                setCustomer={(customer) => setCustomer(customer)}
                activeStep={activeStep}
                setActiveStep={(step) => setActiveStep(step)}
              />
              :
              activeStep === 2 ?
              <TermsStep
                customer={customer}
                setCustomer={(customer) => setCustomer(customer)}
                activeStep={activeStep}
                setActiveStep={(step) => setActiveStep(step)}
              />
              :
              activeStep === 3 ?
              <PaymentStep
                customer={customer}
                idempotencyKey={idempotencyKey}
                setCustomer={(customer) => setCustomer(customer)}
                activeStep={activeStep}
                setActiveStep={(step) => setActiveStep(step)}
              />
              :
              activeStep === 4 ?
              <ContractStep
                customer={customer}
                setCustomer={(customer) => setCustomer(customer)}
                activeStep={activeStep}
                setActiveStep={(step) => setActiveStep(step)}
              />
              :
              null
            :
              activeStep === 0 ?
              <CustomerStep
                customer={customer}
                setCustomer={(customer) => setCustomer(customer)}
                activeStep={activeStep}
                setActiveStep={(step) => setActiveStep(step)}
              />
              :
              activeStep === 1 ?
              <TermsStep
                customer={customer}
                setCustomer={(customer) => setCustomer(customer)}
                activeStep={activeStep}
                setActiveStep={(step) => setActiveStep(step)}
              />
              :
              activeStep === 2 ?
              <PaymentStep
                customer={customer}
                setCustomer={(customer) => setCustomer(customer)}
                activeStep={activeStep}
                setActiveStep={(step) => setActiveStep(step)}
              />
              :
              activeStep === 3 ?
              <ContractStep
                customer={customer}
                setCustomer={(customer) => setCustomer(customer)}
                activeStep={activeStep}
                setActiveStep={(step) => setActiveStep(step)}
              />
              :
              null
            }
          </Grid>
        </Grid>
      </Elements>
    </>
  );
};

export default Customer;
