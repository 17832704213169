import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Pages/Login';
import Logout from './Pages/Logout';
import Dashboard from './Pages/Dashboard';
import AdminDashboard from './Pages/AdminDashboard';
import Customers from './Components/Dashboard/Customers';
import Funding from './Components/Dashboard/Funding';
import History from './Components/Dashboard/History';
import ExpiringContracts from './Components/Dashboard/ExpiringContracts';
import Exclusions from './Components/Dashboard/Exclusions';
import Accountability from './Components/Dashboard/Accountability';
import ContactUs from './Components/Dashboard/ContactUs';
import Customer from './Components/Dashboard/Customer';
import CustomerStatus from './Components/Dashboard/CustomerStatus';
import SigningEvent from './Components/Dashboard/SigningEvent';
import Metrics from './Components/AdminDashboard/Metrics';
import Stores from './Components/AdminDashboard/Stores';
import FundingReports from './Components/AdminDashboard/FundingReports';
import ContractsEnding from './Components/AdminDashboard/ContractsEnding';
import CorporateInfo from './Components/AdminDashboard/CorporateInfo';
import Error from './Pages/Error';
import Chat from './Components/Fragments/Chat';
import { isAuthenticated, isAdmin } from './Util/Auth';
import { ThemeProvider, createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';

function AuthRoute({children, redirectTo}) {
  if (isAdmin()) {
    return <Navigate to="/admin-dashboard" />;
  } else {
    return isAuthenticated() ? children : <Navigate to={redirectTo} />;
  }
}

function AdminAuthRoute({children, redirectTo}) {
  return isAdmin() ? children : <Navigate to={redirectTo} />;
}

/*
  This is the theme used for the application.
  Modifications to styles can often be made here.
*/
const theme = createTheme({
  palette: {
    dark: {
      main: '#000000',
    },
    blue: {
      main: '#1976d2',
    },
    light: {
      main: '#ffffff',
    },
    primary: {
      main: "#1e4670"
    },
    secondary: {
      main: '#fff',
    },
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/:userName/:password" element={<Login />} />
          <Route path="/status/:userName/:password/:contract/:source" element={<Login />} />
          <Route path="/logout" element={<Logout />} />

          { /* Dealer Login Routes */}
          <Route
            path="/dashboard"
            element={
              <AuthRoute redirectTo="/login">
                <Dashboard />
              </AuthRoute>
            }
          >
            <Route index element={<Customers />} />
            <Route path="customers" element={<Customers />} />
            <Route path="customer" element={<Customer />} />
            <Route path="new-customer" element={<Customer />} />
            <Route path="customer-status" element={<CustomerStatus />} />
            <Route path="funding" element={<Funding />} />
            <Route path="order-history" element={<History />} />
            <Route path="expiring-contracts" element={<ExpiringContracts />} />
            <Route path="exclusions" element={<Exclusions />} />
            <Route path="accountability" element={<Accountability />} />
            <Route path="contact-us" element={<ContactUs />} />
          </Route>

          {/* Corporate Login Routes */}
          <Route
            path="/admin-dashboard"
            element={
              <AdminAuthRoute redirectTo="/login">
                <AdminDashboard />
              </AdminAuthRoute>
            }
          >
            <Route index element={<Metrics />} />
            <Route path="metrics" element={<Metrics />} />
            <Route path="stores" element={<Stores />} />
            <Route path="funding-reports" element={<FundingReports />} />
            <Route path="info" element={<CorporateInfo />} />
            <Route path="funding" element={<Funding />} />
            <Route path="order-history" element={<History />} />
            <Route path="expiring-contracts" element={<ExpiringContracts />} />
            <Route path="contracts-ending" element={<ContractsEnding />} />
            <Route path="accountability" element={<Accountability />} />
            <Route path="contact-us" element={<ContactUs />} />
          </Route>

          <Route path="/event/contract/error" element={<Navigate to="/contract/event/error" />} />
          <Route path="/contract/event/:event" element={<SigningEvent />} />
          <Route path="/contract/:event/:AU/:AA/:contract" element={<SigningEvent />} />
          <Route path="/error" element={<Error />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
