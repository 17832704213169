const MIN_FINANCE_VALUE = 300;
const MAX_FINANCE_VALUE = 5000;

const withinFinanceRange = (value) => {
  return value >= MIN_FINANCE_VALUE && value <= MAX_FINANCE_VALUE;
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const cardErrorStatus = (status) => {
  switch (status) {
    case 'invalid_number':
      return 'The card has been declined for an unknown reason. Please contact your card issuer for more information.';
    case 'incorrect_zip':
      return 'The billing zip on the card was incorrect so our system blocked the transaction  - pending charge will drop off - please verify billing zip on the card. Thank you.';
    case 'incorrect_cvc':
      return 'The CVC on the card was incorrect so our system blocked the transaction  - pending charge will drop off - please verify CVC on the card. Thank you.'
    default:
      return 'Your card was declined. Please ensure that the zipcode and CVC are correct. If the payment continues to fail please contact us back. Thank you!'
  }
} 

module.exports = {
  withinFinanceRange,
  currencyFormatter,
  cardErrorStatus,
}
