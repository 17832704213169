import React, { useEffect, forwardRef } from 'react';
import useState from 'react-usestateref';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { currencyFormatter } from '../../Util/Finance';
import { sendData, onData, offData } from '../../Util/Socket';

const AA = process.env.REACT_APP_AA;
const AU = process.env.REACT_APP_AU;

const History = (props) => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [columns, setColumns] = useState([
    {
      headerName: 'Funded Date',
      field: 'fundedDate',
      flex: 1,
      type: 'string'
    },
    {
      headerName: 'Contract',
      field: 'contract',
      flex: 1,
      type: 'string'
    },
    {
      headerName: 'Name',
      field: 'name',
      flex: 1,
      type: 'string'
    },
    {
      headerName: 'Order Number',
      field: 'PONumber',
      flex: 1,
      type: 'number'
    },
    {
      headerName: 'Cash Price',
      field: 'InvoiceAmt',
      flex: 1,
      type: 'number',
      valueFormatter: ({ value }) => currencyFormatter.format(value)
    },
    {
      headerName: 'Contract Start Date',
      field: 'Startdate',
      flex: 1,
      type: 'string'
    },
    {
      headerName: 'Actions',
      field: 'actions',
      flex: 0.75,
      renderCell: (rowData) => renderActions(rowData)
    }
  ]);

  const openContract = (contract) => {
    let contractUrl = `${window.apiUrl}/awsstore/request/${AU}/${AA}/current-contract/${contract}`;
    window.open(contractUrl, '_blank');
  }

  const renderActions = (rowData) => {
    return (
      <>
        { parseInt(rowData.row.hasSignedContract) ?
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            key={rowData.id}
          >
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => openContract(rowData.row.contract)}
              >
                Contract
              </Button>
            </Grid>
          </Grid>
          :
          null
        }
      </>
    );
  }

  const onHistory = (data) => {


    data.forEach(order => {
      if (!order.name) {
        order.name = `${order.Firstname} ${order.lastName}`;
      }
    });

    setOrders(data);
    setLoading(false);
  }

  useEffect(() => {
    sendData('historyDashboard', { genUserId: localStorage.getItem('genUserId') });

    onData('historyDashboard', onHistory);

    return () => {
      offData('historyDashboard');
    }
  }, []);

  return (
    <Card
      raised
    >
      <CardHeader
        title="Order History"
        sx={{
          backgroundColor: '#1e4670',
          color: 'white',
        }}
      />
      <CardContent
        sx={{
          height: 'calc(100vh - 200px)',
        }}
      >
        <DataGrid
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: 'contract', sort: 'desc' }],
            },
          }}
          columns={columns}
          rows={orders}
          components={{ Toolbar: GridToolbar, LoadingOverlay: LinearProgress }}
          loading={loading}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 },
            },
          }}
        />
      </CardContent>
    </Card>
  );
}

export default History;
