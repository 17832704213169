const socket = window.socket;

const sendData = (proc, data) => {
  socket.emit(proc, data);
}

const onData = (proc, cb) => {
  socket.on(proc, (data) => {
    cb(data);
  });
}

const offData = (proc) => {
  socket.off(proc);
}

const joinRoom = (room) => {

  socket.emit('join', room);
}

const leaveRoom = (room) => {
  socket.emit('leave', room);
}

module.exports = {
  sendData,
  onData,
  offData,
  joinRoom,
  leaveRoom
}
