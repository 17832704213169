import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import PeopleIcon from '@mui/icons-material/People';
import { useNavigate } from 'react-router-dom';
import { sendData, onData, offData } from '../../../Util/Socket';

const AA = process.env.REACT_APP_AA;
const AU = process.env.REACT_APP_AU;

const ContractStep = (props) => {
  const [loading, setLoading] = useState(false);
  const [contractPercentage, setContractPercentage] = useState(0);
  const [ellipsis, setEllipsis, ellipsisRef] = useState('...');
  const [error, setError, errorRef] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showEmailAlert, setShowEmailAlert] = useState(false);
  const [signingError, setSigningError] = useState('');
  const [printUrl, setPrintUrl] = useState(null);
  const [docusignUrl, setDocusignUrl] = useState(null);
  const [emailAndSMS, setEmailAndSMS] = useState(false);
  const [customer, setCustomer, customerRef] = useState(props?.customer);

  const navigate = useNavigate();

  const contractDocusign = (signType) => {
    setLoading(true);


    sendData('contractDocusign', {
      contract: customerRef.current.contract,
      signType: signType ?? 'esign',
      phone: customerRef.current.verifiedPhone ?? '6055108679',
      genUserId: localStorage.getItem('genUserId'),
    });
  }

  const onContractDocusign = (data) => {
    console.log(data);
    setLoading(false);
    setContractPercentage(100);


    if (data?.success) {
      if (data?.printurl) {
        setPrintUrl(data.printurl);
      }

      if (data?.signType == 'emailandsms') {
        setEmailAndSMS(true);
      }

      setDocusignUrl(data?.url);
    } else {
      if (data?.error === 'payment') {
        props.setActiveStep(props.activeStep - 1);
      }
    }
  }

  const onVoidContract = (data) => {
    if (data?.success && data.contract == customerRef.current.contract) {
      navigate('/dashboard/customer', { replace: true, state: { contract: customerRef.current.contract } });
    }
  }

  useEffect(() => {


    onData('voidContract', onVoidContract);
    onData('contractDocusign', onContractDocusign);

    if (!customer.contractSigned) {
      let percentInterval = setInterval(() => {
        if (contractPercentage < 100) {
          if (ellipsisRef.current.length < 3) {
            setEllipsis(ellipsisRef.current + '.');
          } else {
            setEllipsis('.');
          }
          setContractPercentage(prevPercent => prevPercent + 1);
        } else {
          clearInterval(percentInterval);
        }
      }, 1000);

      contractDocusign(customer.signType);

      return () => {
        offData('contractDocusign');
        offData('voidContract');
        clearInterval(percentInterval);
      }
    } else {
      let url = `${window.site}/contract/complete/${AU}/${AA}/${customer.contract}`;
      setDocusignUrl(url);
      setLoading(false);

      return () => {
        offData('contractDocusign');
        offData('voidContract');
      }
    }
  }, []);

  return (
    <>
      <Card
        raised
      >
        <CardHeader
          title="Contract"
          sx={{
            backgroundColor: '#1e4670',
            color: 'white',
          }}
        />
        <CardContent>
          <Grid
            container
            direction="row"
            spacing={2}
          >
            <Grid item xs={12}>
              { showEmailAlert &&
                <Alert variant="filled" severity="info">
                  Your contract has been sent! Please allow a few minutes for this request to process.
                </Alert>
              }
            </Grid>
            <Grid item xs={12}>
              { (showErrorAlert && !!signingError) ?
                <Alert
                  variant="filled"
                  severity="error"
                  action={
                    <ButtonGroup>
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() => contractDocusign('esign')}
                      >Try Again</Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => contractDocusign('print')}
                      >Print and Email</Button>
                    </ButtonGroup>
                  }
                >
                  An error occurred while generating your contract.
                </Alert>
                :
                showErrorAlert ?
                <Alert
                  variant="filled"
                  severity="error"
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      { !!error &&
                        <p>Error: {error}</p>
                      }
                    </Grid>
                    <Grid item xs={12}>
                      An error occurred while generating your contract. Please call our office at
                      <a href="tel:18777003040">(877) 700-3040</a>
                      or <strong>Live Chat</strong> us to report this error.
                    </Grid>
                  </Grid>
                </Alert>
                :
                null
              }
            </Grid>
            { (loading && !error) ?
              <>
              <Grid item xs={12}>
                Please wait while we generate your contract{ellipsis}
              </Grid>
              <Grid item xs={12}>
                <LinearProgress variant="determinate" value={contractPercentage} />
              </Grid>
              </>
              :
              null
            }
            <Grid item xs={12}>
              { (customer.contractSigned || printUrl || docusignUrl) ?
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  startIcon={<PeopleIcon />}
                  onClick={() => navigate('/dashboard')}
                >Back to All Customers</Button>
                :
                null
              }
            </Grid>
            <Grid item xs={12}>
              { printUrl &&
                <iframe
                  src={printUrl}
                  frameBorder="0"
                  width="100%"
                  height="1200px"
                />
              }
            </Grid>
            <Grid item xs={12}>
              { docusignUrl &&
                <iframe
                  src={docusignUrl}
                  frameBorder="0"
                  width="100%"
                  height="1200px"
                />
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default ContractStep;
