import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ApplicationMetrics from '../Fragments/Metrics/ApplicationMetrics';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { today, formatDateMMDDYYYY } from '../../Util/DateTime';
import { sendData, onData, offData } from '../../Util/Socket';

const Metrics = () => {
  const [tab, setTab] = useState(0);
  const [showStartDate, setShowStartDate] = useState(false);
  const [showEndDate, setShowEndDate] = useState(false);
  const [startDate, setStartDate, startDateRef] = useState(new Date(today().getFullYear(), today().getMonth(), 1));
  const [endDate, setEndDate, endDateRef] = useState(today());
  const [appStats, setAppStats] = useState([]);

  const corporateMetrics = () => {
    sendData('corporateMetrics', {
      dealerGroup: localStorage.getItem('dealerGroup'),
      monthStart: new Date(startDateRef.current).getMonth() + 1,
      dayStart: new Date(startDateRef.current).getDate(),
      yearStart: new Date(startDateRef.current).getFullYear(),
      monthEnd: new Date(endDateRef.current).getMonth() + 1,
      dayEnd: new Date(endDateRef.current).getDate(),
      yearEnd: new Date(endDateRef.current).getFullYear(),
    });
  }

  const onCorporateMetrics = ({ data }) => {
    //Would like to clean this up and return an id on the database
    data.dealerGroupAppStats.forEach((record, index) => {
      record.id = index;
    });

    data.dealerGroupFundingStats.forEach((record, index) => {
      record.id = index;
    });

    setAppStats(data.dealerGroupAppStats);
  }

  useEffect(() => {

    corporateMetrics();

    onData('corporateMetrics', onCorporateMetrics);

    return () => {
      offData('corporateMetrics');
    }
  }, [startDate, endDate]);

  return (
    <Grid
      container
      direction="row"
      spacing={2}
    >
      <Grid container item alignItems="center" justifyContent="center" xs={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={2}>
          <Tabs
            value={tab}
            onChange={(e, newTab) => setTab(newTab)}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab
              value={0}
              icon={<AssignmentIndIcon />}
              label="Application Metrics"
            />
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography
                variant="h6"
                component="h6"
              >
                Showing metrics for:
                &nbsp;
                {formatDateMMDDYYYY(startDate)} - {formatDateMMDDYYYY(endDate)}
              </Typography>
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  open={showStartDate}
                  onOpen={() => setShowStartDate(true)}
                  onClose={() => setShowStartDate(false)}
                  value={startDate}
                  maxDate={endDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Start Date" onClick={() => setShowStartDate(true)} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  open={showEndDate}
                  onOpen={() => setShowEndDate(true)}
                  onClose={() => setShowEndDate(false)}
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="End Date" onClick={() => setShowEndDate(true)} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        { tab === 0 ?
          <ApplicationMetrics
            startDate={startDate}
            endDate={endDate}
            stats={appStats}
          />
          :
          null
        }
      </Grid>
    </Grid>
  );
}

export default Metrics;
