const isAuthenticated = () => {
  let genUserId = localStorage.getItem('genUserId');

  if (genUserId) {
    return true;
  } else {
    return false;
  }
}

const isAdmin = () => {
  let corporateAccount = localStorage.getItem('corporateAccount');

  if (corporateAccount === 'true') {
    return true;
  } else {
    return false;
  }
}

module.exports = {
  isAuthenticated,
  isAdmin,
}
