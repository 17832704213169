import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [userName, setUserName, userNameRef] = useState("");
  const [password, setPassword, passwordRef] = useState("");
  const [modal, setModal] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    userName: providedUserName,
    password: providedPassword,
    contract: providedContract,
    source: providedSource
  } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const handleShowModal = () => setShowModal(!showModal);

  const handleShowPassword = () => setShowPassword(!showPassword);

  const handleLogin = (e = null, source = null, contract = null) => {
    e?.preventDefault();
    setLoggingIn(true);

    if (userNameRef.current && passwordRef.current) {
      fetch("https://api.smartsalesandlease.com/dealer-login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username: userNameRef.current,
          password: passwordRef.current
        })
      })
      .then(async (res) => {
        if (!res.ok) {
          //Bad API Response
          setModal({
            title: "Error",
            body: "Sorry we encountered an error at this time. Please try again.",
            closeButton: true,
            closeButtonText: "Close",
            onClose: () => {
              setLoggingIn(false);
              setShowModal(false);
            },
            submitButton: false
          });

          handleShowModal();

          throw new Error(res);
        }

        return await res.json()
      })
      .then((json) => {
        //Good API Response
        if (json?.error) {
          setModal({
            title: "Login Failed",
            body: `${json.error}`,
            closeButton: true,
            closeButtonText: "Close",
            onClose: () => {
              setLoggingIn(false);
              setShowModal(false);
            },
            submitButton: false
          });

          handleShowModal();
        } else {
          localStorage.setItem('genUserId', json.genUserId);
          localStorage.setItem('dealerId', json.dealerId);
          localStorage.setItem('dealerName', json.dealerName);
          localStorage.setItem('dealerGroup', json.dealerGroup);
          localStorage.setItem('dealerGroupId', json.dealerGroupId);
          localStorage.setItem('userRoleId', json.userRoleID);
          localStorage.setItem('storisDealer', json.storisFlag ? 'true' : 'false');
          localStorage.setItem('corporateAccount', json.corporateAccount ? 'true' : 'false');
          localStorage.setItem('hasMultipleFundingAccounts', json.hasMultipleFundingAccounts ? 'true' : 'false');

          if (json.corporateAccount) {
            navigate('/admin-dashboard');
          } else {
            if (source && contract) {
              navigate('/dashboard/customer', { state: { contract: contract, source: source } });
            } else {
              navigate('/dashboard');
            }
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    } else {
      //Alert User to fill out all fields
      setModal({
        title: "Login Failed",
        body: "Please fill out all fields and try again.",
        closeButton: true,
        closeButtonText: "Close",
        onClose: () => {
          setLoggingIn(false);
          setShowModal(false);
        },
        submitButton: false
      });

      handleShowModal();
    }
  }

  const useLogin = (e) => {
    if (e.code === `Enter`) {
      handleLogin(e);
    }
  }

  useEffect(() => {


    if (location?.state?.error) {
      setErrorMessage(location.state.message);
      setShowErrorAlert(true);

      setTimeout(() => {
        setShowErrorAlert(false);
        setErrorMessage("");
      }, 5000);
    }

    if (providedUserName && providedPassword) {
      setUserName(providedUserName);
      setPassword(providedPassword);

      if (providedSource && providedContract) {
        handleLogin(null, providedSource, providedContract);
      } else {
        handleLogin();
      }
    }

    document.addEventListener(`keyup`, useLogin);

    return () => {
      document.removeEventListener(`keyup`, useLogin)
    }
  }, [providedUserName, providedPassword]);

  return (
    <>
      <Dialog
        open={showModal}
        onClose={modal.onClose ? modal.onClose : handleShowModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {modal.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modal.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          { modal.closeButton &&
            <Button variant="contained" color="primary" onClick={modal.onClose ? modal.onClose : handleShowModal}>
              {modal.closeButtonText}
            </Button>
          }
        </DialogActions>
      </Dialog>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{height: "100vh"}}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: "white", borderRadius: "1%", margin: "75px", width: "40%", padding: "25px" }}
        >
          <Grid item xs={12}>
            <img
              src="https://cdn.smartsalesandlease.com/global-assets/logos/SMART SALES AND LEASE LOGO 1 TRANSPARENT.png"
              style={{height: "100px", paddingTop: "1rem"}}
              alt="Smart Sales and Lease"
              fluid="true"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{padding: "1rem"}}>
              SMART SALES AND LEASE
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{paddingBottom: "1rem"}}>
              DEALER LOGIN
            </Typography>
          </Grid>
          <Grid item xs={12}>
            { showErrorAlert &&
            <Alert
              variant="filled"
              severity="error"
              onClose={() => setShowErrorAlert(false)}
              sx={{ paddingBottom: 1 }}
            >
              { errorMessage }
            </Alert>
            }
          </Grid>
          <Box
            sx={{ display: "flex", alignItems: "flex-end", padding: "1rem", width: "100%" }}
          >
            <IconButton
              sx={{mr: 1}}
            >
              <PersonIcon/>
            </IconButton>
            <TextField
              id="username"
              fullWidth
              label="Username"
              variant="standard"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "flex-end", padding: "1rem", width: "100%" }}
          >
            { showPassword ?
              <IconButton
                onClick={handleShowPassword}
                sx={{mr: 1}}
              >
                <LockOpenIcon />
              </IconButton>
              :
              <IconButton
                onClick={(e) => setShowPassword(true)}
                tabIndex={-1}
                sx={{mr: 1}}
              >
                <LockIcon />
              </IconButton>
            }
            <TextField
              id="password"
              fullWidth
              label="Password"
              variant="standard"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "flex-end", padding: "1rem", width: "100%" }}
          >
            <Button
              sx={{width: "100%"}}
              variant="contained"
              onClick={handleLogin}
              disabled={loggingIn}
            >
              Login
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;
