import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import InputMask from 'react-input-mask';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { sendData, onData, offData } from '../../Util/Socket';
import { states } from '../../Util/Store';

const formatPhone = (phone) => {
  let cleaned = ('' + phone).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '';
}

const validCheckSum = (routingNumber) => {
  let numArray = routingNumber.split('');
  numArray.forEach((num, index) => {
    numArray[index] = parseInt(num);
  });

  //Checksum Test using weight 371 371 371
  let sum = 3 * (numArray[0] + numArray[3] + numArray[6]);
  sum += 7 * (numArray[1] + numArray[4] + numArray[7]);
  sum += 1 * (numArray[2] + numArray[5] + numArray[8]);

  return sum % 10 === 0;
}

const Stores = () => {
  const [loading, setLoading] = useState(true);
  const [state, setState, stateRef] = useState({ value: '', text: '' });
  const [stateInput, setStateInput] = useState('');
  const [newStore, setNewStore, newStoreRef] = useState({});
  const [error, setError] = useState(false);
  const [stores, setStores] = useState([]);
  const [columns, setColumns] = useState([]);
  const [errors, setErrors] = useState({});
  const [usernameError, setUsernameError] = useState(null);

  const corporateGetStores = () => {
    sendData('corporateGetStores', {
      genUserId: localStorage.getItem('genUserId'),
    });
  }

  const onCorporateGetStores = (data) => {
    setLoading(false);
    setStores(data);
  }

  const updateNewStore = (field, value) => {
    setNewStore({
      ...newStore,
      [field]: value,
    });

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null
      });
    }
  }

  const validateNewCorporateStore = () => {
    let newErrors = validateStore();
    console.error(newErrors);

    setErrors({
      ...errors,
      ...newErrors,
    });

    if (Object.keys(newErrors).length === 0) {
      corporateCheckStoreUsernameIsAvailable();
    }
  }

  const corporateNewStore = () => {
    let data = {
      genUserId: localStorage.getItem('genUserId'),
      dealer: newStoreRef.current.dealer,
      isStoris: newStoreRef.current.isStoris,
      location: newStoreRef.current.locationNumber,
      username: newStoreRef.current.username,
      password: newStoreRef.current.password,
      city: newStoreRef.current.city,
      state: stateRef.current.value,
      zip: newStoreRef.current.zip,
      phone: newStoreRef.current.phone,
      email: newStoreRef.current.email,
      funding: {
        email: newStoreRef.current.fundingEmail,
        bank: {
          name: newStoreRef.current.bankName,
          routing: newStoreRef.current.routingNumber,
          account: newStoreRef.current.accountNumber,
        },
      },
    }

    sendData('corporateNewStore', data);
  }

  const onCorporateNewStore = ({ success, storeId }) => {
    setLoading(false);

    if (success) {
      setNewStore({});
      setError(false);
      corporateGetStores();
    }
  }

  const corporateCheckStoreUsernameIsAvailable = () => {
    setLoading(true);

    sendData('corporateCheckStoreUsernameIsAvailable', {
      genUserId: localStorage.getItem('genUserId'),
      username: newStoreRef.current.username,
    });
  }

  const onCorporateCheckStoreUsernameIsAvailable = (data) => {
    if (data.error) {
      setUsernameError(data.error);
    } else if (!data.isAvailable) {
      setUsernameError('Username is not available. Please try another one.');
    } else {
      setUsernameError(null);

      //Submit new store
      corporateNewStore();
    }
  }

  const createColumns = () => {
    let columns = [
      {
        headerName: 'Store',
        field: 'dealer',
        type: 'string',
        width: 200,
      },
      {
        headerName: 'Location',
        field: 'location',
        type: 'string',
      },
      {
        headerName: 'Address',
        field: 'address',
        type: 'string',
        width: 200,
      },
      {
        headerName: 'City',
        field: 'city',
        type: 'string',
        width: 150,
      },
      {
        headerName: 'State',
        field: 'state',
        type: 'string',
      },
      {
        headerName: 'Zip',
        field: 'zip',
        type: 'string',
      },
      {
        headerName: 'Using Storis POS',
        field: 'isStoris',
        type: 'boolean',
      },
      {
        headerName: 'Phone',
        field: 'phone',
        type: 'string',
        width: 150,
        valueFormatter: ({ value }) => formatPhone(value),
      },
      {
        headerName: 'E-mail',
        field: 'email',
        type: 'string',
      },
      {
        headerName: 'Username',
        field: 'username',
        type: 'string',
      },
      {
        headerName: 'Password',
        field: 'password',
        type: 'string',
      }
    ];

    if (localStorage.getItem('hasMultipleFundingAccounts') === 'true') {
      columns.push(
        {
					headerName: 'Funding E-mail',
					field: 'fundingEmail',
          type: 'string',
				},
				{
					headerName: 'Bank Name',
					field: 'bankName',
          type: 'string',
				},
				{
					headerName: 'Routing #',
					field: 'routingNumber',
          type: 'string',
				},
				{
					headerName: 'Account #',
					field: 'accountNumber',
          type: 'string',
				}
      );
    }

    setColumns(columns);
  }

  const validateStore = () => {
    let newErrors = {};

    if (!newStoreRef.current.dealer || newStoreRef.current.dealer?.length < 1) {
      newErrors.dealer = 'Required.';
    }

    if (!newStoreRef.current.locationNumber || newStoreRef.current.locationNumber?.length < 1) {
      newErrors.locationNumber = 'Required.';
    }

    if (!newStoreRef.current.address || newStoreRef.current.address?.length < 1) {
      newErrors.address = 'Required.';
    }

    if (!newStoreRef.current.city || newStoreRef.current.city?.length < 1) {
      newErrors.city = 'Required.';
    }

    if (!stateRef.current.state.value || stateRef.current.state.value?.length < 1) {
      newErrors.state = 'Required.';
    }

    if (!newStoreRef.current || newStoreRef.current.zip?.length < 1) {
      newErrors.zip = 'Required.';
    } else if (newStoreRef.current.zip?.length !== 5) {
      newErrors.zip = 'Must be 5 digits.';
    }

    let phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}(\s|.|-){0,3}\d{4}$/;
    if (!newStoreRef.current.phone || newStoreRef.current.phone?.length < 1) {
      newErrors.phone = 'Required.';
    } else if (!phoneRegex.test(newStoreRef.current.phone)) {
      newErrors.phone = 'Invalid Phone Format';
    }

    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!newStoreRef.current.email || newStoreRef.current.email?.length < 1) {
      newErrors.email = 'Required.';
    } else if (!emailRegex.test(newStoreRef.current.email)) {
      newErrors.email = 'Invalid Email Format';
    } else if (!(newStoreRef.current.email?.length < 254)) {
      newErrors.email = 'Less than 254 characters.';
    }

    if (!newStoreRef.current.username || newStoreRef.current.username?.length < 1) {
      newErrors.username = 'Required.';
    }

    if (!newStoreRef.current.password || newStoreRef.current.password?.length < 1) {
      newErrors.password = 'Required.';
    }

    //Additional funding account field validation
    if (localStorage.getItem('hasMultipleFundingAccounts') === 'true') {
      let numStartEndRegex = /^[0-9]*.*[0-9]$/i;
      let numAndDashRegex = /^([0-9-])*$/i;
      let routingPrefix = newStoreRef.current.routingNumber?.substring(0, 2);
      routingPrefix = parseInt(routingPrefix);

      if (!(newStoreRef.current.routingNumber?.length > 0)) {
        newErrors.routingNumber = "Required";
      } else if (newStoreRef.current.routingNumber?.length !== 9) {
        newErrors.routingNumber = "Must be 9 digits long.";
      } else if (!numAndDashRegex.test(newStoreRef.current.routingNumber)) {
        newErrors.routingNumber = "Can only contain numbers and dashes.";
      } else if (!numStartEndRegex.test(newStoreRef.current.routingNumber)) {
        newErrors.routingNumber = "Must start and end with a number.";
      } else if (!(routingPrefix >= 1 && routingPrefix <= 72)) {
        newErrors.routingNumber = "Invalid routing number.";
      } else if (newStoreRef.current.routingNumber === newStoreRef.current.accountNumber) {
        newErrors.routingNumber = "Cannot match the account number";
      } else if (!validCheckSum(newStoreRef.current.routingNumber)) {
        newErrors.routingNumber = "Please verify the information you have entered.";
      }

      if (!(newStoreRef.current.accountNumber?.length > 0)) {
        newErrors.accountNumber = "Required.";
      } else if (newStoreRef.current.accountNumber === newStoreRef.current.routingNumber) {
        newErrors.accountNumber = "Cannot match the routing number";
      } else if (!numStartEndRegex.test(newStoreRef.current.accountNumber)) {
        newErrors.accountNumber = "Must start and end with a number.";
      } else if (!numAndDashRegex.test(newStoreRef.current.accountNumber)) {
        newErrors.accountNumber = "Can only contain numbers and dashes.";
      }

      if (!newStoreRef.current.fundingEmail || newStoreRef.current.fundingEmail?.length < 1) {
        newErrors.fundingEmail = 'Required.';
      }

      if (!newStoreRef.current.bankName || newStoreRef.current.bankName?.length < 2) {
        newErrors.bankName = 'Required.';
      }
    }

    return newErrors;
  }

  useEffect(() => {


    createColumns();

    corporateGetStores();

    onData('corporateGetStores', onCorporateGetStores);
    onData('corporateCheckStoreUsernameIsAvailable', onCorporateCheckStoreUsernameIsAvailable);
    onData('corporateNewStore', onCorporateNewStore);

    return () => {
      offData('corporateGetStores');
      offData('corporateCheckStoreUsernameIsAvailable');
      offData('corporateNewStore');
    }
  }, []);

  return (
    <Card>
      <CardHeader
        title="All Stores"
        sx={{
          backgroundColor: "#1e4670",
          color: "white",
        }}
      />
      <CardContent
        sx={{
          height: 'calc(100vh - 200px)',
        }}
      >
        { /* Add New Store */}
        <Stack
          direction="column"
          spacing={2}
          sx={{
            height: '100%',
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="flex-end"
            spacing={2}
          >
            { usernameError &&
              <Grid item xs={12}>
                <Alert
                  severity="error"
                  variant="filled"
                >
                  { usernameError }
                </Alert>
              </Grid>
            }
            <Grid item xs={1}>
              <TextField
                label="Dealer"
                fullWidth
                variant="standard"
                error={errors?.dealer ? true : false}
                helperText={errors?.dealer}
                value={newStore.dealer ?? ""}
                onChange={(e) => updateNewStore('dealer', e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label="Location"
                fullWidth
                variant="standard"
                error={errors?.locationNumber ? true : false}
                helperText={errors?.locationNumber}
                value={newStore.locationNumber ?? ""}
                onChange={(e) => updateNewStore('locationNumber', e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label="Address"
                fullWidth
                variant="standard"
                error={errors?.address ? true : false}
                helperText={errors?.address}
                value={newStore.address ?? ""}
                onChange={(e) => updateNewStore('address', e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label="City"
                fullWidth
                variant="standard"
                error={errors?.city ? true : false}
                helperText={errors?.city}
                value={newStore.city ?? ""}
                onChange={(e) => updateNewStore('city', e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <Autocomplete
                autoHighlight
                autoSelect
                value={state}
                onChange={
                  (e, newState) => {
                    setState(newState);
                    setErrors({
                      ...errors,
                      state: null,
                    })
                  }
                }
                inputValue={stateInput}
                onInputChange={(e, newValue) => setStateInput(newValue)}
                id="state"
                options={states}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                getOptionLabel={(option) => {
                  return option.text ?? "";
                }}
                renderInput={(params) =>
                  <TextField
                    label="State"
                    variant="standard"
                    fullWidth
                    error={errors?.state ? true : false}
                    helperText={errors?.state ? errors?.state : ''}
                    {...params}
                  />
                }
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                type="number"
                label="Zip"
                fullWidth
                variant="standard"
                error={errors?.zip ? true : false}
                helperText={errors?.zip}
                value={newStore.zip ?? ""}
                onChange={(e) => updateNewStore('zip', e.target.value)}
                maxLength={5}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newStore.isStoris}
                    onChange={(e) => updateNewStore('isStoris', e.target.checked)}
                  />
                }
                label="Using Storis POS"
              />
            </Grid>
            <Grid item xs={1}>
              <InputMask
                mask="(999) 999-9999"
                maskChar=" "
                value={newStore.phone ?? ""}
                onChange={(e) => {
                  updateNewStore(
                    'phone', e.target.value
                  );
                }}
              >
              {() =>
                <TextField
                  label="Phone"
                  fullWidth
                  variant="standard"
                  error={errors?.phone ? true : false}
                  helperText={errors?.phone}
                />
              }
              </InputMask>
            </Grid>
            <Grid item xs={1}>
              <TextField
                label="E-mail"
                fullWidth
                variant="standard"
                error={errors?.email ? true : false}
                helperText={errors?.email}
                value={newStore.email ?? ""}
                onChange={(e) => updateNewStore('email', e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label="Username"
                fullWidth
                variant="standard"
                error={errors?.username ? true : false}
                helperText={errors?.username}
                value={newStore.username ?? ""}
                onChange={(e) => updateNewStore('username', e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label="Password"
                fullWidth
                variant="standard"
                error={errors?.password ? true : false}
                helperText={errors?.password}
                value={newStore.password ?? ""}
                onChange={(e) => updateNewStore('password', e.target.value)}
              />
            </Grid>
            { localStorage.getItem('hasMultipleFundingAccounts') === 'true' &&
              <>
                <Grid item xs={3}>
                  <TextField
                    label="Funding E-mail"
                    fullWidth
                    variant="standard"
                    error={errors?.fundingEmail ? true : false}
                    helperText={errors?.fundingEmail}
                    value={newStore.fundingEmail ?? ""}
                    onChange={(e) => updateNewStore('fundingEmail', e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Bank Name"
                    fullWidth
                    variant="standard"
                    error={errors?.bankName ? true : false}
                    helperText={errors?.bankName}
                    value={newStore.bankName ?? ""}
                    onChange={(e) => updateNewStore('bankName', e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Routing Number"
                    fullWidth
                    variant="standard"
                    error={errors?.routingNumber ? true : false}
                    helperText={errors?.routingNumber}
                    value={newStore.routingNumber ?? ""}
                    onChange={(e) => updateNewStore('routingNumber', e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Account Number"
                    fullWidth
                    variant="standard"
                    error={errors?.accountNumber ? true : false}
                    helperText={errors?.accountNumber}
                    value={newStore.accountNumber ?? ""}
                    onChange={(e) => updateNewStore('accountNumber', e.target.value)}
                  />
                </Grid>
              </>
            }
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="info"
                fullWidth
                startIcon={<PersonAddIcon />}
                onClick={() => validateNewCorporateStore()}
              >
                Add Store
              </Button>
            </Grid>
          </Grid>
          <DataGrid
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
            columns={columns}
            rows={stores}
            components={{ Toolbar: GridToolbar, LoadingOverlay: LinearProgress }}
            loading={loading}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}

export default Stores;
