import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { createCSV, downloadCSV } from '../../../Util/CSV';
import { formatDateMMDDYYYY } from '../../../Util/DateTime';
import { currencyFormatter } from '../../../Util/Finance';

const ApplicationMetrics = ({ stats, startDate, endDate }) => {
  const [columns, setColumns] = useState([
    {
      headerName: 'Dealer',
      field: 'dealer',
      type: 'string',
      width: 225,
    },
    {
      headerName: 'Total Apps',
      field: 'totalApps',
      width: 125,
      type: 'number',
    },
    {
      headerName: 'Total Apps Approved',
      field: 'totalAppsApproved',
      width: 125,
      type: 'number',
    },
    {
      headerName: '% Approved',
      field: 'approvalPct',
      width: 125,
      type: 'string',
      valueFormatter: ({ value }) => `${value}%`,
    },
    {
      headerName: '% Waiting',
      field: 'waitingPct',
      width: 125,
      type: 'string',
      valueFormatter: ({ value }) => `${value}%`,
    },
    {
      headerName: '% Declined',
      field: 'declinePct',
      width: 125,
      type: 'string',
      valueFormatter: ({ value }) => `${value}%`,
    },
    {
      headerName: 'Total Amount Approved',
      field: 'totalAmountApproved',
      width: 175,
      type: 'string',
      valueFormatter: ({ value }) => currencyFormatter.format(value),
    },
    {
      headerName: 'Average Amount Approved',
      field: 'avgAmountApproved',
      width: 175,
      type: 'string',
      valueFormatter: ({ value }) => currencyFormatter.format(value),
    },
    {
      headerName: 'Total Signed Contracts',
      field: 'totalSignedContracts',
      width: 175,
      type: 'number',
    },
    {
      headerName: '% Approved Apps Closed',
      field: 'approvedAppClosurePct',
      width: 175,
      type: 'string',
      valueFormatter: ({ value }) => {
        if (value && !isNaN(value)) {
          return `${value}%`;
        } else {
          return '0%';
        }
      },
    }
  ]);

  useEffect(() => {

  }, [startDate, endDate, stats]);

  return (
    <Card
      raised
    >
      <CardHeader
        title="Application Statistics By Dealer"
        action={
          <Button
            variant="contained"
            color="success"
            startIcon={<CloudDownloadIcon />}
            onClick={() => {
              const fileName = `SSAL Application Statistics ${formatDateMMDDYYYY(startDate)} - ${formatDateMMDDYYYY(endDate)}`;
              const csv = createCSV(columns, stats);
              downloadCSV(csv, fileName);
            }}
          >
            Download CSV
          </Button>
        }
        sx={{
          backgroundColor: "#1e4670",
          color: "white",
        }}
      />
      <CardContent
        sx={{
          height: "calc(100vh - 200px)",
        }}
      >
        <DataGrid
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          columns={columns}
          rows={stats}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 },
            },
          }}
        />
      </CardContent>
    </Card>
  );
}

export default ApplicationMetrics;
