import React, { useEffect, useRef } from 'react';
import useState from 'react-usestateref';
import { Rnd } from 'react-rnd';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { useLocation } from 'react-router-dom';
import { startChat, sendChat, logChatStart, onChat, offChat, refreshChat } from '../../Util/ChatSocket';
import { formatUSTime } from '../../Util/DateTime';
import { isAdmin } from '../../Util/Auth';

const Chat = ({ close }) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage, pageRef] = useState('Grid');
  const [name, setName, nameRef] = useState('');
  const [chat, setChat] = useState({ width: 400, height: 600, x: 100, y: 100 });
  const [contract, setContract, contractRef] = useState('');
  const [errors, setErrors] = useState({});
  const [connected, setConnected, connectedRef] = useState(false);
  const [currentMessage, setCurrentMessage, currentMessageRef] = useState('');
  const [messages, setMessages] = useState([]);

  const location = useLocation();

  const bottomRef = useRef(null);

  const sendChatMessage = () => {
    //Send message
    let message = `${currentMessageRef.current?.trim()}`;

    sendChat({
      user: nameRef.current,
      message: message,
      sid: localStorage.getItem('sid'),
      direction: 'inbound',
      chatpage: pageRef.current,
      contract: contractRef.current,
      dealer: localStorage.getItem('dealer'),
      dealergroup: localStorage.getItem('dealerGroup'),
    });

    setCurrentMessage('');
  }

  const connectChat = () => {
    if ((nameRef.current?.length < 20) && (nameRef.current?.trim()?.split(' ')?.length < 3)) {
      //Set name in local storage in case of refresh
      localStorage.setItem('name', nameRef.current);
      setLoading(true);

      //Connect to chat
      //Creates room on chat server
      startChat(nameRef.current);
    } else {
      setErrors({
        ...errors,
        name: 'It appears that you have entered a message. To begin, please enter your name so we can best assist you.'
      });
    }
  }

  const onStartChat = (sid) => {
    localStorage.setItem('sid', sid);

    let message = `New chat from dealer: ${localStorage.getItem('dealerName')}`;

    if (!isAdmin()) {
      if (pageRef.current === 'New') {
        message = `${message} regarding contract:  new`
      } else if (pageRef.current === 'Edit') {
        message = `${message} regarding contract:  ${contractRef.current}`
      }
    }

    message = `${message} on page: ${pageRef.current}`;

    sendChat({
      user: nameRef.current,
      message: message,
      sid: sid,
      direction: 'inbound',
      contract: contractRef.current,
      url: window.location.href,
      dealer: localStorage.getItem('dealerName'),
      dealergroup: localStorage.getItem('dealerGroup'),
    });

    logChatStart({
      user: nameRef.current,
      sid: sid,
      contract: contractRef.current,
      dealer: localStorage.getItem('dealerName'),
      dealergroup: localStorage.getItem('dealerGroup'),
    });

    setTimeout(() => {
      setLoading(false);
      setConnected(true);
    }, [1500]);
  }

  const onChatMessage = (data) => {
    //Update messages
  }

  const onGetChat = (data) => {
    if (data.sid === localStorage.getItem('sid')) {
      //Strip out break characters
      let timestamp = new Date();
      let trimmedMessage = data.message.replace(/<br[^>]*>/g, ''); 

      //Save messages to local storage in case of refresh
      let savedMessages = JSON.parse(localStorage.getItem('messages')) || [];
      savedMessages.push({ name: data.user, message: trimmedMessage, timestamp: timestamp });
      localStorage.setItem('messages', JSON.stringify(savedMessages));

      setMessages(savedMessages);
    }
  }

  const closeChat = () => {
    if (connectedRef.current) {
      sendChat({
        user: name,
        message: 'Has ended the chat session',
        sid: localStorage.getItem('sid'),
        direction: 'inbound',
        chatpage: page,
        url: window.location.href,
        contract: contract,
        dealer: localStorage.getItem('dealerName'),
        dealergroup: localStorage.getItem('dealerGroup'),
      });

      localStorage.removeItem('sid');
      localStorage.removeItem('messages');
      localStorage.removeItem('name');
    }

    close();
  }

  const validateChat = (name) => {
    let errors = {};

    if (name?.length > 0) {
      errors.name = 'Please enter your name.';
    }
  }

  const capatilizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    let currentPages = location.pathname.split('/');
    let currentPage = currentPages[currentPages.length - 1];

    if (!isNaN(parseInt(location.state?.contract))) {
      setContract(location.state?.contract);
    }

    if (currentPage === 'customer' && location.state?.contract) {
      setPage('Edit');
    } else if (currentPage === 'customer' && !location.state?.contract) {
      setPage('New');
    } else if (currentPage === 'admin-dashboard' && isAdmin()) {
      setPage('Corporate Dashboard');
    } else {
      setPage('Grid');
    }
  }, [location]);

  useEffect(() => {
    //Scroll to bottom
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [messages]);

  useEffect(() => {
    let sid = localStorage.getItem('sid');

    if (sid?.length > 0) {
      //Join room
      let savedMessages = JSON.parse(localStorage.getItem('messages'));
      setMessages(savedMessages);
      setConnected(true);
      setName(localStorage.getItem('name'));

      refreshChat(sid);
    } 

    onChat('chat', onChatMessage);
    onChat('chatstart', onStartChat);
    onChat('getChat', onGetChat);

    return () => {
      //Disconnect from chat
      offChat('chatstart');
      offChat('chat');
      offChat('getChat');
    }
  }, []);

  return (
    <Rnd
      size={{ width: chat.width, height: chat.height }}
      position={{ x: chat.x, y: chat.y }}
      onDragStop={(e, d) => setChat({ x: d.x, y: d.y })}
      onResizeStop={(e, direction, ref, delta, position) => {
        setChat({ width: ref.style.width, height: ref.style.height, ...position });
      }}
      style={{
        zIndex: 1000,
        position: 'absolute',
      }}
    >
      <Card 
        elevation={3}
        sx={{
          width: '100%',
        }}
      >
        <CardHeader
          title="Support Chat"
          sx={{
            backgroundColor: "#1e4670",
            color: "white",
            width: '100%',
          }}
          action={
            <IconButton
              aria-label="close"
              onClick={() => closeChat()}
              sx={{
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        />
        { !loading ?
        <CardContent sx={{ height: 300, width: '100%', overflowY: 'auto' }}>
          {messages?.map((message, index) => {
            if (message.name != name) {
              return (
                <React.Fragment key={index}>
                <Paper 
                  sx={{ 
                    width: '100%', 
                    backgroundColor: '#f5f5f5',
                    padding: 1, 
                    marginBottom: 1,
                  }}
                >
                <Grid 
                  container
                  wrap="nowrap"
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  sx={{
                    width: '100%',
                  }}
                >
                  <Grid
                    item
                    sx={{
                      fontWeight: 'bold',
                    }}
                    xs={12}
                  >
                    {capatilizeFirstLetter(message.name)}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      variant="body2" 
                      color="black"
                      dangerouslySetInnerHTML={{ __html: message.message }}
                    />
                  </Grid>
                </Grid>
                </Paper>
                <Typography align="left" sx={{ fontSize: 10, color: 'grey' }}>
                  {formatUSTime(message.timestamp)}
                </Typography>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={index}>
                <Paper 
                  sx={{ 
                    width: '100%', 
                    backgroundColor: '#1e4670', 
                    color: 'white', 
                    padding: 1,
                    paddingBottom: 2,
                  }}
                >
                <Grid 
                  container
                  wrap="nowrap"
                  direction="column"
                  alignItems="flex-end"
                  justifyContent="flex-start"
                  sx={{
                    width: '100%',
                  }}
                >
                  <Grid
                    item
                    sx={{
                      fontWeight: 'bold',
                    }}
                    xs={12}
                  >
                    {message.name}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      variant="body2"
                      color="white"
                      dangerouslySetInnerHTML={{ __html: message.message }}
                    />
                  </Grid>
                </Grid>
                </Paper>
                <Typography align="right" sx={{ fontSize: 10, color: 'grey' }}>
                  {formatUSTime(message.timestamp)}
                </Typography> 
                </React.Fragment>
              );
            }
          })}
          <div ref={bottomRef}></div>
        </CardContent>
        :
        <CardContent sx={{ height: 300, width: '100%', overflowY: 'auto' }}>
          <Typography align="center">
            Connecting to chat...
          </Typography>
          <LinearProgress />
        </CardContent>
        }
        { !loading &&
        <>
          <Divider />
          <CardContent> 
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              { (connected && !loading) &&
                <>
                <Grid item xs={8}>
                  <TextField
                    label="Enter your reply..."
                    multiline
                    variant="standard"
                    value={currentMessage}
                    onChange={(e) => setCurrentMessage(e.target.value)}
                    error={currentMessage?.length === 0}
                    helperText={currentMessage?.length === 0 ? 'Please enter a message' : ''}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    aria-label="send"
                    color="primary"
                    disabled={currentMessage.length === 0}
                    onClick={() => sendChatMessage()}
                  >
                    <SendIcon />
                  </IconButton>
                </Grid>
                </>
              }
              { (!connected && !loading) &&
                <>
                <Grid item xs={7}>
                  <TextField
                    label="Enter Name"
                    variant="standard"
                    onChange={(e) => {
                      setName(e.target.value);

                      if (errors?.name) {
                        setErrors({ ...errors, name: null });
                      }
                    }}
                    value={name}
                    error={errors?.name ? true : false}
                    helperText={errors?.name ? errors.name : ''}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => connectChat()}
                    disabled={name?.length === 0}
                    startIcon={<ConnectWithoutContactIcon />}
                  >
                    Start Chat
                  </Button>
                </Grid>
                </>
              }
            </Grid>
          </CardContent>
          </>
        }
      </Card>
    </Rnd>
  );
}

export default Chat;